import React, { useState } from 'react'
import { Heading, Button, Tile } from 'react-bulma-components/dist'
import { Product } from '../../utilities/Classes'
import { ProductFilter } from '../../utilities/Filters'
import ProductCard from '../../components/Cards/ProductCard'
import { screen } from '../../utilities/enums'
import ColumnAux from '../../containers/ColumnAux'
import ProductModal from '../../components/Cards/ProductModal'

import ProductFilterModal from './productFilterModal'
import cogoToast from 'cogo-toast'

const ProductReports = () => {

    const [products, setProducts] = useState([])
    const [searching, setSearching] = useState(false)
    const [filter, setFilter] = useState(ProductFilter.init())
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [showFilter, setShowFilter] = useState(false)


    const closeModals = () => {
        setSelectedProduct(null)
        setShowFilter(false)
    }

    const selectProductPressed = (ord) => [
        setSelectedProduct(ord)
    ]


    const productSelectedToSend = () => {
        // 
    }

    const showFilterSelected = () => {
        setShowFilter(true)
    }

    const updatefilter = (filter) => {
        setProducts([])
        setFilter(filter)
        console.log('UPDATED FILTER =')
        console.log(filter)
        setShowFilter(false)
        if (filter.active) {
            searchForproducts(filter)
        }
    }

    const searchForproducts = (pf) => {
        console.log('Searching for products with filter- ' + pf.description())
        setSearching(true)

        Product.getAll(null, pf, null, (succ, products, msg) => {
            if (succ) {
                setProducts(products)
            } else {
                cogoToast.error('Could not find Products. An error occured.')
                // console.log(msg)
            }
            setSearching(false)
        })

    }


    const productsView = () => {
        return (
            <div>
                <div className='is-ceneterd has-text-centered'>
                    <Button disabled={searching} loading={searching} className={filter.active ? ' is-primary  has-text-bold' : ' has-text-bold'} onClick={showFilterSelected}  > Conditions {filter.filterCount > 0 ? '(' + filter.filterCount + ' Active)' : null} </Button>
                </div>
                <br />
                <Heading size={6} className=" has-text-centered marginBottom6"> {filter.active ? filter.description() : 'No Conditions Selected'} </Heading>

                <br />
                {/* <Fade right cascade> */}
                {
                    products.length > 0 ?

                        <Tile kind="ancestor" className=' productGrid' >
                            {/* <Fade right cascade> */}
                            {products.map((prd, index) => {
                                return (
                                    <Tile key={index} kind='parent' className=''>
                                        <Tile className='' >
                                            <ProductCard key={index} selectable={false} screen={screen.ProductReports} product={prd} productSelected={productSelectedToSend} viewProductPressed={selectProductPressed} />
                                            {/* {pendingInvoiceBox()} */}
                                        </Tile>
                                    </Tile>
                                )
                            })}
                            {/* </Fade> */}
                        </Tile>

                        :
                        null
                }
                {/* </Fade> */}
                {((filter.active && products.length === 0) && searching === false) ? <div > <br /> <br />  <Heading className=' type70 has-text-grey-light has-text-centered is-centered'> No such products </Heading> </div> : null}
            </div>
        )
    }


    const productUpdated = () => {
        console.log('Product is Updated')
    }

    const removeSentProductFromModal = () => {
        console.log('Product has Moved, Remove it')
    }


    return (
        <div>
            {showFilter ? <ProductFilterModal filter={filter} filterSaved={updatefilter} closed={closeModals} /> : null}
            {selectedProduct ? <ProductModal isNewProduct={false} screen={screen.ProductReports} order={selectedProduct.order} product={selectedProduct} closed={closeModals} productUpdated={productUpdated} productSent={removeSentProductFromModal} /> : productsView()}
        </div>
    )


}

export default ProductReports