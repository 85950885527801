import React from 'react'


const ProductSettings = () => {


    

    return (
        <div>
            <p> ProductSettings </p>
        </div>
    )
}

export default ProductSettings