import React, { useState, useEffect } from 'react'
import { Box, Button, Modal, Heading, Columns, Dropdown } from 'react-bulma-components/dist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPrint, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import ColumnAux from './ColumnAux'
import { Measure, Measurement } from '../utilities/Classes'
import cogoToast from 'cogo-toast';
import { MeasurementType, MeasurementUnits } from '../utilities/enums';
import MeasurementTemplates from './MeasurementTemplates'

const MeasureEditModal = (props) => {


    // props.isNewMeasure, props.measure, props.measureSaved, props.measureDeleted, props.measureIndex


    const setClosed = () => {
        props.closed()
    }

    const closeModals = () => {
        setChooseBodyMeasure(false)
    }

    const bodyMeasureSelected = (selectedMeasure) => {
        console.log("Selected measure is ")
        console.log(selectedMeasure)
        let m = { ...measure }
        m.autoFromMeasureName = selectedMeasure.name
        setMeasure(m)
        closeModals()
    }


    let setupMeasure = () => {

        if (props.measure) {
            return props.measure
        } else {
            let newMeasure = new Measure("", MeasurementUnits.inches, Number(0).toFixed(2), Number(0).toFixed(2))
            return newMeasure
        }
    }


    let [measure, setMeasure] = useState(setupMeasure())
    let [choooseBodyMeasure, setChooseBodyMeasure] = useState(false)



    const saveMeasure = () => {

        if (measure.name.length < 1) {
            cogoToast.warn('Name not set.')
            // alert('Please add OrderNo before saving ')
            return
        }


        if (props.measureSaved) {

            let ms = Measure.copyFrom(measure)

            props.measureSaved(ms, props.isNewMeasure ? null : props.measureIndex)
            props.closed()
        }
    }




    const deleteMeasure = () => {
        if (props.isNewMeasure) {
            props.closed()
        }
        if (props.measureDeleted) {
            props.measureDeleted(measure, props.isNewMeasure ? null : props.measureIndex)
            props.closed()
        }

    }

    const nameChanged = (e) => {
        console.log('Name is changed')
        let m = { ...measure }
        m.name = e.target.value
        setMeasure(m)
    }


    const defaultValueChanged = (e) => {
        console.log('CHNAGING DEFAULT VALUE TO = ' + e.target.value)
        let m = { ...measure }
        m.defaultValue = e.target.value

        console.log('CHNAGING DEFAULT VALUE')

        setMeasure(m)
    }

    const valueChanged = (e) => {
        let m = { ...measure }
        m.value = e.target.value
        setMeasure(m)
    }

    const autoSlimValChanged = (e) => {
        let m = { ...measure }
        m.autoSlimAddVal = e.target.value
        if (m.autoFromMeasureName !== null && m.autoFromMeasureName !== "") {
            m.isAuto = true
        }
        setMeasure(m)
    }

    const autoRegularValChanged = (e) => {
        let m = { ...measure }
        m.autoRegularAddVal = e.target.value
        if (m.autoFromMeasureName !== null && m.autoFromMeasureName !== "") {
            m.isAuto = true
        }
        setMeasure(m)
    }

    const autoComfortValChanged = (e) => {
        let m = { ...measure }
        m.autoComfortAddVal = e.target.value
        if (m.autoFromMeasureName !== null && m.autoFromMeasureName !== "") {
            m.isAuto = true
        }
        setMeasure(m)
    }



    const unitChanged = (e) => {
        let m = { ...measure }
        m.unit = e.target.value
        setMeasure(m)
    }

    const chooseBodyMeasurePressed = (e) => {
        setChooseBodyMeasure(true)
    }

    const removeBodyMeasurePressed = (e) => {
        let m = { ...measure }
        m.autoFromMeasureName = ""
        setMeasure(m)
    }


    const form = () => {

        return (

            <div className='is-two-fifths  paddingHor20'>

                <br />

                <Columns>
                    <Columns.Column size={3}>
                        <label className="label type55  marginTop10 ">Name</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className='field marginHorizontal8'>
                            {/* <label className="label type60 ">Email</label> */}
                            <input name='name' defaultValue={measure.name} type='input' className='input field has-text-weight-semibold type65' placeholder='Name' onChange={e => { nameChanged(e) }} />
                        </div>
                    </Columns.Column>
                </Columns>


                {
                    props.isTemplate ?
                        <Columns>
                            <Columns.Column size={3}>
                                <label className="label type55  marginTop10 ">Default Value</label>
                            </Columns.Column>
                            <Columns.Column>
                                <div className='field marginHorizontal8'>
                                    {/* <label className="label type60 ">Email</label> */}
                                    <input name='defaultValue' defaultValue={measure.defaultValue} type='input' className='input field has-text-weight-semibold type65' placeholder='Default Value' onChange={e => { defaultValueChanged(e) }} />
                                </div>
                            </Columns.Column>
                        </Columns>

                        :

                        <Columns>
                            <Columns.Column size={3}>
                                <label className="label type55  marginTop10 ">Value</label>
                            </Columns.Column>
                            <Columns.Column>
                                <div className='field marginHorizontal8'>
                                    {/* <label className="label type60 ">Email</label> */}
                                    <input name='value' defaultValue={measure.value ?? (measure.defaultValue ?? null)} type='input' className='input field has-text-weight-semibold type65' placeholder='Value' onChange={e => { valueChanged(e) }} />
                                </div>
                            </Columns.Column>
                        </Columns>

                }






                <Columns>
                    <Columns.Column size={3}>
                        <label className="label type55  marginTop10 ">Unit</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className="control marginHorizontal8">
                            <div className='select type65 is-fullwidth'>
                                <select name='unit' className='select ' defaultValue={measure.unit ?? 'Inches'} onChange={e => { unitChanged(e) }}>
                                    <option value={'Inches'} > Inches </option>
                                    <option value={'Centimeter'} > Centimeter </option>
                                    <option value={'Meter'} > Meter </option>
                                </select>
                            </div>
                        </div>

                    </Columns.Column>
                </Columns>

                <br />
                <br />


                { props.measureType === MeasurementType.product ?
                    <div>
                        <p className="has-text-grey-light type55 marginBottom20 has-text-centered"> _______ Optional ________ </p>

                        <Heading size={7} className="has-text-grey has-text-centered"> Auto-Create Rule with Body Measurement </Heading>

                        <Columns className='is-mobile'>
                            <Columns.Column size={3}>
                                <label className="label type55  marginTop10 ">Body Measure Named:</label>
                            </Columns.Column>
                            <Columns.Column>
                                <div className="control is-fullwidth">
                                    <div className='field '>
                                        <div className='type70 has-text-grey marginTop8 has-text-weight-semibold'> {measure.autoFromMeasureName ? `${measure.autoFromMeasureName}` : ""}
                                            <Button className="  no-decoration is-pulled-right  is-text is-small has-text-weight-bold" onClick={chooseBodyMeasurePressed}><FontAwesomeIcon className='' icon={faEdit} /></Button>

                                            {(measure.autoFromMeasureName !== "") ?
                                                <Button className="  no-decoration is-pulled-right has-text-grey marginRight20  is-text is-small has-text-weight-bold" onClick={removeBodyMeasurePressed}><FontAwesomeIcon className='' icon={faTimes} /></Button>
                                                : null
                                            }

                                        </div>

                                        {/* <textarea disabled={enableDescription ? false : true} name='description' defaultValue={product.description ?? null} placeholder='Description' type='input' className='input textarea has-text-weight-medium type65' onChange={e => { productDescriptionChanged(e) }} /> */}
                                    </div>
                                </div>
                            </Columns.Column>
                        </Columns>
                        <p className="has-text-grey is-size-5 has-text-centered"> + </p>
                        <br />
                        <Columns>
                            <Columns.Column size={3}>
                                <label className="label type55  marginTop10 ">Slim Value</label>
                            </Columns.Column>
                            <Columns.Column>
                                <div className='field marginHorizontal8'>
                                    {/* <label className="label type60 ">Email</label> */}
                                    <input name='autoSlimAddVal' defaultValue={measure.autoSlimAddVal ?? 0.0} type='input' className='input field has-text-weight-semibold type65' placeholder='Value to add for slim fit' onChange={e => { autoSlimValChanged(e) }} />
                                </div>
                            </Columns.Column>
                        </Columns>
                        <br />
                        <Columns>
                            <Columns.Column size={3}>
                                <label className="label type55  marginTop10 ">Regular Value</label>
                            </Columns.Column>
                            <Columns.Column>
                                <div className='field marginHorizontal8'>
                                    {/* <label className="label type60 ">Email</label> */}
                                    <input name='autoRegularAddVal' defaultValue={measure.autoRegularAddVal ?? 0.0} type='input' className='input field has-text-weight-semibold type65' placeholder='Value to add for regular fit' onChange={e => { autoRegularValChanged(e) }} />
                                </div>
                            </Columns.Column>
                        </Columns>
                        <br />

                        <Columns>
                            <Columns.Column size={3}>
                                <label className="label type55  marginTop10 ">Comfort Value</label>
                            </Columns.Column>
                            <Columns.Column>
                                <div className='field marginHorizontal8'>
                                    {/* <label className="label type60 ">Email</label> */}
                                    <input name='autoComfortAddVal' defaultValue={measure.autoComfortAddVal ?? 0.0} type='input' className='input field has-text-weight-semibold type65' placeholder='Value to add for comfort fit' onChange={e => { autoComfortValChanged(e) }} />
                                </div>
                            </Columns.Column>
                        </Columns>

                        <p className="has-text-grey-light type55 marginBottom20 has-text-centered"> _____________________ </p>

                        <br />
                        <br />
                    </div>

                    : null
                }




                <Button className='is-fullwidth is-black' onClick={saveMeasure} > Save </Button>

                <br />


            </div>
        )

    }





    const modal = () => {
        return (

            <Modal show={true} closeOnBlur={true} closeOnEsc={true} showClose={true} onClose={() => setClosed()} >
                <Modal.Content className='is-padingless has-background-light'>
                    {(choooseBodyMeasure === true) ? <MeasurementTemplates type={MeasurementType.body} isToSelectedBodyMeasure={true} closed={closeModals} bodyMeasureSelected={bodyMeasureSelected} /> : null}


                    {props.measure ?
                        <Dropdown className=" is-arrowless is-grey noDropdownIcon" color="light" label='•••' onChange={(val) => { deleteMeasure(val) }} >
                            <Dropdown.Item value="delete">
                                Delete
                                </Dropdown.Item>
                        </Dropdown>
                        : null}
                    <br />
                    <Box className='is-radiusless is-padingless no-border is-shadowless has-background-transparent '>

                        <Heading size={6} className='is-size-5-mobile has-text-black has-text-semibold has-text-centered' > {props.isNewMeasure ? 'New Measure' : 'Edit Measure'}  </Heading>

                        {/* {(addProdMesurement === true) ? <MeasurementTemplates type={MeasurementType.product} closed={closeModals} selectedTemplate={prodMeasurementTemplateSelected} /> : null} */}

                        <ColumnAux isMiddle={true} size={10} className=' is-centered '>
                            {form()}
                        </ColumnAux>

                    </Box>
                </Modal.Content>
            </Modal>

        )
    }

    return modal()

}

export default MeasureEditModal