import React, { useState, useEffect } from 'react'
import { Box, Button, Modal, Heading, Columns, Table } from 'react-bulma-components/dist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPrint } from '@fortawesome/free-solid-svg-icons';
import ColumnAux from '../../containers/ColumnAux'
import LoadingAux from '../../containers/LoadingAux'

import { Position } from '../../utilities/UserClasses'
import { StyleSelection, Product } from '../../utilities/Classes'

import { getLocations, getUsers, getCurrentUser } from '../../utilities/Store';
import cogoToast from 'cogo-toast';

const MaterialModal = (props) => {



    const setClosed = () => {
        props.closed()
    }


    let [materials, setMaterials] = useState(props.product.materialToSource)
    let [saving, setSaving] = useState(false)
    







    const saveMaterials = () => {

        
        setSaving(true)
        let prodCopy = Product.copyFrom(props.product)
        prodCopy.materialToSource = materials

        console.log("SAVING MATERIALS")
        console.log(materials)

        prodCopy.update( (succ, msg) => {
            setSaving(false)
            if (succ){
                props.materialsUpdated(materials)
            }else{
                cogoToast.error('Could not update materials. Check internet and try again.')
                console.log(msg)
            }
        })
    }


    const materialsChanged = (event) => {
        // alert(`Change in Selection name = ${selectionName}`)

        console.log('EVENT TARGET VALUE = ', event.target.value)


        let val = event.target.value
        // let o = { ...styleSelection }
        let thisVal = val.replace( /\n/g, "" ).split(",")

        let fVal = thisVal.filter( (opt) => {
            return opt !== "" && opt !== " "  && opt !== ","
        } )

        setMaterials(fVal)


    }






const createTables = () => {
    return (
        <div >
                <Columns className='is-mobile'  >
                    <Columns.Column size={3}>
                        <label className="label type55  marginTop10 ">MATERIAL TO SOURCE :</label>
                        <p className='type45'>Seperate entries by comma (, )</p>                    
                    </Columns.Column>
                    <Columns.Column>
                    <div className="control is-fullwidth">
                            <div className='field '>
                                <textarea name='username' defaultValue={materials ? materials.join(',') : null} placeholder='Materials' type='input' className='input textarea has-text-weight-semibold type65' onChange={e => { materialsChanged(e) }} />
                            </div>
                        </div>
                    </Columns.Column>
                </Columns>
        </div>
    )
}

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  

const camelToSentenceCase = (text) => {
    var result = text.replace(/([A-Z])/g, " $1");
    var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult
}



const form = () => {
    return (
        <div className='is-two-fifths  paddingHor20'>
            <br />
            {createTables()}
            <br />
            <Button disabled={saving} loading={saving} className='is-fullwidth is-black' onClick={saveMaterials} > Save </Button>
            <br />
        </div>
    )
}


const modal = () => {

    createTables()

    return (
        <Modal show={true} closeOnBlur={true} closeOnEsc={true} showClose={true} onClose={() => setClosed()} >
            <Modal.Content className='is-padingless'>
                <Box className='is-radiusless is-padingless fadeIn has-background-light'>
                    <Heading size={6} className='is-size-5-mobile has-text-black has-text-semibold has-text-centered' > Materials  </Heading>
                    <ColumnAux isMiddle={true} size={10} className=' is-centered '>

                        <div className='field is-centered'>
                            {/* <label className="label type60 ">Email</label> */}
                            {/* <input name={position.name} defaultValue={position.name} placeholder={'Name of Position'} type='input' className='input  has-text-weight-semibold type65' onChange={e => { positionNameChanged(e) }} /> */}
                        </div>

                        {form()}
                    </ColumnAux>

                </Box>
            </Modal.Content>
        </Modal>

    )
}

return modal()

}

export default MaterialModal