import React, { useState } from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Tile, Box, Columns, Button, Tag, Image, Modal, Heading, Card, Label, InputFile } from 'react-bulma-components/dist';
import { pages, departments, screen, departmentForScreen, clientScreenTab, imageUrlForPostures } from '../utilities/enums'
import ColumnAux from './ColumnAux';
import { CustomerPic, ProductPic, Customer } from '../utilities/Classes'
import { User } from '../utilities/UserClasses'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPrint, faUpload } from '@fortawesome/free-solid-svg-icons';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { picType } from '../utilities/enums'
import cogoToast from 'cogo-toast';


const ImageUploader = (props) => {

    const [selectedFile, setSelectedFile] = useState(null)

    // const [files, setFiles] = useState([])

    const [filesInfo, setFilesInfo] = useState([])

    const [isUploading, setIsUploading] = useState(false)
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null)
    // const [imagePreviewUrls, setImagePreviewUrls] = useState([])




    const selectAFile = (file) => {
        console.log('FILE SELECTED = ')
        console.log(file)

        setSelectedFile(file)
    }

    const toggleImageSelect = () => {
        // setImageSelected(!imageSelected)
    }

    const uplodadCustPic = (custPic) => new Promise ( (resolve, reject) => {
        let cp = CustomerPic.copyFrom(custPic)
        cp.add((success, errorMsg) => {
            setIsUploading(false)
            if (success) {
                // console.log('Image Uploaded')
                resolve(cp)
            } else {
                // cogoToast.error('Could not upload Image. Check internet.')
                console.log("Could not upload Image. Check internet. " + errorMsg)
                // alert(errorMsg)
                reject('Could not upload Image. Check internet.')
            }
        })
    })

    const uplodadProductPic = (productPic) => new Promise ( (resolve, reject) => {
        let pp = ProductPic.copyFrom(productPic)
        // setIsUploading(true)
        pp.add((success, errorMsg) => {
            if (success) {
                // console.log(' Product Image Uploaded')
                resolve(pp)
            } else {
                console.log('Could not upload Product Image. Check internet.')
                // alert(errorMsg)
                // setIsUploading(false)
                reject('Could not upload Product Image. Check internet.')
            }
        })

    })



    const uploadImagesNow = () => {

        if (props.type === picType.CustomerPic) {
            // Uplaod CustomerPic
            console.log('UPLOADING CUSTOMER IMAGES')

            setIsUploading(true)
            Promise.all(
                filesInfo.map((thisFileInfo) => {
                    let cp = new CustomerPic(props.customer, null, null, thisFileInfo.data, null)
                    return uplodadCustPic(cp);
                })
            ).then((results) => {

                console.log("------CUSTOMER IMAGE UPLOAD RESULTS ARE")
                console.log(results)

                setIsUploading(false)
                props.imageAdded(results, true)


            }).catch((error) => {
                console.log(error);
                cogoToast.error('Could not upload Image. Check internet.')
            });


            // filesInfo.map((thisFileInfo) => {
            //     let cp = new CustomerPic(props.customer, null, null, thisFileInfo.data, null)

            //     setIsUploading(true)
            //     cp.add((success, errorMsg) => {
            //         setIsUploading(false)
            //         if (success) {
            //             // console.log('Image Uploaded')
            //             props.imageAdded(cp)
            //         } else {
            //             cogoToast.error('Could not upload Image. Check internet.')
            //             // alert(errorMsg)
            //         }
            //     })
            //     // cogoToast.info("Uploading in background. Notification will appear in case of error.")
            //     // props.imageAdded(cp)
            // })



        } else if (props.type === picType.ProductPic) {

            setIsUploading(true)
            Promise.all(
                filesInfo.map((thisFileInfo) => {
                    let pp = new ProductPic(props.product, null, null, thisFileInfo.data, null)
                    return uplodadProductPic(pp);
                })
            ).then((results) => {

                console.log("------PRODUCT IMAGE UPLOAD RESULTS ARE")
                console.log(results)

                setIsUploading(false)
                props.imageAdded(results, true)

            }).catch((error) => {
                console.log(error);
                cogoToast.error('Could not upload Product Image. Check internet.')
            });


            // filesInfo.map((thisFileInfo) => {
            //     // Uplaod ProductPic
            //     console.log('UPLOADING PRODUCT IMAGE')
            //     let pp = new ProductPic(props.product, null, null, thisFileInfo.data, null)
            //     // setIsUploading(true)
            //     pp.add((success, errorMsg) => {
            //         if (success) {
            //             // console.log(' Product Image Uploaded')
            //             props.imageAdded(pp)
            //         } else {
            //             cogoToast.error('Could not upload Product Image. Check internet.')
            //             // alert(errorMsg)
            //             // setIsUploading(false)
            //         }
            //     })
            //     cogoToast.info("Uploading in background. Notification will appear in case of error.")
            //     props.imageAdded(pp)
            // })

        } else if (props.type === picType.CustomerProfilePic) {

            let thisFileInfo = filesInfo[0]

            let cs = Customer.copyFrom(props.customer)
            setIsUploading(true)
            cs.uploadProfileImage(thisFileInfo.data, (succ, profilePicUrl, errMsg) => {
                setIsUploading(false)
                if (succ) {
                    // console.log(' Customer Profile Image Uploaded')
                    props.imageAdded(profilePicUrl)
                } else {
                    cogoToast.error('Could not upload Profile Image. Check internet.')
                    // alert(errMsg)
                }
            })


        } else if (props.type === picType.UserPic) {

            let thisFileInfo = filesInfo[0]


            let user = User.copyFrom(props.user)
            setIsUploading(true)
            user.uploadImage(thisFileInfo.data, (succ, userImageUrl, errMsg) => {
                setIsUploading(false)
                if (succ) {
                    // console.log(' User Image Uploaded')
                    props.imageAdded(userImageUrl)
                } else {
                    cogoToast.error('Could not upload User Image. Check internet.')
                    // alert(errMsg)
                }
            })


        }
    }


    const uploadImageNow = () => {
        if (props.type === picType.CustomerPic) {
            // Uplaod CustomerPic
            console.log('UPLOADING CUSTOMER IMAGE')
            let cp = new CustomerPic(props.customer, null, null, selectedFile, null)
            setIsUploading(true)
            cp.add((success, errorMsg) => {
                setIsUploading(false)
                if (success) {
                    // console.log('Image Uploaded')
                    props.imageAdded(cp)
                } else {
                    cogoToast.error('Could not upload Image. Check internet.')
                    // alert(errorMsg)
                }
            })
            // cogoToast.info("Uploading in background. Notification will appear in case of error.")
            // props.imageAdded(cp)


        } else if (props.type === picType.ProductPic) {


            // Uplaod ProductPic
            console.log('UPLOADING PRODUCT IMAGE')
            let pp = new ProductPic(props.product, null, null, selectedFile, null)
            // setIsUploading(true)
            pp.add((success, errorMsg) => {
                if (success) {
                    // console.log(' Product Image Uploaded')
                    props.imageAdded(pp)
                } else {
                    cogoToast.error('Could not upload Product Image. Check internet.')
                    // alert(errorMsg)
                    // setIsUploading(false)
                }
            })
            cogoToast.info("Uploading in background. Notification will appear in case of error.")
            props.imageAdded(pp)


        } else if (props.type === picType.CustomerProfilePic) {

            let cs = Customer.copyFrom(props.customer)
            setIsUploading(true)
            cs.uploadProfileImage(selectedFile, (succ, profilePicUrl, errMsg) => {
                setIsUploading(false)
                if (succ) {
                    // console.log(' Customer Profile Image Uploaded')
                    props.imageAdded(profilePicUrl)
                } else {
                    cogoToast.error('Could not upload Profile Image. Check internet.')
                    // alert(errMsg)
                }
            })


        } else if (props.type === picType.UserPic) {

            let user = User.copyFrom(props.user)
            setIsUploading(true)
            user.uploadImage(selectedFile, (succ, userImageUrl, errMsg) => {
                setIsUploading(false)
                if (succ) {
                    // console.log(' User Image Uploaded')
                    props.imageAdded(userImageUrl)
                } else {
                    cogoToast.error('Could not upload User Image. Check internet.')
                    // alert(errMsg)
                }
            })


        }

    }





    const imageAndUploader = () => {

        return (
            <div>

                {/* <div className=''>
                    {selectedImage ? <Image src={selectedImage.url} /> : null}
                </div> */}

                {/* <div className="">
                            <input type="file" name="avatar" onChange={fileChangedHandler} />
                            <button type="button" onClick={submit} > Upload </button>
                            {imagePreview()}
                        </div> */}

                <div className='has-text-centered'>
                    {imagePreviews()}
                </div>

                <div className="file is-boxed has-text-centered is-centered">
                    <label className="file-label">
                        {/* <input multiple={(props.type === picType.CustomerProfilePic || props.type === picType.UserPic) ? false : true} disabled={isUploading} className="file-input" type="file" accept="image/png, image/jpeg, image/jpg, image/pdf" name="images" webkitdirectory="true" onChange={readAllFiles} /> */}
                        <input multiple={(props.type === picType.CustomerProfilePic || props.type === picType.UserPic) ? false : true} disabled={isUploading} className="file-input" type="file" accept="image/png, image/jpeg, image/jpg, image/pdf" name="images" onChange={readAllFiles} />

                        <span className="file-cta">
                            <span className="file-icon">
                                <FontAwesomeIcon className='' icon={faUpload} />
                            </span>
                            <span className="file-label">
                                Choose a file…
                            </span>
                        </span>
                    </label>
                </div>

                <Button disabled={!filesInfo.length} loading={isUploading} className='button is-black is-fullwidth marginTop10' onClick={uploadImagesNow} > Upload </Button>

            </div>
        )
    }

    const modal = () => {
        return (
            <Modal show={true} closeOnBlur={true} closeOnEsc={true} showClose={true} onClose={() => props.closed()} >
                <Modal.Content className='is-padingless'>
                    <Box className='is-radiusless is-padingless fadeIn has-background-light'>
                        <Heading size={6} className='is-size-5-mobile has-text-black has-text-semibold has-text-centered' > Upload Image </Heading>

                        <ColumnAux isMiddle={true} size={10} className=' is-centered '>
                            {imageAndUploader()}
                        </ColumnAux>

                    </Box>
                </Modal.Content>
            </Modal>

        )
    }


    const handleFileChosen = async (thisFile) => {
        return new Promise((resolve, reject) => {
            let fileReader = new FileReader();
            fileReader.onload = () => {
                let fileInfo = {
                    data: thisFile,
                    name: thisFile.name,
                    size: thisFile.size,
                    type: thisFile.type,
                    url: fileReader.result
                }
                resolve(fileInfo);
            };
            fileReader.onerror = reject;
            fileReader.readAsDataURL(thisFile);
        });
    }


    const readAllFiles = (event) => {
        let allFiles = [...event.target.files]

        if (!allFiles.length) {
            return
        }


        if (props.type === picType.CustomerProfilePic || props.type === picType.UserPic) {
            if (allFiles.length > 1) {
                cogoToast.warn("Only 1 image is needed for the profile.")
                return
            }

            if (filesInfo.length) {
                if ((filesInfo.length + allFiles.length) > 1) {
                    cogoToast.warn("Only 1 image is needed for the profile.")
                    return
                }
            }
        }

        if (allFiles.length > 5) {
            cogoToast.warn("You can only upload upto 5 images together.")
            return
        }

        if (filesInfo.length) {
            if ((filesInfo.length + allFiles.length) > 5) {
                cogoToast.warn("You can only upload upto 5 images together.")
                return
            }
        }

        // const results = await Promise.all(allFiles.map(async (file) => {
        //     const fileContents = await handleFileChosen(file);
        //     return fileContents;
        // }));

        // console.log("CONTENTS IN THE READ ALL FILES BLOCK");
        // console.log(results);
        // return results;


        const prodAssets = Promise.all(
            allFiles.map((file) => {
                return handleFileChosen(file);
            })
        ).then((results) => {
            // cogoToast.success("PROMISE PRODUCTS Results Success")
            console.log("ALL READ FILES ARE");
            console.log(results);
            let allFIs = [...filesInfo]

            results.map((thisr) => {
                let existingArr = allFIs.filter((thisInfo) => {
                    return thisInfo.url === thisr.url
                })
                if (!existingArr.length) {
                    allFIs.push(thisr)
                }
            })


            // console.log("ALL FIs ARE");
            // console.log(allFIs);
            // get unique only

            // let finalFileInfos = Array(new Set(allFIs))
            setFilesInfo(allFIs)
        }).catch((error) => {
            console.log("ERROR WHILE TRYING TO READ FILES IS");
            console.log(error);
        });

    }



    const fileChangedHandlers = event => {

        readAllFiles(event).then((results) => {
            console.log("ALL READ FILES ARE");
            console.log(results);
            setFilesInfo(results)
        }).catch(error => {
            console.log("ERROR WHILE TRYING TO READ FILES IS");
            console.log(error);
        })

        // if (event.target.files.length) {
        //     let allFiles = [...event.target.files]

        //     console.log("ALL FILES ARE")
        //     console.log(allFiles)


        //     let allFilesInfos = [...filesInfo]
        //     console.log("FILES INFO BEFORE ADD")
        //     console.log(allFilesInfos)




        //     allFiles.map((thisFile) => {
        //         let fileSize = thisFile.size
        //         if (fileSize) {
        //             if ((fileSize / 1024) > 600) {
        //                 cogoToast.warn(<div>
        //                     <p>Choose lower resolution if possible.</p>
        //                     <p>This file will take time to upload.</p>
        //                 </div>, { hideAfter: 5 })
        //             }


        //             console.log("FILE IS ")
        //             console.log(thisFile)
        //             console.log("FILESIZE IS ")
        //             console.log(fileSize / 1024)
        //         }

        //         let reader = new FileReader();
        //         reader.onloadend = () => {
        //             // let allUrls = [...imagePreviewUrls]
        //             // allUrls.push(reader.result)
        //             // setImagePreviewUrls(allUrls)


        //             let fileInfo = {
        //                 data: thisFile,
        //                 name: thisFile.name,
        //                 size: thisFile.size,
        //                 type: thisFile.type,
        //                 url: reader.result
        //             }


        //             allFilesInfos.push(fileInfo)


        //             console.log("READ FILE")


        //             // let allFiles = [...files]
        //             // allFiles.push(thisFile)
        //             // setFiles(allFiles)
        //         }
        //         reader.readAsDataURL(thisFile)
        //         console.log("READING FILE")
        //     })

        //     setFilesInfo(allFilesInfos)

        // }

    }


    const fileChangedHandler = event => {

        if (event.target.files.length) {

            let thisFile = event.target.files[0]
            let fileSize = thisFile.size
            if (fileSize) {

                if ((fileSize / 1024) > 600) {
                    cogoToast.warn(<div>
                        <p>Choose lower resolution if possible.</p>
                        <p>This file will take time to upload.</p>
                    </div>, { hideAfter: 5 })
                }


                console.log("FILE IS ")
                console.log(thisFile)
                console.log("FILESIZE IS ")
                console.log(fileSize / 1024)
            }


            selectAFile(event.target.files[0])
            let reader = new FileReader();
            reader.onloadend = () => {
                setImagePreviewUrl(reader.result)
            }


            reader.readAsDataURL(event.target.files[0])
        }


    }


    const removeImage = (imgUrl, index) => {


        // let filteredImageUrls = imagePreviewUrls.filter(thisUrl => {
        //     return thisUrl !== imgUrl
        // })
        // setImagePreviewUrls(filteredImageUrls ?? [])


        let filteredFileInfos = filesInfo.filter(thisFileInfo => {
            return thisFileInfo.url !== imgUrl
        })
        setFilesInfo(filteredFileInfos ?? [])

        console.log("All File Infos after removing are ");
        console.log(filteredFileInfos);

        // console.log("Image Urls after removing are ");
        // console.log(filteredImageUrls);

        // console.log("Files after removing are ");
        // console.log(files);
    }


    const imagePreviews = () => {
        let imagePreview = (<div className="previewText is-centered has-text-centered is-size-7 has-text-grey-light image-container"> Select Image for Preview </div>);
        if (filesInfo.length) {

            // console.log("allFilesInfos are ");
            // console.log(filesInfo);

            imagePreview = (


                <div className=" uploadInfoContainer pad10v5w marginTop10">
                    {
                        filesInfo.map((thisFileInfo, index) => {
                            return <Box key={index} className="uploadInfoImage">
                                <div>
                                    <Button rounded className=" is-small " onClick={() => { removeImage(thisFileInfo.url, index) }} >x</Button>
                                    <Image className="is-centered" src={thisFileInfo.url} size={128} />
                                </div>
                            </Box>
                        })
                    }
                </div>

            )

        }
        return imagePreview
    }

    const imagePreview = () => {
        let imagePreview = (<div className="previewText is-centered has-text-centered is-size-7 has-text-grey-light image-container">Please select an Image for Preview</div>);
        if (imagePreviewUrl) {
            imagePreview = (<div className="image-container is-centered has-text-centered" ><img src={imagePreviewUrl} alt="icon" width="200" /> </div>);
        }
        return imagePreview
    }







    return (
        modal()
    )

}

export default ImageUploader