import React from 'react'
import { screen } from '../../utilities/enums'
import Orders from '../../General/Orders'

const SearchOrdersFactory = () => {

    return (
        <div>
            {/* <p className='type74 has-text-weight-semibold has-text-grey-light has-text-centered'>Orders At Factory.</p>
            <br /> */}
            <Orders columnAuxSize={8} isSearchable={true} screen={screen.Search_Factory} />
        </div>
    ) 
}

export default SearchOrdersFactory