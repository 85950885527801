import React from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Heading } from 'react-bulma-components/dist';
import PageHeader from '../../containers/PageHeader'
import Customers from '../../General/Customers'
import SearchClients from './search-clients'
import ColumnAux from '../../containers/ColumnAux';
import { screen } from '../../utilities/enums';

const Clients = (props) => {

    let pathOrder = () => {

        console.log('RE RENDERED CLIENTS')

        let path = props.location.pathname
        let links = path.split('/')

        // removing initial blank item after split
        links.shift()

        return links
    }

    let lastPath = () => {
        let path = pathOrder()
        let thisTitle = path[path.length - 1]
        return thisTitle
    }

    let thisPage = () => {
        switch (lastPath()) {
            case 'search': return <SearchClients screen={screen.Search_Clients} />;
            default:
                return <Customers screen={screen.AllClients} />;
        }
    }

    return (
        <div>
            <PageHeader crumbItems={pathOrder()} />
            {thisPage()}
        </div>

    )
}

export default Clients