import React, { useState, useEffect } from 'react'
import { Box, Button, Modal, Heading, Columns, Table } from 'react-bulma-components/dist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPrint } from '@fortawesome/free-solid-svg-icons';
import ColumnAux from '../../containers/ColumnAux'
import LoadingAux from '../../containers/LoadingAux'

import { Position } from '../../utilities/UserClasses'
import { StyleSelection, Product } from '../../utilities/Classes'

import { getLocations, getUsers, getCurrentUser } from '../../utilities/Store';
import cogoToast from 'cogo-toast';

const ProductCancelModal = (props) => {



    const setClosed = () => {
        props.closed()
    }


    let [cancelReason, setCancelReason] = useState(props.product.cancellationInfo ?? "")
    let [saving, setSaving] = useState(false)
    

    const saveInfo = () => {    
        setSaving(true)
        let prodCopy = Product.copyFrom(props.product)
        let cu = getCurrentUser()
        let preInfo = `Product cancelled by ${cu.name}. Reason : `
        prodCopy.isCancelled = true
        prodCopy.cancelledBy = cu
        prodCopy.cancellationInfo = preInfo + cancelReason


        prodCopy.update( (succ, msg) => {
            setSaving(false)
            if (succ){
                props.productCancelled(prodCopy)
                setClosed()
            }else{
                cogoToast.error('Could not cancel product. Check internet and try again.')
                console.log(msg)
            }
        })
    }


    const infoChanged = (event) => {
        // alert(`Change in Selection name = ${selectionName}`)

        // console.log('EVENT TARGET VALUE = ', event.target.value)


        // let val = event.target.value
        // // let o = { ...styleSelection }
        // let thisVal = val.replace( /\n/g, "" ).split(",")

        // let fVal = thisVal.filter( (opt) => {
        //     return opt !== "" && opt !== " "  && opt !== ","
        // } )

        setCancelReason(event.target.value)
    }






const createTables = () => {
    return (
        <div >
                    <div>
                        <label className="label type70  marginTop10 has-text-centered">Are you sure? </label>
                        <p className=' type55 has-text-centered'> Are you sure you want to remove this product. This cannot be undone.</p>    
                        <br />                
                    </div>
                    <div className="control is-fullwidth">
                        <div className='field '>
                            <textarea name='username' defaultValue={cancelReason} placeholder='Reason to cancel' type='input' className='input textarea has-text-weight-semibold type65' onChange={e => { infoChanged(e) }} />
                        </div>
                    </div>
        </div>
    )
}

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  

const camelToSentenceCase = (text) => {
    var result = text.replace(/([A-Z])/g, " $1");
    var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult
}



const form = () => {
    return (
        <div className='is-two-fifths  paddingHor20'>
            <br />
            {createTables()}
            <br />
            <Button disabled={saving} loading={saving} className='is-fullwidth is-danger' onClick={saveInfo} > Confirm </Button>
            <br />
        </div>
    )
}


const modal = () => {
 
    createTables()

    return (
        <Modal show={true} closeOnBlur={true} closeOnEsc={true} showClose={true} onClose={() => setClosed()} >
            <Modal.Content className='is-padingless'>
                <Box className='is-radiusless is-padingless fadeIn has-background-light'>
                    <Heading size={6} className='is-size-5-mobile has-text-black has-text-semibold has-text-centered' > Cancel Product </Heading>
                    <ColumnAux isMiddle={true} size={10} className=' is-centered '>

                        <div className='field is-centered'>
                            {/* <label className="label type60 ">Email</label> */}
                            {/* <input name={position.name} defaultValue={position.name} placeholder={'Name of Position'} type='input' className='input  has-text-weight-semibold type65' onChange={e => { positionNameChanged(e) }} /> */}
                        </div>

                        {form()}
                    </ColumnAux>

                </Box>
            </Modal.Content>
        </Modal>

    )
}

return modal()

}

export default ProductCancelModal