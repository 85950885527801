import React, { useState, useEffect } from 'react'
import { Box, Button, Modal, Heading, Columns, Dropdown } from 'react-bulma-components/dist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPrint } from '@fortawesome/free-solid-svg-icons';
import ColumnAux from '../../containers/ColumnAux'
import DatePicker from 'react-datepicker'
import { Order } from '../../utilities/Classes'
import { getLocations, getUsers, getCurrentUser, getCurrentLocation } from '../../utilities/Store';
import { newOrderNo } from '../../utilities/utils';

import cogoToast from 'cogo-toast';
import OrderCancelModal from './OrderCancelModal'

const OrderModal = (props) => {

    const setClosed = () => {
        props.closed()
    }


    function makeid(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const newOrder = () => {
        // if (props.isNewOrder === true){
        let no = Order.init()
        no.customer = props.customer
        no.orderedAt = getCurrentLocation()
        no.addedByUser = getCurrentUser()
        no.orderNo = ''
        no.bookingDate = new Date()
        console.log(no)

        return no
        // }else {
        //     return props.order
        // }
    }

    let [order, setOrder] = useState(props.order ?? newOrder())
    let [orderNoFetch, setOrderNoFetch] = useState(true)
    let [cancelOrderSelected, setCancelOrderSelected] = useState(false)
    let [saving, setSaving] = useState(false)


    // {(newOrder === true) ? <OrderModal customer={customer} isNewOrder={true} orderAdded={newOrderAdded} orderUpdated={orderWasUpdated} closed={setNoSelected} /> : null}




    useEffect(() => {

        if (orderNoFetch) {

            let ord = { ...order }
            ord.orderNo = newOrderNo()
            setOrder(ord)
            setOrderNoFetch(false)


        }


    }, [order, orderNoFetch])


    const saveOrder = () => {

        if (order.orderNo.length < 1) {
            cogoToast.warn('OrderNo Missing. Check Internet and reload.')
            // alert('Please add OrderNo before saving ')
            return
        }

        setSaving(true)

        if (props.isNewOrder === true) {
            let no = Order.copyFrom(order)
            no.customer = props.customer
            no.customerName = props.customer.name
            no.customerEmail = props.customer.email1
            no.customerPhone = props.customer.phone1
            // no.orderedAt = order.orderedAt
            // no.addedByUser = order.addedByUser
            // no.orderNo = order.orderNo
            // no.bookingDate = order.bookingDate
            // no.trialDate = order.trialDate
            // no.deliveryDate = order.deliveryDate

            no.add((succ, err) => {
                if (succ) {
                    // console.log('New Order creared')
                    cogoToast.success('Order Added')
                    props.orderAdded(no)
                    setOrder(no)
                } else {
                    cogoToast.error('Could not save Order. Check internet and try again.')
                    // alert(err)
                }

                setClosed()
            })
        } else {

            let ord = Order.copyFrom(order)

            console.log('Copied Order =')
            console.log(ord)

            ord.update((succ, err) => {
                if (succ) {
                    // console.log('Order updated')
                    // console.log(ord)
                    cogoToast.success('Order Updated')
                    props.orderUpdated(order)
                } else {
                    cogoToast.error('Could not update Order. Check internet and try again.')
                    // console.log(err)
                }
                setClosed()
            })

        }


    }


    const orderNoChanged = (e) => {
        console.log('ORDER NO WAS TRIED TO CHANGE')
        // let o = {...order}
        // o.orderNo = e.target.value
        // setOrder(o)
    }


    const internalInfoChanged = (e) => {
        let o = { ...order }
        o.internalInfo = e.target.value
        setOrder(o)
    }

    const posNoChanged = (e) => {
        let o = { ...order }
        o.posNo = e.target.value
        setOrder(o)
    }

    const bookingChanged = (date) => {
        let o = { ...order }
        o.bookingDate = date
        setOrder(o)
    }

    const trialChanged = (date) => {
        let o = { ...order }
        o.trialDate = date
        setOrder(o)
    }

    const deliveryChanged = (date) => {
        let o = { ...order }
        o.deliveryDate = date
        setOrder(o)
    }


    const form = () => {

        return (

            <div className='is-two-fifths  paddingHor20'>

                <br />

                <Columns className="is-mobile">
                    <Columns.Column size={3} className="backgroundMoreGreyLighter">
                        <label className="label type55  marginTop10 has-text-right">ORDER NO</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className='field '>
                            {/* <label className="label type60 ">Email</label> */}
                            <input disabled name='orderNo' defaultValue={order.orderNo} type='input' className='input field has-text-weight-semibold type65' placeholder='Order No' onChange={e => { orderNoChanged(e) }} />
                        </div>
                    </Columns.Column>
                </Columns>

                <Columns className="is-mobile">
                    <Columns.Column size={3} className="backgroundMoreGreyLighter">
                        <label className="label type55  marginTop10 has-text-right">POS No</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className='field '>
                            {/* <label className="label type60 ">Email</label> */}
                            <input name='posNo' defaultValue={order.posNo} type='input' className='input field has-text-weight-semibold type65' placeholder='POS No  (optional)' onChange={e => { posNoChanged(e) }} />
                        </div>
                    </Columns.Column>
                </Columns>



                <Columns className="is-mobile">
                    <Columns.Column size={3} className="backgroundMoreGreyLighter">
                        <label className="label type55  marginTop10 has-text-right">Booking Date</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className='field '>
                            <DatePicker onFocus={e => e.target.blur()} name='bookingDate' defaultValue={order.bookingDate} dateFormat="MMM/dd/yyyy" className='datePicker has-text-weight-semibold type65 is-fullwidth  input' placeholderText='mm/dd/yyyy' selected={order.bookingDate ?? null} onChange={date => bookingChanged(date)} />
                        </div>
                    </Columns.Column>
                </Columns>


                <Columns className="is-mobile">
                    <Columns.Column size={3} className="backgroundMoreGreyLighter">
                        <label className="label type55  marginTop10 has-text-right">Trial Date</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className='field '>
                            <DatePicker onFocus={e => e.target.blur()} name='trialDate' defaultValue={order.trialDate} dateFormat="MMM/dd/yyyy" className='datePicker has-text-weight-semibold type65 is-fullwidth  input' placeholderText='mm/dd/yyyy  (optional)' selected={order.trialDate ?? null} onChange={date => trialChanged(date)} />
                        </div>
                    </Columns.Column>
                </Columns>


                <Columns className="is-mobile">
                    <Columns.Column size={3} className="backgroundMoreGreyLighter">
                        <label className="label type55  marginTop10 has-text-right">Delivery Date</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className='field '>
                            <DatePicker onFocus={e => e.target.blur()} name='deliveryDate' defaultValue={order.deliveryDate} dateFormat="MMM/dd/yyyy" className='datePicker has-text-weight-semibold type65 is-fullwidth  input' placeholderText='mm/dd/yyyy  (optional)' selected={order.deliveryDate ?? null} onChange={date => deliveryChanged(date)} />

                        </div>
                    </Columns.Column>
                </Columns>

                <Columns className="is-mobile">
                    <Columns.Column size={3} className="backgroundMoreGreyLighter">
                        <label className="label type55  marginTop10 has-text-right">Internal Info</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className='field '>
                            <textarea name='internalInfo' defaultValue={order.internalInfo} type='input' className='input textarea  has-text-weight-semibold type65' placeholder='Internal Info (optional)' onChange={e => { internalInfoChanged(e) }} />

                        </div>
                    </Columns.Column>
                </Columns>

                <br />
                <br />


                <Button disabled={saving} loading={saving} className='is-fullwidth is-black' onClick={saveOrder} > Save </Button>

                <br />


            </div>
        )

    }




    const dropDownChoiceMade = (choice) => {
        if (choice === 'cancelOrder') {

            // if all products are cancelled, then we can cancel this order. Else not.

            if (props.products !== null) {
                if (props.products.length) {
                    let nonCancelled = props.products.filter((thisProd) => {
                        return (thisProd.isCancelled !== true)
                    })

                    if (nonCancelled !== null) {
                        if (nonCancelled.length > 0)
                            cogoToast.warn("Products are active in this order. Order can only be cancelled if all of its products are cancelled.")
                        return
                    }
                }
            } else {
                // order does not have products 

            }

            // alert("Cancelling order now")
            setCancelOrderSelected(true)
        }

        if (choice === 'mailOrder') {
            Order.sendOrderHTMLMail("tempObjId", "112233", "This Order Footer", "rokinife@gmail.com", "Order Details", "Order Sheet")


            alert("Order Mail Sent")
        }

    }

    const closeModals = () => {
        setCancelOrderSelected(false)
    }

    const orderCancelled = (ordCopy) => {
        setOrder(ordCopy)
        props.orderUpdated(ordCopy)
        setClosed()


    }

    const modal = () => {
        return (
            <Modal show={true} closeOnBlur={true} closeOnEsc={true} showClose={true} onClose={() => setClosed()} >
                <Modal.Content className='is-padingless has-background-light'>

                    {cancelOrderSelected ? <OrderCancelModal order={order} closed={closeModals} orderCancelled={orderCancelled} /> : null}

                    <Dropdown className="  is-arrowless  is-pulled-left has-background-white noDropdownIcon" color="light" label='•••' onChange={(val) => { dropDownChoiceMade(val) }} >
                        <Dropdown.Item value="cancelOrder">
                            Cancel Order
                        </Dropdown.Item>
                        {/* <Dropdown.Item value="mailOrder">
                            Mail Order
                        </Dropdown.Item> */}
                    </Dropdown>
                    <br />
                    <Box className='is-radiusless is-padingless no-border is-shadowless has-background-transparent '>

                        <Heading size={6} className='is-size-5-mobile has-text-black has-text-semibold has-text-centered' > {props.isNewOrder ? 'New Order' : 'Order Details'}  </Heading>
                        <ColumnAux isMiddle={true} size={10} className=' is-centered '>
                            {form()}
                        </ColumnAux>

                    </Box>
                </Modal.Content>
            </Modal>

        )
    }

    return modal()

}

export default OrderModal