import React from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { screen } from '../../utilities/enums'
import Orders from '../../General/Orders'

const CompleteOrders = () => {

    return (
        <div>
            <Orders columnAuxSize={8} headerTitle='Orders that are recieved at any Vendor but not yet marked as work-complete.' screen={screen.CompleteOrders_Vendor} />
        </div>
        
    )

}

export default CompleteOrders