import React, { useState } from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Heading, Tabs, Image, Columns, Button } from 'react-bulma-components/dist';
import Aux from '../../containers/Aux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faEdit } from '@fortawesome/free-solid-svg-icons';
import Orders from '../../General/Orders'
import { screen } from '../../utilities/enums'
import VendorModal from '../Cards/VendorModal'
import VendorImage from '../../assets/vendorImage.png'
import { getCurrentUser } from '../../utilities/Store';


const VendorProfile = (props) => {


    let cu = getCurrentUser()

    const vendorProfileTab = {
        AllOrders: 'All Orders',
        AcceptOrders: 'Accept Orders',
        CompleteOrders: 'Complete Orders',
        SendOrders: 'Send Orders',

    }

    const [currentTab, setCurrentTab] = useState(vendorProfileTab.AllOrders)
    const [currentScreen, setCurrentScreen] = useState(props.screen ?? screen.Vendors)

    const [vendor, setVendor] = useState(props.vendor ?? null)
    const [editVendor, setEditVendor] = useState(false)
    const [orderIsSelected, setOrderIsSelected] = useState(false)

    const toggleEditVendor = () => {

        console.log('Sending Vendor Object ')
        console.log(vendor)

        setEditVendor(!editVendor)
    }

    const toggleOrderIsSelected = (isSelected) => {
        setOrderIsSelected(isSelected)
    }

    const vendorUpdated = (vnd) => {
        setVendor(vnd)
        if (props.vendorUpdated != null){
            props.vendorUpdated(vnd)
        }
    }

    const changeTab = (tab) => {

        console.log('Tab Changed')

        let cs = props.screen 
        switch (tab){
            case vendorProfileTab.AllOrders : cs = screen.Vendors ; break;
            case vendorProfileTab.AcceptOrders : cs = screen.OrdersRecieved_Vendor ; break;
            case vendorProfileTab.CompleteOrders : cs = screen.CompleteOrders_Vendor ; break;
            case vendorProfileTab.SendOrders : cs = screen.SendOrders_Vendor ; break;
            default : cs = screen.Vendors ; break;
        }
        console.log('Setting Current Screen to = ' + currentScreen)
        setCurrentScreen(cs) 
        setCurrentTab(tab)
    }

    let tabs = (

        <Tabs
            //   type={select('Tab type', {boxed: 'boxed', toggle: 'toggle', 'toggle-rounded': 'toggle-rounded' }, 'boxed')}
            fullwidth={true}
            align='centered'
            size='small'
            className="boldTabs has-text-black marginTop10"
        >
            <Tabs.Tab active={currentTab === vendorProfileTab.AllOrders} onClick={() => { changeTab(vendorProfileTab.AllOrders) }} >  All Orders </Tabs.Tab>
            

            {cu.position.control.ordersControl.vendorControl.confirmOrders || cu.isAdmin  ? <Tabs.Tab active={currentTab === vendorProfileTab.AcceptOrders} onClick={() => { changeTab(vendorProfileTab.AcceptOrders) }} > To Accept </Tabs.Tab> : null }  


            {cu.position.control.ordersControl.vendorControl.completeOrders || cu.isAdmin  ? <Tabs.Tab active={currentTab === vendorProfileTab.CompleteOrders} onClick={() => { changeTab(vendorProfileTab.CompleteOrders) }} > To Complete </Tabs.Tab> : null }  

            {cu.position.control.ordersControl.vendorControl.sendOrders || cu.isAdmin  ? <Tabs.Tab active={currentTab === vendorProfileTab.SendOrders} onClick={() => { changeTab(vendorProfileTab.SendOrders) }} > To Send </Tabs.Tab> : null }  
            
            

        </Tabs>

    )



    const vendorHeader = () => {

        // let logoPicUrl = "https://logopond.com/logos/542261601e8c26d6b12cce3cb159b55a.png"

        // if (vendor) {
        //     if (vendor.logoPicUrl != null) {
        //         logoPicUrl = vendor.profilePicUrl
        //     }
        // }

        return (
            <Aux>
                <Button className="  no-decoration is-pulled-left is-small has-text-weight-bold" onClick={props.backPressed}><FontAwesomeIcon className='' icon={faChevronLeft} /></Button>
                <br />
                {/* <Heading className='has-text-bold marginTop30 type74'> {vendor ? vendor.name : null} </Heading> */}
                <div className='paddingHor20 has-text-centered'>

                    <Image className=' is-centered vendorImage has-image-centered' src={VendorImage} alt="Vendor Profile Pic" size={96} />
                    <br />
                    <Heading size={6}> {vendor.name}
                    
                    <Button className="marginTopMinus20  no-decoration  is-text is-small has-text-weight-bold" onClick={toggleEditVendor}><FontAwesomeIcon className='' icon={faEdit} /></Button>

                     </Heading>

                    <p className=' type74 has-text-semibold has-text-grey'> {vendor.description} </p>
                    <p className=' type74 has-text-semibold has-text-grey'> {vendor.address}  </p>
                    <br />
                </div>

                {tabs}
            </Aux>

        )
    }




    let orders = () => {
        return <Orders screen={currentScreen}  vendor={vendor}  columnAuxSize={8}  orderSelectUpdate={toggleOrderIsSelected}  />
    }


    return (
        <Aux>
            {editVendor ? <VendorModal closed={toggleEditVendor} vendor={vendor} isNewVendor={false} vendorUpdated={vendorUpdated} /> : null}
            {orderIsSelected ?  null :  vendorHeader() }

            {/* {vendorHeader()} */}
            <br />
            { orders() }
        </Aux>
    )

}

export default VendorProfile

