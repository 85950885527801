import React from 'react'
import { screen } from '../../utilities/enums'
import Orders from '../../General/Orders'

const ConfirmOrders = () => {

    return (
        <div>
            <Orders columnAuxSize={8} headerTitle='Orders that are sent to factory but not marked as recieved.' screen={screen.OrdersRecieved_Factory} />
        </div>
    ) 

}

export default ConfirmOrders