import React from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Heading, Button } from 'react-bulma-components/dist';
import PageHeader from '../../containers/PageHeader'
import ClientReports from './client-reports'
import OrderReports from './order-reports'
import ProductReports from './product-reports'
import StaffReports from './staff-reports'
import StockReports from './stock-reports'
import VendorReports from './vendor-reports'
import Client from '../Store/client';


const Reports = (props) => {

    let pathOrder = () => {
        let path = props.location.pathname
        let links = path.split('/')

        // removing initial blank item after split
        links.shift()

        console.log('PATHS ARE - ')
        console.log(links)

        return links
    }

    let lastPath = () => {
        let path = pathOrder()
        let thisTitle = path[path.length - 1]
        return thisTitle
    }

    let thisPage = () => {
        switch (lastPath()) {
            case 'clients': return <ClientReports />;
            case 'orders': return <OrderReports />;
            case 'products': return <ProductReports />;
            case 'staff': return <StaffReports />;
            case 'stocks': return <StockReports />;
            case 'vendors': return <VendorReports />;

            default:
                return <Heading size={5}> Select A Report Type </Heading>;
        }
    }




    return (
        <div>
            <PageHeader crumbItems={pathOrder()} />
            {/* <Heading size={5}> Vendors </Heading> */}

            {/* <Vendors screen={screen.Vendors} isToChoose={false} vendorChosen={vendorChosen} isSearchable={false} /> */}

            {thisPage()}

        </div>

    )

    
}

export default Reports