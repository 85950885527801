
import Parse from 'parse'
import { Location, Position, User, TWConfig } from './UserClasses'

import { saveLocations, savePositions, saveUsers, getUsers, saveTwConfig, getTwConfig } from './Store'
import { config } from '@fortawesome/fontawesome-svg-core';

const UsersClass = Parse.Object.extend("Users");


export const tryUserLogin = (username, password, inLocation, callback) => {



    if (inLocation === null){
        console.log("Location not seleted for tryUserLogin")
        callback(false, 'Location not selected')
        return
    }
    saveCurrentLocation(inLocation)


    const userQuery = new Parse.Query(UsersClass);
    userQuery.equalTo("username", username);
    userQuery.equalTo("password", password);

    // make Location pointer 
    let atLocation = {
        __type: 'Pointer',
        className: 'Location',
        objectId: inLocation.objectId
    }
    userQuery.equalTo('location', atLocation)

    console.log('TRYING USER LOGIN')


    console.log(`Username = ${username} . Password = ${password} . Location ObjId = ${inLocation.objectId}`)

    

    userQuery.includeAll()

    console.log('STARTING QUERY')


    userQuery.first().then((object) => {
        console.log('All Users =')
        console.log(object)


        if (object != null) {


            let thisUser = User.initFromPFObject(object, false)

            if (thisUser == null) {

                let allUsers = getUsers()
                console.log('ALL USERS')
                console.log(allUsers)
                let matchingUser = allUsers.filter((usr) => {
                    return (object.id === usr.objectId)
                })

                if (matchingUser != null) {
                    if (matchingUser.length > 0) {
                        thisUser = matchingUser[0]

                        saveCurrentUser(thisUser)
                        saveCurrentLocation(inLocation)
                    }
                }

            }

            // TODO - Save User Login and all associated info with it.
            // let isMobile = false;
            // if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
            //     isMobile = true            
            // }

            if (thisUser != null) {
                saveCurrentUser(thisUser)
                saveCurrentLocation(inLocation)


                setTimeout(() => {
                    callback(true, thisUser, '')
                }, 1000);
            } else {
                callback(false, 'Could not validate user')

            }

        } else {
            callback(false, null, 'Invalid')
        }

    }, (error) => {
        console.log('Error while getting Users ', error.message)
        callback(false, null, error.message)

    })
}


export const logoutCurrentUser = () => {
    removeCurrentUser()
}



export const saveCurrentLocation = (location) => {
    localStorage.setItem('currentLocation', JSON.stringify(location))
}

export const getCurrentLocation = () => {
    let loc = localStorage.getItem('currentLocation');

    if (loc != null) {
        loc = JSON.parse(loc)
    } else {
        return null
    }
    return loc
}



export const saveCurrentUser = (user) => {

    console.log('SAVING CURRENT USER INFO')

    delete user["password"]


    localStorage.setItem('userAuthenticated', true)
    localStorage.setItem('currentUser', JSON.stringify(user))

    // let isAdmin = user.get('isAdmin')
    // let isDeleted = user.get('isDeleted')
    // let imageUrl = user.get('imageUrl')
    // let phone = user.get('phone')
    // let position = user.get('phone')
    // let location = user.get('phone')

    localStorage.setItem('loginTime', new Date())

    // Save Position, Location, isDeleted, imageUrl and Phone as well
}


export const getCurrentUserInfo = () => {
    let user = localStorage.getItem('currentUser');

    if (user != null) {
        user = JSON.parse(user)
    } else {
        return null
    }

    const loginTimeSTR = localStorage.getItem('loginTime')
    const loginTime = new Date(loginTimeSTR)
    let difference_In_Time = new Date().getTime() - loginTime.getTime();
    let difference_In_Hours = difference_In_Time / (1000 * 3600);
    let difference_In_Minutes = difference_In_Time / (1000 * 60);

    let locationStr = ''
    let positionStr = user.position.name ?? ''
    let loginSinceStr = ''

    let thisLoc = getCurrentLocation()

    if (difference_In_Hours > 1) {
        loginSinceStr = 'Logged in since ' + difference_In_Hours.toFixed(2) + ' hrs'
    } else {
        loginSinceStr = 'Logged in since ' + difference_In_Minutes.toFixed(2) + ' mins'
    }

    user.posAtLocInfo = positionStr + ' at ' + (thisLoc.name ?? "")
    user.loginInfo = loginSinceStr

    // let isAdmin = user.get('isAdmin')
    // let isDeleted = user.get('isDeleted')
    // let imageUrl = user.get('imageUrl')
    // let phone = user.get('phone')
    // let position = user.get('phone')
    // let location = user.get('phone')

    return user
}


export const removeCurrentUser = (user) => {
    localStorage.setItem('userAuthenticated', false)
    localStorage.setItem('currentUser', null)
    localStorage.setItem('loginTime', null)
}


export const isCurrentUser = () => {

    const userAuthenticated = localStorage.getItem('userAuthenticated') === 'true';
    const currentUser = userAuthenticated ? localStorage.getItem('currentUser') : '';

    if (userAuthenticated && currentUser) {

        // TODO 
        // Remove User if logged in more than 48 hours 
        const loginTimeSTR = localStorage.getItem('loginTime')
        const loginTime = new Date(loginTimeSTR)

        // To calculate the time difference of two dates 
        let difference_In_Time = new Date().getTime() - loginTime.getTime();

        // To calculate the no. of days between two dates 
        let difference_In_Days = difference_In_Time / (1000 * 3600 * 24);


        let difference_In_Hours = difference_In_Time / (1000 * 3600);

        let difference_In_Minutes = difference_In_Time / (1000 * 60);

        let conf = getTwConfig()

        if (conf){
            if (conf.logoutAfterHours) {
                if (difference_In_Hours >= Number(conf.logoutAfterHours)){
                    removeCurrentUser()
                    return false
                }
            }
        }else
        if (difference_In_Hours >= 6) {
            removeCurrentUser()
            return false
        } else {
            // alert('Last Login Was within ' + (difference_In_Minutes).toFixed(2) + ' Minutes Ago')
        }

        return true
    }

    return false

    // localStorage.setItem('userAuthenticated', true)
    // localStorage.setItem('currentUser', user.get('username'))
    // localStorage.setItem('loginTime', new Date())
}



export const areAssetsNeeded = () => {

    const needAssets = localStorage.getItem('needAssets');
    const assetsSavedOn = localStorage.getItem('assetsSavedOn');
    const assetsSavedOnTime = new Date(assetsSavedOn)

    if (needAssets == null || assetsSavedOn == null || needAssets === true) {
        return true
    } else {
        let difference_In_Time = new Date().getTime() - assetsSavedOnTime.getTime();

        let difference_In_Hours = difference_In_Time / (1000 * 3600);

        if (difference_In_Hours > 12) {
            return true
        }
    }


    return false

}


export const getAndSaveLocations = (callback) => {

    Location.getAll((success, locs, errMsg) => {
        if (success) {
            saveLocations(locs)
            callback(true, locs, null)
        } else {
            console.log('Locations could not be fetched')
            console.log('ERROR = ' + errMsg)
            callback(false, [], "Could not read client locations")
        }
    })

}

export const getAccessAssets = (callback) => {

    console.log('FETCHING LOCATIONS')


    let need = areAssetsNeeded()

    if (need === false) {
        console.log('DO NOT NEED ASSETS')
        callback(true, "")
        return
    } else {
        console.log('ASSETS ARE NEEDED')

    }


    Location.getAll((success, locs, errMsg) => {
        if (success) {
            // console.log('LOCATIONS ARE')
            // console.log(locs)
            saveLocations(locs)


            console.log('FETCHING Positions')
            Position.getAll((success, positions, errMsg) => {
                if (success) {
                    // console.log('Positions ARE')
                    // console.log(positions)
                    savePositions(positions)

                    // console.log('GETTING Positions')
                    // getPositions()

                    console.log('FETCHING Users')
                    User.getAll((success, users, errMsg) => {
                        if (success) {
                            // console.log('Users ARE')
                            // console.log(users)


                            let usersWOpass = users.map((us) => {
                                // us.password = '••••••••'

                                delete us["password"]
                                return us
                            })

                            // saving Users without password
                            saveUsers(usersWOpass)

                            localStorage.setItem('assetsSavedOn', new Date())
                            localStorage.setItem('needAssets', false)



                            TWConfig.getConfig((success, conf, errMsg) => {
                                console.log('FETCHING CONFIG')

                                if (success) {
                                    // save Config
                                    console.log('SAVING CONFIG')
                                    saveTwConfig(conf)




                                } else {
                                    console.log('Could not fetch config. ' + errMsg)
                                }
                            })


                            callback(true, "")


                            // GetStyleSelections 


                            // console.log('GETTING Users')
                            // getUsers()

                        } else {
                            console.log('Users could not be fetched')
                            console.log('ERROR = ' + errMsg)
                            callback(false, "Users could not be read.")

                        }
                    })



                } else {
                    console.log('Positions could not be fetched')
                    console.log('ERROR = ' + errMsg)

                    callback(false, "Positions could not be read.")

                }
            })

            // console.log('GETTING LOCATIONS')
            // getLocations()
        } else {
            console.log('Locations could not be fetched')
            console.log('ERROR = ' + errMsg)
            callback(false, "Locations could not be read.")

        }
    })

}





//   const getLocs = () => {
//     console.log('FETCHING LOCATIONS')
//     Location.getAll((success, locs, errMsg) => {
//       if (success){
//         console.log('LOCATIONS ARE')
//         console.log(locs)
//         saveLocations(locs)

//         console.log('GETTING LOCATIONS')
//         getLocations()

//       }else{
//         console.log('Locations could not be fetched')
//         console.log('ERROR = ' + errMsg)
//       }
//     })
//   }

//   const getPos = () => {
//     console.log('FETCHING Positions')
//     Position.getAll((success, positions, errMsg) => {
//       if (success){
//         console.log('Positions ARE')
//         console.log(positions)
//         savePositions(positions)

//         console.log('GETTING Positions')
//         getPositions()

//       }else{
//         console.log('Positions could not be fetched')
//         console.log('ERROR = ' + errMsg)
//       }
//     })
//   }

//   const getUsrs = () => {
//     console.log('FETCHING Users')
//     User.getAll((success, users, errMsg) => {
//       if (success){
//         console.log('Users ARE')
//         console.log(users)
//         saveUsers(users)

//         console.log('GETTING Users')
//         getUsers()

//       }else{
//         console.log('Users could not be fetched')
//         console.log('ERROR = ' + errMsg)
//       }
//     })
//   }