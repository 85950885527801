import React, { useState } from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Tile, Box, Columns, Button, Tag, Image, Modal, Heading, Card, Label, InputFile, Dropdown } from 'react-bulma-components/dist';
import { pages, departments, screen, departmentForScreen, clientScreenTab, Posture, imageUrlForPostures } from '../utilities/enums'
import ColumnAux from './ColumnAux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPrint, faUpload } from '@fortawesome/free-solid-svg-icons';
import LoadingAux from './LoadingAux';
import { CustomerPic, ProductPic } from '../utilities/Classes';
import cogoToast from 'cogo-toast';


const PostureModal = (props) => {


    const posGroups = {
        stance: "stance",
        shoulderFront: "shoulderFront",
        shoulderBack: "shoulderBack",
        chest: "chest",
        stomach: "stomach",
        seat: "seat"
    }

    const backPressed = () => {
        props.closed()
    }

    const [selectedPostures, setSelectedPostures] = useState(props.selectedPostures ?? [])
    const [isSaving, setIsSaving] = useState(false)


    const stancePos = [Posture.StanceNormal, Posture.StanceForwardLeaning, Posture.StanceErect]
    const shoulderFrontPos = [Posture.ShoulderFrontFlat, Posture.ShoulderFrontNormal, Posture.ShoulderFrontSteep]
    const shoulderBackPos = [Posture.ShoulderBackRegular, Posture.ShoulderBackHigh, Posture.ShoulderBackSloping]
    const chestPos = [Posture.ChestThin, Posture.ChestFit, Posture.ChestNormal, Posture.ChestMuscular, Posture.ChestLarge]
    const stomachPos = [Posture.StomachThin, Posture.StomachNormal, Posture.StomachMedium, Posture.StomachLarge]
    const seatPos = [Posture.SeatThin, Posture.SeatNormal, Posture.SeatLarge, Posture.SeatCurved]


    const posSelected = (pos, group) => {
        let selectedPoses = [...selectedPostures];




        // If it is already selected one
        if (selectedPoses.includes(pos)) {
            // just remove this 
            selectedPoses = selectedPoses.filter(thisPos => {
                return thisPos !== pos;
            })
            setSelectedPostures(selectedPoses)
            return
        }

        // If it is any else
        let thisGroupPos = []
        switch (group) {
            case posGroups.stance: thisGroupPos = stancePos; break;
            case posGroups.shoulderFront: thisGroupPos = shoulderFrontPos; break;
            case posGroups.shoulderBack: thisGroupPos = shoulderBackPos; break;
            case posGroups.chest: thisGroupPos = chestPos; break;
            case posGroups.stomach: thisGroupPos = stomachPos; break;
            case posGroups.seat: thisGroupPos = seatPos; break;
        }

        let existingPosFromThisGroup = selectedPoses.filter(thisPos => {
            return thisGroupPos.includes(thisPos);
        })

        if (existingPosFromThisGroup.length) {
            // remove all postures from this group
            selectedPoses = selectedPoses.filter(thisPos => {
                return !thisGroupPos.includes(thisPos);
            })


        }
        selectedPoses.push(pos)
        setSelectedPostures(selectedPoses)
    }


    const postureSelectBox = (posture, isSelected, group) => {
        return (
            <div key={posture} className="marginLeft8 " onClick={() => { posSelected(posture, group) }}>
                <Box className={`bodyInfoPosture is-marginless has-background-white ${isSelected ? "selectionBorder" : ""} `}>
                    <Image src={imageUrlForPostures(posture)} size={128} />
                    <p className="has-text-centered type55 has-text-grey"> {posture} </p>
                    <br />
                </Box>
            </div>


        )
    }

    const stance = () => {
        let allPos = stancePos
        return (
            <div>
                <Heading size={6} className="has-text-weight-semibold marginBottom6 marginLeft10"> Stance </Heading>
                <div className=" bodyPostureContainer topMarginMinus10">
                    {
                        allPos.map(pos => {
                            return postureSelectBox(pos, selectedPostures.includes(pos), posGroups.stance)
                        })
                    }
                </div>
                <br />
            </div>
        )
    }

    const shoulderFront = () => {
        let allPos = shoulderFrontPos
        return (
            <div>
                <Heading size={6} className="has-text-weight-semibold marginBottom6 marginLeft10"> Shoulder Front </Heading>
                <div className=" bodyPostureContainer topMarginMinus10">
                    {
                        allPos.map(pos => {
                            return postureSelectBox(pos, selectedPostures.includes(pos), posGroups.shoulderFront)
                        })
                    }
                </div>
                <br />
            </div>
        )
    }

    const shoulderBack = () => {
        let allPos = shoulderBackPos
        return (
            <div>
                <Heading size={6} className="has-text-weight-semibold marginBottom6 marginLeft10"> Shoulder Back </Heading>
                <div className=" bodyPostureContainer topMarginMinus10">
                    {
                        allPos.map(pos => {
                            return postureSelectBox(pos, selectedPostures.includes(pos), posGroups.shoulderBack)
                        })
                    }
                </div>
                <br />
            </div>
        )
    }

    const chest = () => {
        let allPos = chestPos
        return (
            <div>
                <Heading size={6} className="has-text-weight-semibold marginBottom6 marginLeft10"> Chest </Heading>
                <div className=" bodyPostureContainer topMarginMinus10">
                    {
                        allPos.map(pos => {
                            return postureSelectBox(pos, selectedPostures.includes(pos), posGroups.chest)
                        })
                    }
                </div>
                <br />
            </div>
        )
    }

    const stomach = () => {
        let allPos = stomachPos
        return (
            <div>
                <Heading size={6} className="has-text-weight-semibold marginBottom6 marginLeft10"> Stomach </Heading>
                <div className=" bodyPostureContainer topMarginMinus10">
                    {
                        allPos.map(pos => {
                            return postureSelectBox(pos, selectedPostures.includes(pos), posGroups.stomach)
                        })
                    }
                </div>
                <br />
            </div>
        )
    }

    const seat = () => {
        let allPos = seatPos
        return (
            <div>
                <Heading size={6} className="has-text-weight-semibold marginBottom6 marginLeft10"> Seat </Heading>
                <div className=" bodyPostureContainer topMarginMinus10">
                    {
                        allPos.map(pos => {
                            return postureSelectBox(pos, selectedPostures.includes(pos), posGroups.seat)
                        })
                    }
                </div>
                <br />
            </div>
        )
    }


    const savePressed = () => {
        setIsSaving(true)
        props.posturesChanged(selectedPostures)
    }


    const modal = () => {
        return (
            <Modal show={true} closeOnBlur={true} closeOnEsc={true} showClose={true} onClose={() => props.closed()} >
                <Modal.Content className='is-padingless'>
                    <Box className='is-radiusless is-padingless fadeIn has-background-light'>

                        {/* <Button className="" onClick={savePressed}> Done </Button> */}
                        <LoadingAux isLoading={false} loaderHeight={300} >
                            {/* <Dropdown className=" is-arrowless is-grey noDropdownIcon" color="light" label='•••' onChange={(val) => { deletePressed(val) }} >
                                <Dropdown.Item value="delete">
                                    Delete
                                </Dropdown.Item>
                            </Dropdown> */}

                            {/* <Heading size={6} className='is-size-5-mobile has-text-black has-text-semibold has-text-centered' > Image </Heading> */}
                            <ColumnAux isMiddle={true} size={12} className=' is-centered '>

                                <Heading size={4} className="has-text-grey-light has-text-centered"> Postures </Heading>
                                <br />

                                {stance()}
                                {shoulderFront()}
                                {shoulderBack()}
                                {chest()}
                                {stomach()}
                                {seat()}

                                <br />
                                    <Button disabled={isSaving} loading={isSaving} className='is-fullwidth is-black' onClick={savePressed} > Save </Button>
                                <br />


                            </ColumnAux>
                            <br />
                        </LoadingAux>


                    </Box>
                </Modal.Content>
            </Modal>

        )
    }


    return (
        modal()
    )

}

export default PostureModal