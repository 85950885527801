import React, { useState } from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Heading, Tabs, Image, Columns } from 'react-bulma-components/dist';
import ProductSettings from './productSettings'
import UserSettings from './userSettings'
import TemplateSettings from './templateSettings'
import StyleSelections from './styleSelections'


import Aux from '../../containers/Aux'
import ColumnAux from '../../containers/ColumnAux'
// import { StyleSelection } from '../../utilities/Classes';


const ClientWindow = () => {



    //vendorTab
    const settingsTab = {
        Products: 'Products',
        Templates: 'Measurement Templates.',
        Users: 'Users.',
        Selections: 'Selections'
    }


    const [currentTab, setCurrentTab] = useState(settingsTab.Templates)

    let tabs = (
        <Tabs
            //   type={select('Tab type', {boxed: 'boxed', toggle: 'toggle', 'toggle-rounded': 'toggle-rounded' }, 'boxed')}
            fullwidth={true}
            align='centered'
            size='small'
            className="boldTabs has-text-black marginTop10"
        >
            {/* <Tabs.Tab active={currentTab === settingsTab.Products} onClick={() => { changeTab(settingsTab.Products) }} >   Products </Tabs.Tab> */}

            <Tabs.Tab active={currentTab === settingsTab.Templates} onClick={() => { changeTab(settingsTab.Templates) }} > Templates </Tabs.Tab>

            <Tabs.Tab active={currentTab === settingsTab.Users} onClick={() => { changeTab(settingsTab.Users) }} > Users </Tabs.Tab>

            <Tabs.Tab active={currentTab === settingsTab.Selections} onClick={() => { changeTab(settingsTab.Selections) }} > Selections </Tabs.Tab>


        </Tabs>
    )



    const changeTab = (tab) => {
        setCurrentTab(tab)
    }


    const header = () => {

        return (
            <Aux>
                {/* <Heading className='has-text-bold type74'> Vendore Window </Heading> */}
                <Columns>
                    {/* <Columns.Column size={1} className="is-narrow">
                        <Image src={profilePicUrl} alt="Customer Profile Pic" size={64} rounded />
                    </Columns.Column> */}
                    <Columns.Column >
                        {tabs}
                    </Columns.Column>
                </Columns>
            </Aux>

        )
    }

    const aptSection = () => {
        switch (currentTab) {
            case settingsTab.Products: return <ProductSettings />;
            case settingsTab.Templates: return <TemplateSettings />;
            case settingsTab.Selections: return <StyleSelections />;

            default: return <UserSettings  />;
        }
    }

    const page = () => {
        return (
            <div>
                {header()}
                {<ColumnAux size={ currentTab === settingsTab.Users ? 5 : 8} isMiddle={true} >
                    {aptSection()}
                </ColumnAux>
                }
            </div>
        )
    }


    return (
        page()
    )



}

export default ClientWindow