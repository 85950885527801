import React from 'react'
import { Box, Columns, Button, Tag } from 'react-bulma-components/dist';
import { invoiceStatus, Status } from '../../utilities/enums'
import { dateToDateString } from '../../utilities/helper'
import { getCurrentLocation } from '../../utilities/Store';
import mapPinMicro from '../../assets/mapPinMicro.svg'


const OrderCard = (props) => {

    const payNowPressed = () => {
        props.payNowPressed(props.objid)
    }

    const viewPressed = (order) => {
        props.selectOrder(order)
    }

    // constructor(id, type, name, description, createdOn, dueDate, amount, currency, paidFull, paymentDate, paymentId, fileURL, status, orderId, userId) {


    const orderBox = (order) => {

        let isMainLoc = true
        let locString = ""
        let oa = order.orderedAt

        // console.log(" BJKSBJS ORDERED AT ")
        // console.log(oa)
        // console.log(" BJKSBJS CL IS ")
        // console.log(getCurrentLocation())

        if (oa) {
            if (oa.objectId !== getCurrentLocation().objectId) {
                locString = oa.name
                isMainLoc = false
            }
        }

        // console.log(" ISMAINLOC ")
        // console.log(isMainLoc)


        //  id, orderNo, productsCount, bookingD, trialD, deliveryD, status

        return (
            <Box key={order.objectId} className='hoverShine noBreakWords generalCardBG' onClick={() => { viewPressed(order) }}  style={{cursor: 'pointer'}}>

                <Columns className='is-mobile is-size-7'>
                    <Columns.Column className=''>
                        <p className='has-text-grey has-text-weight-semibold  marginBottom5'> {order.isConciergeOrder ? <span className=" has-text-bold  type54 conciergeIconText">C</span> : null} #{order.orderNo} </p>
                        <p className="is-capitalized type50">{(order.isCancelled === true) ? "CANCELLED" : <span className={order.status === Status.Delivered ?  "has-text-success" : "has-text-grey-light " }>{order.status} </span>  }</p>
                        <p className='maxWrap165 type50  has-text-grey has-text-weight-medium'> {order.posNo ? "POS: " + order.posNo  :  ""} </p>
                        {/* { (order.addedByUser && (order.isConciergeOrder !== true)) ?  <p className='type50'>via {order.addedByUser.name}  </p>  : null} */}
                    </Columns.Column>
                    <Columns.Column className=''>
                        <p className='has-text-grey  marginBottom5 '> For </p>
                        <p className='is-capitalized '>{order.customerName ?? ""}</p>
                    </Columns.Column>
                    <Columns.Column className=''>
                        <p className='has-text-grey  marginBottom5'> Booking </p>
                        <p>{order.bookingDate ? dateToDateString(order.bookingDate) : "."}</p>
                    </Columns.Column>
                    <Columns.Column className=''>
                        <p className='has-text-grey  marginBottom5'> Trial </p>
                        <p>{order.trialDate ? dateToDateString(order.trialDate) : "."}</p>
                    </Columns.Column>
                    <Columns.Column className=''>
                        <p className='has-text-grey  marginBottom5'> Delivery </p>
                        <p>{order.deliveryDate ? dateToDateString(order.deliveryDate) : "."}</p>
                    </Columns.Column>
                    {/* <Columns.Column className=''>
                        <p className='has-text-grey  marginBottom5'> Status </p>
                        <Tag color="light" >  {(order.isCancelled === true) ? "CANCELLED" : <span className={order.status === Status.Delivered ?  "has-text-success" : "" }>{order.status} </span>  }</Tag>
                    </Columns.Column> */}
                    {/* <Columns.Column className=''>
                        <Button className='is-small is-fullwidth is-outlined' onClick={() => { viewPressed(order) }}> View </Button>
                    </Columns.Column> */}
                </Columns>
                {
                    (isMainLoc === false) ?
                        <p className="type45 has-text-grey topMarginMinus10"><img src={mapPinMicro}  width="6" />&nbsp;{locString}</p> :
                        null
                }
            </Box>
        )
    }

    const paidInvoiceBox = (id, name, description, dueDate, amount, status, paymentId, paymentDate) => {

        return (
            <Box className='hoverShine noBreakWords generalCardBG'>
                <Columns className='is-mobile is-size-7'>
                    <Columns.Column className=''>
                        <p className='has-text-grey has-text-weight-semibold  marginBottom5'> INVOICE </p>
                        <p className='maxWrap165 has-text-weight-semibold'>{id} </p> <p>{name}  </p><p>({description}) </p>
                    </Columns.Column>
                    <Columns.Column className=''>
                        <p className='has-text-grey  marginBottom5'> Due Date </p>
                        <p>{dueDate}</p>
                    </Columns.Column>
                    <Columns.Column className=''>
                        <p className='has-text-grey  marginBottom5'> Amount </p>
                        <p>₹{amount}</p>
                    </Columns.Column>
                    <Columns.Column className=''>
                        <p className='has-text-grey  marginBottom5'> Payment </p>
                        <Tag color="light" >{status}</Tag>
                    </Columns.Column>
                    <Columns.Column className=''>
                        <p className='has-text-grey  marginBottom5'> Payment Id </p>
                        <p>{paymentId}</p>
                        <p>({paymentDate})</p>
                    </Columns.Column>
                    <Columns.Column className=''>
                        <Button className='is-small is-fullwidth is-outlined' onClick={viewPressed}> View </Button>
                    </Columns.Column>
                </Columns>
            </Box>
        )
    }

    const pendingInvoiceBox = (id, name, description, dueDate, amount, status) => {
        return (
            <Box className='hoverShine noBreakWords generalCardBG'>
                <Columns className='is-mobile is-size-7'>
                    <Columns.Column className=''>
                        <p className='has-text-grey has-text-weight-semibold  marginBottom5'> INVOICE </p>
                        <p className='maxWrap165 has-text-weight-semibold' >{id}</p><p>{name}  </p><p>({description}) </p>
                    </Columns.Column>
                    <Columns.Column className=''>
                        <p className='has-text-grey  marginBottom5'> Due Date </p>
                        <p>{dueDate}</p>
                    </Columns.Column>
                    <Columns.Column className=''>
                        <p className='has-text-grey  marginBottom5'> Amount </p>
                        <p>${amount}</p>
                    </Columns.Column>
                    <Columns.Column className=''>
                        <p className='has-text-grey  marginBottom5'> Payment </p>
                        <Tag className='has-text-danger' color="" >{status}</Tag>
                    </Columns.Column>
                    <Columns.Column className=''>
                        <Button className='is-small is-outlined' onClick={viewPressed}> View </Button>
                    </Columns.Column>
                    <Columns.Column className=''>
                        <Button className='is-black is-fullwidth is-small is-outlined' onClick={payNowPressed}> Pay Now </Button>
                    </Columns.Column>
                </Columns>
            </Box>
        )
    }




    const invoiceCardToShow = (status) => {
        let invoiceCard = orderBox(props.id, props.orderNo, props.productsCount, props.bookingD, props.trialD, props.deliveryD, props.status)
        // switch (status) {
        //     // Cancelled Case is Pending
        //     case invoiceStatus.Pending: invoiceCard = pendingInvoiceBox(props.id, props.name, props.description, props.dueDate, props.amount, props.status); break;
        //     default: invoiceCard = paidInvoiceBox(props.id, props.name, props.description, props.dueDate, props.amount, props.status, props.paymentId, props.paymentDate); // For Alloted Case
        // }
        return invoiceCard
    }

    return (
        // invoiceCardToShow(props.status)
        orderBox(props.order)
    )
}

export default OrderCard