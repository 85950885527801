import React, { useState } from 'react'
import { Box, Columns, Button, Image } from 'react-bulma-components/dist';
import { invoiceStatus } from '../../utilities/enums'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { dateToDateString } from '../../utilities/helper'
import { Vendor } from '../../utilities/Classes'
import VendorImage from '../../assets/vendorImage.png'





const VendorCard = (props) => {



    // const custs = () => {

    //     let cust1 = Customer.init()
    //     cust1.name = 'Dwyane Johnson'
    //     cust1.country = '+1'
    //     cust1.phone1 = '903909090'
    //     cust1.email1 = 'rocky@gmail.com'

    //     let cust2 = Customer.init()
    //     cust2.name = 'Idrys Elba'
    //     cust2.country = '+91'
    //     cust2.phone1 = '3576353657'
    //     cust2.email1 = 'elba@gmail.com'

    //     let cust3 = Customer.init()
    //     cust3.country = '+91'
    //     cust3.name = 'Jason Stathom'
    //     cust3.phone1 = '46754654757'
    //     cust3.email1 = 'json@gmail.com'

    //     let allCusts = [cust1, cust2, cust3]

    //     return allCusts.map( (c, index) => {
    //         return customerBox(c, index)
    //     })

    // }


    const vendorBox = (vendor) => {

        console.log('Got Vendor')
        console.log(vendor)

        // let imageUrlOfUser = 'https://logopond.com/logos/542261601e8c26d6b12cce3cb159b55a.png'
        // let imageUrlOfUser = 'https://ui-avatars.com/api/?color=909090&name=' + customer.name

        return (
            <Box className='hoverShine is-radiusless vendorCard has-background-grey-darker has-text-centered noBreakWords generalCardBG' onClick={() => { props.selectVendor(vendor) }} style={{cursor: 'pointer'}}>

                <div className=" marginBottom20  has-text-centered ">
                    <Image  className=' vendorImage has-image-centered' src={VendorImage} alt="Vendor Profile Image" size={64} />
                </div>

                <p className="fieldHeight20 marginBottom5 has-text-white-ter has-text-weight-bold type65 is-uppercase noBreakWords" >{vendor.name }</p>
                <p className="fieldHeight20  has-text-grey has-text-grey-er has-text-weight-semibold type55 noBreakWords marginBottom10" >{vendor.description}</p>
                {/* <Button className='  is-small is-white is-fullwidth is-outlined' onClick={() => { props.selectVendor(vendor) }}> { props.isToChoose ? 'Choose' : 'View'  } </Button> */}
            </Box>
        )
    }





    return (
        vendorBox(props.vendor)
    )
}

export default VendorCard