import React, { useState, useEffect } from 'react'
import { Box, Button, Modal, Heading, Columns, Table } from 'react-bulma-components/dist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPrint } from '@fortawesome/free-solid-svg-icons';
import ColumnAux from '../../containers/ColumnAux'
import LoadingAux from '../../containers/LoadingAux'

import { Position } from '../../utilities/UserClasses'
import { StyleSelection, Product } from '../../utilities/Classes'

import { getLocations, getUsers, getCurrentUser } from '../../utilities/Store';
import cogoToast from 'cogo-toast';

const StylesModal = (props) => {



    const setClosed = () => {
        props.closed()
    }


    let [selections, setSelections] = useState(props.product.styleSelections ?? [])
    let [saving, setSaving] = useState(false)
    let [allSS, setAllSS] = useState([])
    let [fetchingAllSS, setFetchingAllSS] = useState(true)


    useEffect(() => {
        StyleSelection.getAll([], (succ, fetchedSSs, errMsg) => {
            setFetchingAllSS(false)
            if (succ) {

                let sssCopy = fetchedSSs.map((ss) => {
                    ss.options.push("")
                    return ss
                })

                setAllSS(sssCopy)
            } else {
                console.log('Error while fetching StyleSelections. ' + errMsg)
                cogoToast.error('Could not fetch Style Selections. Check internet and reload.')
            }
        })

    }, [fetchingAllSS])







    const saveSelections = () => {

        
        setSaving(true)
        let prodCopy = Product.copyFrom(props.product)
        prodCopy.styleSelections = selections

        console.log("SAVING SELECTIONS")
        console.log(selections)

        prodCopy.update( (succ, msg) => {
            setSaving(false)
            if (succ){
                props.stylesAreSaved(selections)
            }else{
                cogoToast.error('Could not update style selections. Check internet and try again.')
                console.log(msg)
            }
        })
    }


    const styleValChanged = (event, selectionObj) => {
        // alert(`Change in Selection name = ${selectionName}`)

        console.log('EVENT TARGET VALUE = ', event.target.value)

        let ssCopy = [...selections]
        // console.log("EXISTING SELECTIONS")
        // console.log(selections)

        // console.log("SelectionObj Name")
        // console.log(selectionObj.name)

        // console.log("SelectionObj StyleSelectionId")
        // console.log(selectionObj.styleSelectionId)

        // console.log("SelectionObj ObjectId")
        // console.log(selectionObj.objectId)

        const index = ssCopy.findIndex((ch) => {
            return (ch.key === selectionObj.name || ch.styleSelectionId === selectionObj.objectId)
        })





        if (index != null && index > -1) {
            // console.log("INDEX")
            // console.log(index)
            let thisCh = ssCopy[index]
            // console.log("THIS CH")
            // console.log(thisCh)
            thisCh.value = event.target.value
            ssCopy[index] = thisCh
            setSelections(ssCopy)
        }else{

            // Add fresh 
    //        { "key": "FRONT BUTTONS",
    // "value": "SINGLE BREASTED 2 BUTTON",
    // "styleSelectionId": "",
    // "imageSrc": "" }

            let newSS = { 
                "key" : selectionObj.name,
                "value" : event.target.value ?? "", 
                "styleSelectionId" : selectionObj.objectId, 
                "imageSrc" : ""
            }

            ssCopy.push(newSS)
            setSelections(ssCopy)

        }

        // ssCopy.map((el, index) => {
        //     if (el.name === selectionObj.name || el.styleSelectionId === selectionObj.styleSelectionId){
        //         let elCopy = {...el}
        //         elCopy.value = event.target.value
        //         ssCopy[index] = elCopy
        //     }
        //     return null
        // })

        // setSelections(ssCopy)
        console.log('SELECTIONS ARE = ')
        console.log(selections)

    }

    // const positionNameChanged = (e) => {
    //     console.log('position NO WAS TRIED TO CHANGE')
    //     let o = {...position}
    //     o.name = e.target.value
    //     setPosition(o)
    // }





    const makeSelections = () => {

        let matchingSS = allSS.filter((ss) => {
            return ss.applicableOn.includes(props.product.type.toUpperCase())
        })


        // SORT MATCHING SS 
        matchingSS.sort( (a, b) => {
            return a.name > b.name
        })

      let allFields =  matchingSS.map((thisMss, index) => {
            let thisValue = thisMss.selectedOption

            console.log("_______SELECTIONS ARE_______");
            console.log(selections);

            let filtered = selections.filter((ss) => {  

                if (!ss.key){
                    console.log("_______SELECTION IS NOT AN OBJECT_______");
                    console.log(ss);
                }

                return (ss.styleSelectionId === thisMss.objectId) || (ss.key.toUpperCase() === thisMss.name)
            
            })
            if (filtered != null) {
                if (filtered.length) {
                    let thisSS = filtered[0]
                    thisValue = thisSS.value
                }
            }

            // thisMss.options.push("")


            return (

                <Columns className='is-mobile' key={'' + thisMss.name + index} >
                    <Columns.Column size={4}>
                        <div className="label type55  marginTop10 is-capitalized">{ thisMss.name} :</div>
                    </Columns.Column>
                    <Columns.Column>

                        <div className="control ">
                            <div className='select type65 is-fullwidth'>
                                <select name='user' className='select ' defaultValue={thisValue ?? ""} onChange={ (e) => { styleValChanged( e, thisMss) }}>
                                    {
                                        thisMss.options.map((thisOpt, index) => {
                                            return <option key={index} value={ thisOpt} > { thisOpt} </option>
                                        })
                                    }
                                </select>
                            </div>
                        </div>

                    </Columns.Column>
                </Columns>
            )
        })

        return allFields


}





const createTables = () => {
    return (
        <LoadingAux isMain={true} isLoading={fetchingAllSS} loaderHeight={400}>
            {
                makeSelections()
            }
        </LoadingAux>
    )
}

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  

const camelToSentenceCase = (text) => {
    var result = text.replace(/([A-Z])/g, " $1");
    var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult
}



const form = () => {
    return (
        <div className='is-two-fifths  paddingHor20'>
            <br />
            {createTables()}
            <br />
            <Button disabled={saving} loading={saving} className='is-fullwidth is-black' onClick={saveSelections} > Save </Button>
            <br />
        </div>
    )
}


const modal = () => {

    createTables()

    return (
        <Modal show={true} closeOnBlur={true} closeOnEsc={true} showClose={true} onClose={() => setClosed()} >
            <Modal.Content className='is-padingless'>
                <Box className='is-radiusless is-padingless fadeIn has-background-light'>
                    <Heading size={6} className='has-text-centered'> Style Selections </Heading>

                    <ColumnAux isMiddle={true} size={10} className=' is-centered '>

                        <div className='field is-centered'>
                            {/* <label className="label type60 ">Email</label> */}
                            {/* <input name={position.name} defaultValue={position.name} placeholder={'Name of Position'} type='input' className='input  has-text-weight-semibold type65' onChange={e => { positionNameChanged(e) }} /> */}
                        </div>

                        {form()}
                    </ColumnAux>

                </Box>
            </Modal.Content>
        </Modal>

    )
}

return modal()

}

export default StylesModal