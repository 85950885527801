import React, { useState, useEffect } from 'react'
import { Box, Button, Modal, Dropdown } from 'react-bulma-components/dist';

import ColumnAux from './ColumnAux'
import { Customer, CustomerPic, Measurement, Order, ProductPic, Remark } from '../utilities/Classes'

import { renderToString } from 'react-dom/server'


import { Formik, Form, Field } from 'formik'

import cogoToast from 'cogo-toast';
import { CustomerCallResultType, MeasurementType, PrintSheetType, ProductCallResultType } from '../utilities/enums';
import { getPrintSheetHTML } from './PrintSheet';
import LoadingAux from './LoadingAux';
import { randomAlphabets } from '../utilities/utils';




const PrintOrder = (props) => {



    let [order, setOrder] = useState(props.order)
    let [customer, setCustomer] = useState(props.customer)
    let [products, setProducts] = useState(props.products ?? [])


    let [showClientInfo, setShowClientInfo] = useState(true)
    let [showOrderInfo, setShowOrderInfo] = useState(true)

    let [showProducts, setShowProducts] = useState(props.products ? props.products.map(p => { return p.objectId }) : [])

    let [fetchingAssets, setFetchingAssets] = useState(false)
    let [saving, setSaving] = useState(false)
    let [preview, setPreview] = useState(null)
    let [sheetHTML, setSheetHTML] = useState(null)
    let [shareInputMode, setShareInputMode] = useState(null)
    let [shareInProgress, setShareInProgress] = useState(false)
    let [shareStatus, setShareStatus] = useState(null)

    // const downloadLinkRef = useRef('downloadLinkRef')



    // {(newOrder === true) ? <OrderModal customer={customer} isNewOrder={true} orderAdded={newOrderAdded} orderUpdated={orderWasUpdated} closed={setNoSelected} /> : null}


    useEffect(() => {

        fetchAssets()
        // setTimeout(() => {

        //     console.log("ORDER IS")
        //     console.log(order)

        //     cogoToast.success("USE EFFECT")


        //     // getHtmlString()

        //     fetchAssets()

        // }, 5000)
    }, [])




    const getCustMeasures = (cust) => new Promise((resolve, reject) => {
        let measurements = []
        Measurement.getAll(MeasurementType.body, cust, null, (succ, msms, errMsg) => {
            if (succ) {
                // cogoToast.success("Fetched customer measurements")
                console.log("Customer Measurements are")
                console.log(msms)

                measurements = msms
                if (customer.conciergeMeasurements) {
                    measurements.unshift(customer.conciergeMeasurements)
                }
                // resolve(measurements)
                resolve({ resultType: CustomerCallResultType.measurements, results: measurements })

            } else {
                reject("Could not load customer measurments. " + errMsg)
            }
        })
    })


    const getCustomerPics = (cust) => new Promise((resolve, reject) => {


        CustomerPic.getAll(cust, (succ, pics, errMsg) => {
            if (succ) {
                // cogoToast.success("Fetched customer pics")
                console.log("Customer Pics are")
                console.log(pics)
                // resolve(pics)
                resolve({ resultType: CustomerCallResultType.pics, results: pics })

            } else {
                // cogoToast.error("Could not load customer measurments")
                console.log("Could not load customer pics. " + errMsg)
                reject("Could not load customer pics. " + errMsg)
            }
        })
    })


    const getProductMeasurements = (prod) => new Promise((resolve, reject) => {
        // get Product Measuremenst 
        Measurement.getAll(MeasurementType.product, null, prod, (succ, msms, errMsg) => {
            if (succ) {
                console.log("One Product Measurements are")
                console.log(msms)
                // resolve(msms)
                resolve({ productId: prod.objectId, resultType: ProductCallResultType.measurements, results: msms })

            } else {
                console.log("Could not one product measurements. " + errMsg)
                reject("Could not load one product measurements. " + errMsg)
            }
        })
    })


    const getProductPics = (prod) => new Promise((resolve, reject) => {
        // get Product Pics
        ProductPic.getAll(prod, (succ, pics, errMsg) => {
            if (succ) {
                console.log("One Product Pics are")
                console.log(pics)
                // resolve(pics)
                resolve({ productId: prod.objectId, resultType: ProductCallResultType.pics, results: pics })

            } else {
                console.log("Could not one product pics. " + errMsg)
                reject("Could not load one product pics. " + errMsg)
            }
        })
    })


    const getProductRemarks = (prod) => new Promise((resolve, reject) => {
        // get Product Remarks
        Remark.getAll(prod, order, false, (succ, rmrks, errMsg) => {
            if (succ) {
                console.log("One Product Remarks are")
                console.log(rmrks)
                // resolve(rmrks)
                // let thisProd = Product.copyFrom(prod)            
                // thisProd.measurements
                resolve({ productId: prod.objectId, resultType: ProductCallResultType.remarks, results: rmrks })
            } else {
                console.log("Could not one product remarks. " + errMsg)
                reject("Could not load one product remarks. " + errMsg)
            }
        })
    })


    const getSingleProductAssets = async (prod) => {
        const prodAssets = await Promise.all(
            getProductMeasurements(prod),
            getProductPics(prod),
            getProductRemarks(prod)
        )
            .then(responses => {
                return responses
            }).catch(error => {
                console.log("Error in One Product promise all = ", error)
            })

        return prodAssets
    }


    const allProdAssets = (products) => {
        let allCalls = products.map(prod => {
            return [
                getProductMeasurements(prod),
                getProductPics(prod),
                getProductRemarks(prod)
            ]
        })

        return [...allCalls]
    }


    let beforeSheetHTML = `<!DOCTYPE html><html lang="en" style="box-sizing: border-box;margin: 0;padding: 0;background-color: #fff;font-size: 16px;-moz-osx-font-smoothing: grayscale;-webkit-font-smoothing: antialiased;min-width: 300px;overflow-x: hidden;overflow-y: scroll;text-rendering: optimizeLegibility;-webkit-text-size-adjust: 100%;-moz-text-size-adjust: 100%;-ms-text-size-adjust: 100%;text-size-adjust: 100%;font-family: -apple-system, BlinkMacSystemFont, &quot;Helvetica Neue&quot;, sans-serif;line-height: 1.5;height: 100%;"><head style="box-sizing: inherit;"> <meta charset="UTF-8" style="box-sizing: inherit;"> <title style="box-sizing: inherit;">Print Sheet</title> <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/bulma/0.9.1/css/bulma.min.css" style="box-sizing: inherit;"> <!-- <link rel="stylesheet" href="./style.css"> --> <link rel="stylesheet" href="https://files.ideamassive.com/htmltopdf/style.css" style="box-sizing: inherit;"></head><body style="box-sizing: inherit;margin: 0;padding: 0;font-family: -apple-system, BlinkMacSystemFont, &quot;Helvetica Neue&quot;, sans-serif;color: #4a4a4a;font-size: 16px;font-weight: 400;line-height: 1.5;height: 100%;"> <div class="container is-max-desktop" style="box-sizing: inherit;flex-grow: 1;margin: 0 auto;position: relative;width: auto;">`
    let afterSheetHTML = `</div></body></html>`

    let fetchAssets = async () => {

        if (!customer) {
            console.log("No Customer to find assets of")
            cogoToast.warn("No Customer to find assets of")
            return
        }

        setFetchingAssets(true)


        const custAssets = await Promise.all([
            getCustomerPics(customer),
            getCustMeasures(customer)
        ]).then((responses) => {
            // cogoToast.success("PROMISE Customer Results Success")
            console.log("Results of Customer Assets are ")
            console.log(responses);
            return responses
        }).catch((error) => {
            // if there's an error, log it
            console.log(error);
        });

        let thisCust = Customer.copyFrom(customer)
        custAssets.map(thisAsset => {
            switch (thisAsset.resultType) {
                case CustomerCallResultType.measurements:
                    thisCust.measurements = thisAsset.results; break;
                case CustomerCallResultType.pics:
                    thisCust.pics = thisAsset.results; break;
                default: break;
            }
        })

        console.log("Final Customer after adding assets")
        console.log(thisCust)

        setCustomer(thisCust)


        // cogoToast.success("We got Cust Assets")
        console.log("We got Cust Assets")
        console.log(custAssets)

        // console.log("Sent products from view are")
        // console.log(products)

        const prodAssets = await Promise.all(
            [].concat.apply([], allProdAssets(products))
        ).then((prodResponses) => {
            // cogoToast.success("PROMISE PRODUCTS Results Success")
            console.log("Results of Products Assets are ")
            console.log(prodResponses);
            return prodResponses
        }).catch((error) => {
            console.log(error);
        });


        // cogoToast.success("We got Products Assets")
        console.log("We got Products Assets")
        console.log(prodAssets);


        let prodsCopy = [...products]
        prodsCopy.map(thisProd => {
            prodAssets.filter(thisAsset => {
                return thisAsset.productId === thisProd.objectId
            }).map(thisAsset => {
                switch (thisAsset.resultType) {
                    case ProductCallResultType.measurements:
                        thisProd.measurements = thisAsset.results; break;
                    case ProductCallResultType.pics:
                        thisProd.pics = thisAsset.results; break;
                    case ProductCallResultType.remarks:
                        thisProd.remarks = thisAsset.results; break;
                    default: break;
                }
                return thisProd
            })
        })

        // console.log("Final Products after change are")
        // console.log(prodsCopy)

        // setProducts(prodsCopy)


        // console.log("_______________________THE CUSTOMER DETAILS WE ARE SENDING IS ")
        // console.log(customer)


        // renderPreviewNow(thisCust, prodsCopy)

        renderPreviewNow(thisCust, prodsCopy, order, props.sheetType ?? PrintSheetType.orderSheet, showOrderInfo, showClientInfo)



        // console.log([...prodAssets].map( res => {
        //     return res.result
        // }))
    }


    let renderPreviewNow = (finalCust, finalProds, finalOrder, finalSheetType, toShowOrderInfo, toShowClientInfo) => {
        // Get HTML FROM PrintSheet 
        // let thisPreview = getPrintSheetHTML(finalCust, finalOrder, finalProds.filter((pr) => { return showProducts.includes(pr.objectId) }), PrintSheetType.orderSheet, showOrderInfo, showClientInfo)

        let thisPreview = getPrintSheetHTML(finalCust, finalOrder, finalProds, finalSheetType, toShowOrderInfo, toShowClientInfo)

        setPreview(thisPreview)

        // let sheetStaticMarkup = renderToStaticMarkup(thisPreview)
        // console.log(" ")
        // console.log(" ")
        // console.log("The Sheet Static Markup is ")
        // console.log(sheetStaticMarkup)
        // console.log(" ")
        // console.log(" ")

        // console.log(" ")
        let sheetHTML = renderToString(thisPreview)
        // console.log("The Sheet HTML is ")
        // console.log(sheetHTML)
        // console.log(" ")
        // console.log(" ")

        let finalSheetHTML = beforeSheetHTML + sheetHTML + afterSheetHTML


        // Send to PrintOrder
        setSheetHTML(finalSheetHTML)
    }


    // let formWithModal = () => {

    //     return (
    //         <Modal show={true}  >
    //             <Modal.Content className='is-padingless '>
    //                 <Box className='is-radiusless is-padingless fadeIn '>
    //                     {/* <ColumnAux isMiddle={true} size={12} className=' is-centered '> */}
    //                     {form()}
    //                     {/* </ColumnAux> */}
    //                 </Box>
    //             </Modal.Content>
    //         </Modal>

    //     )
    // }

    // let getHtmlString = () => {

    //     let htmlString = ReactDOMServer.renderToStaticMarkup(form())

    //     if (htmlString) {

    //         console.log("HTML STRING IS")
    //         console.log(htmlString)

    //         // cogoToast.success('HTML EXPORTED')
    //     } else {
    //         cogoToast.warn("Could not convert to HTML")
    //     }
    //     //  ReactDOMServer.renderToStaticMarkup(
    //     //  )
    // }





    // const form = () => {



    //     return (
    //         <Box className=" has-background-light">

    //             <br />

    //             {/* Header */}
    //             <Columns className=" is-mobile">
    //                 <Columns.Column size={9} className="">
    //                     <Heading size={7} className=" type70 has-text-weight-bold  marginTop10"> ORDER DETAILS </Heading>
    //                 </Columns.Column>
    //                 <Columns.Column className="">
    //                     <img className=' ' src={clientMenuLogoUrl} alt="Tailorwise: Streamlined Bespoke Tailoring" width="160" />
    //                 </Columns.Column>
    //             </Columns>
    //             <hr className="solid heavyHr topMarginMinus20" />
    //             <Heading subtitle className=" type50 topMarginMinus20 has-text-weight-semibold has-text-grey has-text-right"> 17.Jan.2021 </Heading>

    //             <div className="">
    //                 <Heading size={5} className=" marginBottom0"> #201224ZHO </Heading>
    //                 {/* <Heading size={5} className=" marginBottom0"> PRODUCT DETIALS </Heading> */}

    //                 <Heading size={7} className=" has-text-grey topMarginMinus20"> (POSNo-112212) • 2 Product </Heading>

    //                 <Heading subtitle size={7} className=" has-text-grey"> Order Trial: 31-Jan-2021 &nbsp; · &nbsp; Order Delivery: 4-Feb-2021 </Heading>
    //             </div>

    //             <br />



    //             <Card className=' has-background-light marginTop10   '>
    //                 <Card.Header className=' pad10 is-shadowless'>
    //                     <Heading size={6} className="has-text-grey-light marginTop10 marginLeft10 "> Client: Elon Musk </Heading>
    //                 </Card.Header>
    //                 <Card.Content >
    //                     <Columns className=' type60'>
    //                         <Columns.Column className=''>
    //                             <p className="has-text-grey-light has-text-weight-semibold  marginBottom6"> HWS </p>
    //                             {/* <List className=" has-background-light topMarginMinus20 type60" >
    //                                 <List.Item> */}
    //                             <div className="  ">
    //                                 <p>Height · 175 cm</p>
    //                                 <p>Weight · 70 kg</p>
    //                                 <p>ShoeSize · 8 inch</p>
    //                             </div>

    //                             {/* </List.Item>
    //                             </List> */}
    //                         </Columns.Column>
    //                         <Columns.Column className=''>
    //                             <p className="has-text-grey-light  has-text-weight-semibold marginBottom6"> PATTERNS </p>
    //                             {/* <List className=" has-background-light topMarginMinus20 type60" >
    //                                 <List.Item> */}
    //                             <div className=" ">
    //                                 <p>Jacket · 164672</p>
    //                                 <p>Suit 2 Peace · 164672</p>
    //                             </div>

    //                             {/* </List.Item>
    //                             </List> */}
    //                         </Columns.Column>
    //                     </Columns>


    //                     <p className="has-text-grey-light type60 has-text-weight-semibold marginBottom6"> POSTURES </p>
    //                     <Tile kind="ancestor" className=' printPostureGrid ' >
    //                         <Tile kind='parent' className=' '>
    //                             <Tile className='' >
    //                                 <Box className=' printPostureCard pad0  ' >
    //                                     <Image rounded={false} className='  ' src={"https://picsum.photos/200/200"} />
    //                                 </Box>
    //                             </Tile>
    //                         </Tile>
    //                         <Tile kind='parent' className=''>
    //                             <Tile className='' >
    //                                 <Box className=' printPostureCard pad0  ' >
    //                                     <Image rounded={false} className='  ' src={"https://picsum.photos/200/200"} />
    //                                 </Box>
    //                             </Tile>
    //                         </Tile>
    //                         <Tile kind='parent' className=''>
    //                             <Tile className='' >
    //                                 <Box className=' printPostureCard pad0  ' >
    //                                     <Image rounded={false} className='  ' src={"https://picsum.photos/200/200"} />
    //                                 </Box>
    //                             </Tile>
    //                         </Tile>
    //                         <Tile kind='parent' className=''>
    //                             <Tile className='' >
    //                                 <Box className=' printPostureCard pad0  ' >
    //                                     <Image rounded={false} className='  ' src={"https://picsum.photos/200/200"} />
    //                                 </Box>
    //                             </Tile>
    //                         </Tile>
    //                         <Tile kind='parent' className=''>
    //                             <Tile className='' >
    //                                 <Box className=' printPostureCard pad0  ' >
    //                                     <Image rounded={false} className='  ' src={"https://picsum.photos/200/200"} />
    //                                 </Box>
    //                             </Tile>
    //                         </Tile>
    //                     </Tile>

    //                     <br />

    //                     <p className="has-text-grey-light type60 has-text-weight-semibold marginBottom8"> MEASUREMENTS </p>
    //                     <Tile kind="ancestor" className=' printMeasurementGrid ' >

    //                         <Tile kind='parent' className=' '>
    //                             <Tile className='' >
    //                                 <div className=' printMeasuremntCard pad0 has-background-light ' >
    //                                     <List className=" has-background-light  type50 has-text-weight-semibold " >
    //                                         <p className=" pad10  type50     "> MEASUREMENT NAME </p>

    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Height</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  175 cm </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Weight</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  70 kg </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> ShoeSize</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>

    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Height</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  175 cm </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Weight</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  70 kg </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> ShoeSize</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>

    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Height</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  175 cm </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Weight</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  70 kg </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> ShoeSize</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>

    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Height</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  175 cm </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Weight</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  70 kg </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> ShoeSize</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>

    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Height</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  175 cm </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Weight</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  70 kg </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> ShoeSize</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>

    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Height</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  175 cm </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Weight</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  70 kg </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> ShoeSize</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                     </List>
    //                                 </div>
    //                             </Tile>
    //                         </Tile>


    //                         <Tile kind='parent' className=' '>
    //                             <Tile className='' >
    //                                 <div className=' printMeasuremntCard pad0 has-background-light ' >
    //                                     <List className=" has-background-light  type50 has-text-weight-semibold " >
    //                                         <p className=" pad10  type50     "> MEASUREMENT NAME </p>

    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Height</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  175 cm </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Weight</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  70 kg </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> ShoeSize</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>

    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Height</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  175 cm </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>

    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Height</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  175 cm </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Weight</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  70 kg </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> ShoeSize</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>

    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Height</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  175 cm </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Weight</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  70 kg </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> ShoeSize</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>



    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Height</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  175 cm </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Weight</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  70 kg </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> ShoeSize</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                     </List>
    //                                 </div>
    //                             </Tile>
    //                         </Tile>

    //                         <Tile kind='parent' className=' '>
    //                             <Tile className='' >
    //                                 <div className=' printMeasuremntCard pad0 has-background-light ' >
    //                                     <List className=" has-background-light  type50 has-text-weight-semibold " >
    //                                         <p className=" pad10  type50     "> MEASUREMENT NAME </p>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Height sjsbkjbs dbdjbdkd djdbdkjbd dkjbdkjd</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  175 cm </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Weight</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  70 kg </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> ShoeSize</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>

    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Height</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  175 cm </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Weight</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  70 kg </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> ShoeSize</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>

    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Height</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  175 cm </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Weight</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  70 kg </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> ShoeSize</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>

    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Height</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  175 cm </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Weight</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  70 kg </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> ShoeSize</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>

    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Height</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  175 cm </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Weight</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  70 kg </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> ShoeSize</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>

    //                                     </List>
    //                                 </div>
    //                             </Tile>
    //                         </Tile>


    //                     </Tile>


    //                     <br />

    //                     <p className="has-text-grey-light type60 has-text-weight-semibold marginBottom6"> POSTURE IMAGES </p>
    //                     <Tile kind="ancestor" className=' printBodyImageGrid ' >
    //                         <Tile kind='parent' className=' '>
    //                             <Tile className='' >
    //                                 <Box className=' printBodyImageCard pad0  ' >
    //                                     <Image rounded={false} className='  ' src={"https://picsum.photos/160/200"} />
    //                                 </Box>
    //                             </Tile>
    //                         </Tile>
    //                         <Tile kind='parent' className=''>
    //                             <Tile className='' >
    //                                 <Box className=' printBodyImageCard pad0  ' >
    //                                     <Image rounded={false} className='  ' src={"https://picsum.photos/160/200"} />
    //                                 </Box>
    //                             </Tile>
    //                         </Tile>
    //                         <Tile kind='parent' className=''>
    //                             <Tile className='' >
    //                                 <Box className=' printBodyImageCard pad0  ' >
    //                                     <Image rounded={false} className='  ' src={"https://picsum.photos/160/200"} />
    //                                 </Box>
    //                             </Tile>
    //                         </Tile>
    //                         <Tile kind='parent' className=''>
    //                             <Tile className='' >
    //                                 <Box className=' printBodyImageCard pad0  ' >
    //                                     <Image rounded={false} className='  ' src={"https://picsum.photos/160/200"} />
    //                                 </Box>
    //                             </Tile>
    //                         </Tile>
    //                         <Tile kind='parent' className=''>
    //                             <Tile className='' >
    //                                 <Box className=' printBodyImageCard pad0  ' >
    //                                     <Image rounded={false} className='  ' src={"https://picsum.photos/160/200"} />
    //                                 </Box>
    //                             </Tile>
    //                         </Tile>
    //                     </Tile>



    //                 </Card.Content>
    //                 <Card.Footer >
    //                     {/* Footer */}
    //                 </Card.Footer>
    //             </Card>

    //             <br />
    //             <Heading size={6} className="marginLeft10 marginTop20 has-text-grey-lighter has-text-centered"> PRODUCTS </Heading>

    //             <Columns className="marginTop20">
    //                 <Columns.Column size={4}>
    //                 </Columns.Column>
    //                 <Columns.Column size={4}>
    //                     <hr className="hrLight topMarginMinus20" />
    //                 </Columns.Column>
    //                 <Columns.Column size={4}>
    //                 </Columns.Column>
    //             </Columns>


    //             <Card className='    '>
    //                 <Card.Header className=' pad10 is-shadowless'>
    //                     <Heading size={7} className="has-text-grey-light marginTop10 marginLeft10 "> PRODUCT ID · 201224ZHO-PDXK  </Heading>
    //                 </Card.Header>
    //                 <Card.Content className=" " >

    //                     <div className=" marginTopMinus30">
    //                         <Heading size={5} className="  marginBottom0"> Bomber Jacket </Heading>
    //                         <Heading subtitle className=" type50 has-text-grey"> StyleNo: 26762BHJ &nbsp; · &nbsp; Trial: 31-Jan-2021 &nbsp; · &nbsp; Delivery: 4-Feb-2021 </Heading>
    //                     </div>


    //                     <div className="has-text-weight-semibold marginTop30 type60">
    //                         <p className="has-text-grey-light "> MATERIALS </p>
    //                         <p className=" "> GFYFYF, YVYFYFYFY </p>
    //                     </div>



    //                     <Columns className=' type60 marginTop20'>
    //                         <Columns.Column size={7}>
    //                             <p className="has-text-grey-light has-text-weight-semibold  marginBottom6"> SELECTIONS </p>
    //                             {/* <List className=" has-background-light topMarginMinus20 type60" >
    //                                 <List.Item> */}
    //                             <div className="  ">
    //                                 <List className="   type50 has-text-weight-semibold " >
    //                                     <List.Item>
    //                                         <p className="marginBottom6">• FIT= REGULAR</p>
    //                                         <p className="marginBottom6">• MONOGRAM = YES (SEE REMARKS FOR DETAILS ) (MYNAME)</p>
    //                                         <p className="marginBottom6">• MONOGRAM = YES (SEE REMARKS FOR DETAILS ) (MYNAME)</p>
    //                                         <p className="marginBottom6">• FIT= REGULAR</p>
    //                                         <p className="marginBottom6">• MONOGRAM = YES (SEE REMARKS FOR DETAILS ) (MYNAME)</p>
    //                                         <p className="marginBottom6">• MONOGRAM = YES (SEE REMARKS FOR DETAILS ) (MYNAME)</p>
    //                                         <p className="marginBottom6">• FIT= REGULAR</p>
    //                                         <p className="marginBottom6">• MONOGRAM = YES (SEE REMARKS FOR DETAILS ) (MYNAME)</p>
    //                                         <p className="marginBottom6">• MONOGRAM = YES (SEE REMARKS FOR DETAILS ) (MYNAME)</p>
    //                                         <p className="marginBottom6">• FIT= REGULAR</p>
    //                                         <p className="marginBottom6">• MONOGRAM = YES (SEE REMARKS FOR DETAILS ) (MYNAME)</p>
    //                                         <p className="marginBottom6">• MONOGRAM = YES (SEE REMARKS FOR DETAILS ) (MYNAME)</p>
    //                                         <p className="marginBottom6">• FIT= REGULAR</p>
    //                                         <p className="marginBottom6">• MONOGRAM = YES (SEE REMARKS FOR DETAILS ) (MYNAME)</p>
    //                                         <p className="marginBottom6">• MONOGRAM = YES (SEE REMARKS FOR DETAILS ) (MYNAME)</p>
    //                                     </List.Item>
    //                                 </List>

    //                             </div>

    //                             {/* </List.Item>
    //                             </List> */}
    //                         </Columns.Column>
    //                         <Columns.Column className=''>
    //                             <p className="has-text-grey-light  has-text-weight-semibold marginBottom6"> REMARKS </p>
    //                             {/* <List className=" has-background-light topMarginMinus20 type60" >
    //                                 <List.Item> */}
    //                             <div className="type50">
    //                                 <p className="marginBottom6">• REM 2. by STAN</p>
    //                                 <p className="marginBottom6">• REM 2. by STAN</p>
    //                             </div>

    //                             {/* </List.Item>
    //                             </List> */}
    //                         </Columns.Column>
    //                     </Columns>


    //                     <br />

    //                     <p className="has-text-grey-light type60 has-text-weight-semibold marginBottom8"> MEASUREMENTS </p>
    //                     <Tile kind="ancestor" className=' printMeasurementGrid ' >

    //                         <Tile kind='parent' className=' '>
    //                             <Tile className='' >
    //                                 <div className=' printMeasuremntCard pad0  ' >
    //                                     <List className=" has-background-white-bis  type50 has-text-weight-semibold " >
    //                                         <p className=" pad10  type50     "> MEASUREMENT NAME </p>

    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Height</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  175 cm </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Weight</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  70 kg </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> ShoeSize</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>

    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Height</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  175 cm </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Weight</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  70 kg </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> ShoeSize</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>

    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Height</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  175 cm </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Weight</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  70 kg </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> ShoeSize</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>

    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Height</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  175 cm </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Weight</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  70 kg </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> ShoeSize</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>

    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Height</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  175 cm </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Weight</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  70 kg </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> ShoeSize</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>

    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Height</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  175 cm </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Weight</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  70 kg </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> ShoeSize</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                     </List>
    //                                 </div>
    //                             </Tile>
    //                         </Tile>


    //                         <Tile kind='parent' className=' '>
    //                             <Tile className='' >
    //                                 <div className=' printMeasuremntCard pad0  ' >
    //                                     <List className=" has-background-white-bis  type50 has-text-weight-semibold " >
    //                                         <p className=" pad10  type50     "> MEASUREMENT NAME </p>

    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Height</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  175 cm </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Weight</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  70 kg </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> ShoeSize</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>

    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Height</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  175 cm </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>

    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Height</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  175 cm </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Weight</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  70 kg </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> ShoeSize</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>

    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Height</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  175 cm </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Weight</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  70 kg </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> ShoeSize</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>



    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Height</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  175 cm </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Weight</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  70 kg </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> ShoeSize</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                     </List>
    //                                 </div>
    //                             </Tile>
    //                         </Tile>

    //                         <Tile kind='parent' className=' '>
    //                             <Tile className='' >
    //                                 <div className=' printMeasuremntCard pad0  ' >
    //                                     <List className="  has-background-white-bis type50 has-text-weight-semibold " >
    //                                         <p className=" pad10  type50     "> MEASUREMENT NAME </p>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Height sjsbkjbs dbdjbdkd djdbdkjbd dkjbdkjd</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  175 cm </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Weight</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  70 kg </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> ShoeSize</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>

    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Height</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  175 cm </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Weight</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  70 kg </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> ShoeSize</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>

    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Height</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  175 cm </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Weight</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  70 kg </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> ShoeSize</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>

    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Height</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  175 cm </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Weight</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  70 kg </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> ShoeSize</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>

    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Height</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  175 cm </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> Weight</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  70 kg </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>
    //                                         <List.Item>
    //                                             <Columns className="is-mobile">
    //                                                 <Columns.Column size={8}> ShoeSize</Columns.Column>
    //                                                 <Columns.Column className="has-text-right">  8 Inchs </Columns.Column>
    //                                             </Columns>
    //                                         </List.Item>

    //                                     </List>
    //                                 </div>
    //                             </Tile>
    //                         </Tile>


    //                     </Tile>


    //                     <br />

    //                     <p className="has-text-grey-light type60 has-text-weight-semibold marginBottom6"> IMAGES </p>
    //                     <Tile kind="ancestor" className=' printBodyImageGrid ' >
    //                         <Tile kind='parent' className=' '>
    //                             <Tile className='' >
    //                                 <Box className=' printBodyImageCard pad0  ' >
    //                                     <Image rounded={false} className='  ' src={"https://picsum.photos/160/200"} />
    //                                 </Box>
    //                             </Tile>
    //                         </Tile>
    //                         <Tile kind='parent' className=''>
    //                             <Tile className='' >
    //                                 <Box className=' printBodyImageCard pad0  ' >
    //                                     <Image rounded={false} className='  ' src={"https://picsum.photos/160/200"} />
    //                                 </Box>
    //                             </Tile>
    //                         </Tile>
    //                         <Tile kind='parent' className=''>
    //                             <Tile className='' >
    //                                 <Box className=' printBodyImageCard pad0  ' >
    //                                     <Image rounded={false} className='  ' src={"https://picsum.photos/160/200"} />
    //                                 </Box>
    //                             </Tile>
    //                         </Tile>
    //                         <Tile kind='parent' className=''>
    //                             <Tile className='' >
    //                                 <Box className=' printBodyImageCard pad0  ' >
    //                                     <Image rounded={false} className='  ' src={"https://picsum.photos/160/200"} />
    //                                 </Box>
    //                             </Tile>
    //                         </Tile>
    //                         <Tile kind='parent' className=''>
    //                             <Tile className='' >
    //                                 <Box className=' printBodyImageCard pad0  ' >
    //                                     <Image rounded={false} className='  ' src={"https://picsum.photos/160/200"} />
    //                                 </Box>
    //                             </Tile>
    //                         </Tile>
    //                     </Tile>



    //                 </Card.Content>
    //             </Card>






    //         </Box>
    //     )
    // }

    const getPdfViaMail = (html) => {

        console.log("CALLING FETCH FOR PDF S3URL")

        fetch("https://l3ji481vf2.execute-api.ap-south-1.amazonaws.com/prod", {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: {
                htmlString: html,
                preferredOutPut: "s3url"
            }
        }).then((response) => {
            // cogoToast.success("Got PDF LINK BABY")
            console.log("PDF URL FETCH RESPONSE IS")
            console.log(response)

        }).catch((error) => {
            console.log("Error while trying to post fetch pdf")
            console.log(error)
        })

    }

    const dropDownChoiceMade = (choice) => {
        if (choice === 'cancelOrder') {
        }
        if (choice === 'mailOrder') {
            // Order.sendOrderHTMLMail("tempObjId", "112233", "This Order Footer",  "rokinife@gmail.com", "Order Details", "Order Sheet")

            // sendCustomHTMLMail
            // getPdfViaMail(sheetHTML)
            Order.sendCustomHTMLMail(sheetHTML, "tempObjId", "112233", "This Order Footer", "rokinife@gmail.com", "Order Details", "Order Sheet")

            // alert("Order Mail Sent")
        }
    }

    const closeModals = () => {
        props.closed()
    }


    const closeSubModals = () => {
        setShareInputMode(null)
    }

    const showShareInput = (mode) => {

        if (shareInProgress) {
            return
        }

        // cogoToast.warn("Domain Verification is pending for branded email.")
        // return
        setShareInputMode(mode)
    }


    const download_file = (fileURL, fileName) => {
        // for non-IE
        if (!window.ActiveXObject) {
            var save = document.createElement('a');
            save.href = fileURL;
            save.target = '_blank';
            var filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
            save.download = fileName || filename;
            if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
                document.location = save.href;
                // window event not working here
            } else {
                var evt = new MouseEvent('click', {
                    'view': window,
                    'bubbles': true,
                    'cancelable': false
                });
                save.dispatchEvent(evt);
                (window.URL || window.webkitURL).revokeObjectURL(save.href);
            }
        }

        // for IE < 11
        else if (!!window.ActiveXObject && document.execCommand) {
            var _window = window.open(fileURL, '_blank');
            _window.document.close();
            _window.document.execCommand('SaveAs', true, fileName || fileURL)
            _window.close();
        }
    }



    const downloadPressed = () => {
        if (shareInProgress) {
            return
        }

        // Download
        setShareInProgress(true)

        let randomname = randomAlphabets("14")

        // download("hello world", "dlText.txt", "text/plain");



        // download(new Blob([sheetHTML]), `${randomname}`, "application/pdf");
        // setShareInProgress(false)


        Order.downloadOrderSheet(sheetHTML, (succ, result, errMsg) => {
            setShareInProgress(false)
            if (succ) {
                console.log("Recieved Result in DOWNLOAD Method");
                console.log(result);


                let fileUrl = result.body.result
                let fileName = result.body.fileName

                // download_file(result.body.s3Url, result.body.fileName)


                // download(result.body.s3Url); 

                // let blob = new Blob([result.body.data]);

                // download(blob, "mySample.pdf", "application/pdf");

                // const filename = result.headers.get('Content-Disposition').split('filename=')[1];
                // // result.blob().then(blob => {
                // //     let url = window.URL.createObjectURL(blob);
                // //     let a = document.createElement('a');
                // //     a.href = url;
                // //     a.download = filename;
                // //     a.click();
                // // });

                //     let thisBlob = new Blob([blob], { type: 'application/pdf' })

                //     const url = window.URL.createObjectURL(thisBlob);

                download_file(fileUrl, fileName)


                // if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {


                // }else{
                //     downloadLinkRef.current.href = fileUrl;
                //     downloadLinkRef.current.download = fileName;
                //     downloadLinkRef.current.click()
                // }






                // const link = document.createElement('a');
                // link.href = fileUrl;
                // link.download = fileName;
                // link.target = "_blank"
                // // link.setAttribute('download', filename);
                // document.body.appendChild(link);
                // link.click();
                // link.parentNode.removeChild(link);

                // cogoToast.success(result.body.smsResult);  //downloadResult
                closeSubModals()
            } else {
                cogoToast.error("Some error occurred. Check and try again.")
                console.log(errMsg);
            }
        })
    }

    // const emailIdChanged = (e) => {

    //     let val = (e.target.value);
    //     let isValid = validateEmail(val)
    //     if (isValid.valid) {
    //         setShareEmailId(e.target.value)
    //     } else {

    //     }

    // }

    // const smsPhoneChanged = (e) => {
    //     setShareSMSphone(e.target.value)
    // }

    const startShare = (email, phone) => {

        let thisEmail = email
        let thisPhone = phone

        if (shareInputMode === "phone" && thisPhone) {
            shareNow(shareInputMode, thisPhone)
        } else if (thisEmail) {
            shareNow(shareInputMode, thisEmail)
        }
    }


    const shareNow = (mode, input) => {

        let prodsToShow = products.filter((pr) => { return showProducts.includes(pr.objectId) })
        let prodId = ""
        let prodType = ""

        let subject = ""

        if (sheetType === PrintSheetType.productSheet) {
            if (prodsToShow) {
                if (prodsToShow.length) {
                    let thisProd = prodsToShow[0]
                    prodId = thisProd.productId

                    subject = prodId + " Product Details"
                    prodType = thisProd.type

                }
            }

        } else {
            subject = (order.orderNo ?? " ") + " Order Details"
        }

        if (mode === "phone") {
            if (input.length > 9) {
                // SMS
                setShareInProgress(true)
                Order.sendOrderSheet(sheetHTML, order ? order.orderNo : "", prodId, prodType, false, true, "", "", "", "", input, sheetType, prodsToShow, (succ, result, errMsg) => {
                    // setShareInProgress(false)
                    if (succ) {
                        console.log("Recieved Result in Phone Method");
                        console.log(result);
                        cogoToast.success("SMS Initiation. Confirmed.");  //smsResult
                        // closeSubModals()
                    } else {
                        cogoToast.error("Some error occurred. Check and try again.")
                        console.log(errMsg);
                    }
                })

                setTimeout(() => {
                    showShareInitatedAndClose("SMS", true, true);
                }, 3000)

                // Order.sendCustomHTMLMail(sheetHTML, "tempObjId", "112233", "This Order Footer", "rokinife@gmail.com", "Order Details", "Order Sheet")
            }
        } else {
            if (input.length > 5) {
                // Email
                setShareInProgress(true)
                Order.sendOrderSheet(sheetHTML, order ? order.orderNo : "", prodId, prodType, true, false, input, subject, "Order Details", "", "", sheetType, prodsToShow, (succ, result, errMsg) => {
                    // setShareInProgress(false)
                    if (succ) {
                        console.log("Recieved Result in Email Method");
                        console.log(result);
                        cogoToast.success("Email Initiation. Confirmed.");  //emailResult
                        // closeSubModals()
                    } else {
                        cogoToast.error("Some error occurred. Check and try again.")
                        console.log(errMsg);
                    }
                })
                
                setTimeout(() => {
                    showShareInitatedAndClose("Email", true, true);
                }, 3000)

                // Order.sendCustomHTMLMail(sheetHTML, "tempObjId", "112233", "This Order Footer",  "rokinife@gmail.com", "Order Details", "Order Sheet")
            }
        }
    }

    const showShareInitatedAndClose = (mode, toClose, toStopShareInProgress, hideInput) => {
        cogoToast.info(mode + " Initiated")
        if (toClose) { closeSubModals()}
        if (toStopShareInProgress) { setShareInProgress(false)}
        if (hideInput) {  }
    }

    const validateEmail = (value) => {
        let error;
        if (!value) {
            error = 'Required';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            error = 'Invalid email address';
        }
        return error;
    }

    const validatePhone = (value) => {
        const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
        let error;
        if (!value) {
            error = 'Required';
        } else if (phoneRegExp.test(value)) {
            error = 'Invalid Phone No';
        }
        return error;
    }

    let formValues = {
        email: "",
        phone: ""
    }

    const formDiv = (props) => {

        return (
            <div >
                <Formik
                    initialValues={formValues}


                    onSubmit={(data, { setSubmitting, resetForm }) => {
                        setSubmitting(true)
                        startShare(data.email, data.phone)

                    }}
                >
                    {({ values, isSubmitting, errors, touched, isValidating }) => (

                        <Form >

                            {/* <div className='field'>
                                {errors.username && touched.username && <div className="help is-pulled-right is-danger">{errors.username}</div>}
                                <label className="label is-size-7 marginTop10">Username</label>
                                <Field name='username' type='input' className='input ' validate={validateUsername} />
                            </div>
                            <Button disabled={isSubmitting || isValidating} loading={isSubmitting} className=' marginTop20 is-radiusless  is-fullwidth is-centerd  is-black ' type='submit' > Sign In &nbsp; </Button> */}

                            {
                                shareInputMode === "phone" ?
                                    <div className='pad20'>
                                        {/* <Heading size={5}> Phone  (with +CountryCode) </Heading> */}
                                        <div className='field '>
                                            <label className="label ">Phone  (with +CountryCode)</label>
                                            {errors.phone && touched.phone && <div className="help is-pulled-right is-danger">{errors.phone}</div>}
                                            <Field name='phone' type='phone' validate={validatePhone} className='input field has-text-weight-semibold is-size-5' placeholder='Enter full phone no. without spaces' />
                                        </div>
                                        <Button loading={shareInProgress} disabled={shareInProgress || shareStatus || isValidating || isSubmitting} className='is-light marginTop10' type='submit'  > Send </Button>
                                    </div>
                                    :
                                    <div className='pad20'>
                                        {/* <Heading size={5}> Email Id </Heading> */}
                                        <div className='field '>
                                            <label className="label  ">Email</label>
                                            {errors.email && touched.email && <div className="help is-pulled-right is-danger">{errors.email}</div>}
                                            <Field name='email' type='email' validate={validateEmail} className='input field has-text-weight-semibold is-size-5' placeholder='Enter email Id' />
                                        </div>
                                        <Button loading={shareInProgress || isSubmitting} disabled={shareInProgress || isSubmitting || shareStatus || isValidating} className='is-light marginTop10' type='submit'  > Send </Button>
                                    </div>
                            }



                        </Form>

                    )}

                </Formik>
            </div>
        )
    }



    const inputModal = () => {
        return (
            <Modal show={true} closeOnBlur={true} closeOnEsc={true} showClose={true} onClose={() => closeSubModals()} >
                <Modal.Content className='is-padingless '>
                    <br />
                    <Box className='is-radiusless is-padingless  '>

                        <ColumnAux isMiddle={true} size={8} className=' is-centered '>
                            {formDiv()}

                        </ColumnAux>

                    </Box>
                    <br />

                </Modal.Content>
            </Modal>
        )
    }



    // const inputModalOld = () => {
    //     return (
    //         <Modal show={true} closeOnBlur={true} closeOnEsc={true} showClose={true} onClose={() => closeSubModals()} >
    //             <Modal.Content className='is-padingless '>
    //                 <br />
    //                 <Box className='is-radiusless is-padingless  '>

    //                     <ColumnAux isMiddle={true} size={8} className=' is-centered '>

    //                         {
    //                             shareInputMode === "phone" ?
    //                                 <div className='pad20'>
    //                                     {/* <Heading size={5}> Phone  (with +CountryCode) </Heading> */}
    //                                     <div className='field '>
    //                                         <label className="label ">Phone  (with +CountryCode)</label>
    //                                         <input name='phoneInput' defaultValue={shareSMSphone} type='phone' className='input field has-text-weight-semibold is-size-5' placeholder='Enter full phone no. without spaces' onChange={e => { smsPhoneChanged(e) }} />
    //                                     </div>
    //                                     <Button loading={shareInProgress} disabled={shareInProgress || shareStatus} className='is-light marginTop10' onClick={startShare} > Send </Button>
    //                                 </div>
    //                                 :
    //                                 <div className='pad20'>
    //                                     {/* <Heading size={5}> Email Id </Heading> */}
    //                                     <div className='field '>
    //                                         <label className="label  ">Email</label>
    //                                         <input name='emailIdInput' defaultValue={shareEmailId} type='email' className='input field has-text-weight-semibold is-size-5' placeholder='Enter email Id' onChange={e => { emailIdChanged(e) }} />
    //                                     </div>
    //                                     <Button loading={shareInProgress} disabled={shareInProgress || shareStatus} className='is-light marginTop10' onClick={startShare} > Send </Button>

    //                                 </div>
    //                         }

    //                     </ColumnAux>

    //                 </Box>
    //                 <br />

    //             </Modal.Content>
    //         </Modal>
    //     )
    // }


    const [sheetType, setSheetType] = useState(props.sheetType ?? PrintSheetType.orderSheet)

    const sheetTypeUpdated = (e) => {
        // cogoToast.info(`Sheet type is updated : ${e.target.value}`)
        setSheetType(e.target.value)
    }


    const changeOrderDetailsShow = (e) => {
        let val = e.target.checked
        setShowOrderInfo(val)

        let prodsToShow = products.filter((pr) => { return showProducts.includes(pr.objectId) })
        renderPreviewNow(customer, prodsToShow, order, props.sheetType ?? PrintSheetType.orderSheet, val, showClientInfo)

    }

    const changeClientShow = (e) => {
        let val = e.target.checked
        setShowClientInfo(val)

        let prodsToShow = products.filter((pr) => { return showProducts.includes(pr.objectId) })
        renderPreviewNow(customer, prodsToShow, order, props.sheetType ?? PrintSheetType.orderSheet, showOrderInfo, val)
    }

    const changeProductShow = (prObjectId, e) => {
        // let val = e.target.checked
        let showingProds = [...showProducts]
        let didInclude = false
        let filteredProds = showingProds.filter(thisProdObjId => {
            let result = thisProdObjId !== prObjectId
            if (!result) {
                didInclude = true
            }
            return result
        })

        if (didInclude) {
            setShowProducts(filteredProds)
        } else {
            filteredProds.push(prObjectId)
            setShowProducts(filteredProds)
        }


        let prodsToShow = products.filter((pr) => { return filteredProds.includes(pr.objectId) })
        renderPreviewNow(customer, prodsToShow, order, props.sheetType ?? PrintSheetType.orderSheet, showOrderInfo, showClientInfo)
    }


    const sheetSelections = () => {
        return (
            <div>
                <Dropdown className=" smallerTitle" label={"Configure Sheet"}>
                    <div className="minWidth240 ">
                        <Dropdown.Item value="divider">
                            <label className="checkbox type60 has-text-grey">
                                <input checked={showOrderInfo} type="checkbox" onChange={(e) => { changeOrderDetailsShow(e) }} />
                                &nbsp;Order Details
                                </label>
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item value="divider">
                            <label className="checkbox type60 has-text-grey">
                                <input checked={showClientInfo} type="checkbox" onChange={(e) => { changeClientShow(e) }} />
                                   &nbsp;Client Details
                                </label>
                        </Dropdown.Item>

                        {
                            products.map((pr) => {
                                return (
                                    <div key={pr.objectId} >
                                        <Dropdown.Divider />
                                        <Dropdown.Item value="divider marginTop10">
                                            <label className="checkbox type60 has-text-grey">
                                                <input checked={showProducts.includes(pr.objectId)} type="checkbox" onChange={(e) => { changeProductShow(pr.objectId, e) }} />
                                                &nbsp;{pr.type + ' (' + pr.productId + ')'}
                                            </label>
                                        </Dropdown.Item>
                                    </div>
                                )
                            })

                        }
                    </div>


                </Dropdown>
            </div>
        )
    }


    const modal = () => {
        return (
            <Modal show={true} closeOnBlur={true} closeOnEsc={true} showClose={true} onClose={() => closeModals()} >
                <Modal.Content className='is-padingless '>

                    {shareInputMode ? inputModal() : null}

                    <Box className='is-radiusless is-padingless fadeIn '>
                        {/* <Dropdown className=" is-small is-arrowless  is-marginless is-pulled-left has-background-white noDropdownIcon no-border is-shadowless" color="" label='•••' onChange={(val) => { dropDownChoiceMade(val) }} >
                            <Dropdown.Item value="cancelOrder">
                                Cancel Order
                        </Dropdown.Item>
                            <Dropdown.Item value="mailOrder">
                                Mail Order
                        </Dropdown.Item>
                        </Dropdown> */}


                        {preview ?
                            <div>

                                {
                                    shareInProgress ?
                                        <div>
                                            <Button disabled={shareInProgress} loading={shareInProgress} className="is-size-7  has-text-grey is-pulled-right no-decoration is-light is-small minWidth120" > </Button>
                                        </div>
                                        :

                                        <div>

                                            {/* <Button className="is-size-7 marginLeft8  has-text-grey is-pulled-right no-decoration is-light is-small" onClick={() => { showShareInput("phone") }} > SMS </Button> */}
                                            <Button disabled={shareInProgress} loading={shareInProgress} className="is-size-7  has-text-grey is-pulled-right no-decoration is-light is-small" onClick={() => { showShareInput("email") }} > Email </Button>

                                            {shareInProgress ? null : <Button disabled={shareInProgress} loading={shareInProgress} className="is-size-7  has-text-grey is-pulled-right no-decoration is-light is-small marginRight20" onClick={() => { downloadPressed() }} > Download </Button>}
                                            {/* <a href="https://tw-uploads.s3.eu-west-2.amazonaws.com/pdf/pdfviaapic1a0a440-2cd0-46ce-af31-02ce690f7ad7.pdf" download>Download PDF</a> */}

                                            {/* <br /> */}

                                            {sheetSelections()}


                                            {/* <a ref={downloadLinkRef} className="is-hidden" href="https://tw-uploads.s3.eu-west-2.amazonaws.com/pdf/pdfviaapic1a0a440-2cd0-46ce-af31-02ce690f7ad7.pdf" download="MyPdf" target='_blank'> </a> */}

                                            {/* <a ref={downloadLinkRef} onTouchStart="{this.onSubmit}" className="clickable" href="" download="MyPdf" target='_blank' > Download </a> */}

                                        </div>

                                }


                            </div>
                            :
                            null}




                        <br />



                        <ColumnAux isMiddle={true} size={12} className=' is-centered '>

                            {preview ?? <LoadingAux isMain={true} isLoading={true} loaderHeight={400}>  </LoadingAux>}
                        </ColumnAux>
                    </Box>
                </Modal.Content>
            </Modal>

        )
    }

    return modal()

}

export default PrintOrder







// const downloadResponse =  {

//     "statusCode": 200, 
//     "headers" : {
//         "Content-Type" : "application/pdf",
//         "Content-Disposition" : "attachment; filename={}".format("filename")
//     },
//     "body" : base64.b64encode(file_content),
//     "isBase64Encoded" : true

// }