import React from 'react'
import { screen } from '../../utilities/enums'
import Orders from '../../General/Orders'

const SendOrders = () => {



    return (
        <div>
            <Orders columnAuxSize={8} headerTitle='Orders that are made at factory and can be sent to Store.' screen={screen.SendOrders_Factory} />
        </div>
    ) 
}

export default SendOrders