import React from 'react'
import { Box, Columns, Button, Tag } from 'react-bulma-components/dist';
import { Position } from '../../utilities/UserClasses'
import mapPinMicro from '../../assets/mapPinMicro.svg'


const PositionCard = (props) => {


    const viewPressed = (position) => {
        props.selectPosition(position)
    }

    // constructor(id, type, name, description, createdOn, dueDate, amount, currency, paidFull, paymentDate, paymentId, fileURL, status, orderId, userId) {


    const posBox = (pos) => {

        let position = Position.copyFrom(pos)

        let isMainLoc = true
        let locString = ""
        let oa = position.forLocation

        // console.log(" BJKSBJS REGISTERED AT ")
        // console.log(oa)
        // console.log(" BJKSBJS CL IS ")
        // console.log(getCurrentLocation())


        if (oa) {
            // if (oa.objectId !== getCurrentLocation().objectId) {
                locString = oa.name
                isMainLoc = false
            // }
        }

        // console.log(" ISMAINLOC ")
        // console.log(isMainLoc)


        // let pos = new Position('', null, '')

        //  id, orderNo, productsCount, bookingD, trialD, deliveryD, status

        return (
            <Box key={position.objectId} className='hoverShine noBreakWords generalCardBG' onClick={() => { viewPressed(position) }} style={{cursor: 'pointer'}}>
                <Columns className=' is-size-7'>
                    <Columns.Column size={3} className=''>
                        <p className='has-text-grey-light has-text-weight-semibold  marginBottom5'> POSITION </p>
                        <p className='maxWrap165 has-text-weight-semibold'>{position.name} </p> 
                        {/* { order.addedByUser ?  <p className='type50'>via {order.addedByUser.name}  </p>  : null} */}
                    </Columns.Column>
                    <Columns.Column className=' '>
                        <p className='has-text-grey-light has-text-grey  marginBottom5'> Access </p>
                        <p color="maxWrap165 light" >{position.departmentAccessDescription()}</p>
                    </Columns.Column>
                    {/* <Columns.Column size={2} className=''>
                        <Button className='is-small is-fullwidth is-outlined' onClick={() => { viewPressed(position) }}> View </Button>
                    </Columns.Column> */}
                </Columns>
                {
                    (isMainLoc === false) ?
                        <p className="type45 has-text-grey marginTop10"><img src={mapPinMicro} width="6" />&nbsp;{locString}</p> :

                        null
                }
            </Box>
        )
    }

    
    return (
        // invoiceCardToShow(props.status)
        posBox(props.position)
    )
}

export default PositionCard