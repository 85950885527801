import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment'


const FormikDatePicker = ({ placeHolderText, value, onChange, onBlur, error, touched, label, name, className }) => {

    console.log('INCOMING DATE VALUE = ', value)
    // Fri Aug 14 2020 00:00:00 GMT+0530 (India Standard Time)
    

    return (
        <>
            <div className='datePicker has-text-weight-semibold type65 input'>
            <DatePicker
            name={name}
            className= {className}
            placeholderText={placeHolderText}
            selected={value} 
            showMonthDropdown
            showYearDropdown
            dropdownMode= "select"
            todayButton='Today'
            dateFormat="MMM/dd/yyyy"
            
            onChange={(value) => {
                onChange(name, value);
            }}
            onBlur={() => {
                onBlur(name, true);
            }}
            onFocus={e => e.target.blur()}

            />
            <label className='type40 has-text-grey is-uppercase is-aligned-right has-text-right'>{label}</label>

            {/* <FontAwesomeIcon className={styles.calendarIcon} icon='calendar-alt' size='xs' />
            {touched && error ? (<div>{error}</div>) : null} */}
            </div>                  
        </>
    )
}


export default FormikDatePicker;