import React, { useState, useEffect } from 'react'
import { Box, Button, Modal, Heading, Columns, Dropdown, Table } from 'react-bulma-components/dist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPrint } from '@fortawesome/free-solid-svg-icons';
import ColumnAux from '../../containers/ColumnAux'
import DatePicker from 'react-datepicker'
import { Order } from '../../utilities/Classes'
import { getLocations, getUsers, getCurrentUser, getCurrentLocation } from '../../utilities/Store';
import { newOrderNo } from '../../utilities/utils';

import cogoToast from 'cogo-toast';
import OrderCancelModal from './OrderCancelModal'
import MeasurementModal from '../../containers/MeasurementModal';
import { MeasurementType } from '../../utilities/enums';

const ConciergeOrderModal = (props) => {

    const setClosed = () => {
        props.closed()
        setSelectedMeasurement(null)
    }





    let [order, setOrder] = useState(props.order ?? null)
    let [saving, setSaving] = useState(false)
    let [selectedMeasurement, setSelectedMeasurement] = useState(null)


    // {(newOrder === true) ? <OrderModal customer={customer} isNewOrder={true} orderAdded={newOrderAdded} orderUpdated={orderWasUpdated} closed={setNoSelected} /> : null}



    const saveOrder = () => {
        setSaving(true)
        let ord = Order.copyFrom(order)
        // console.log('Copied Order =')
        // console.log(ord)

        ord.update((succ, err) => {
            if (succ) {
                // console.log('Order updated')
                // console.log(ord)
                cogoToast.success('Order Updated')
                props.orderUpdated(order)
            } else {
                cogoToast.error('Could not update Order. Check internet and try again.')
                // console.log(err)
            }
            setClosed()
        })
    }



    const displayNoteChanged = (e) => {
        let o = { ...order }
        o.conciergeDisplayNote = e.target.value
        setOrder(o)
    }

    const refundedAmountChanged = (e) => {

        if (Number(e.target.value).toFixed(2)) {
            let o = { ...order }
            o.refundedAmount = Number(e.target.value).toFixed(2)
            setOrder(o)
        } else {
            cogoToast.warn("Could not convert to number")
        }

    }

    const deliveryAddressChanged = (e) => {
        let o = { ...order }
        o.deliveryAddress = e.target.value
        setOrder(o)
    }

    const measurementsUpdated = (mmt) => {
        let o = { ...order }
        o.conciergeMeasurements = mmt

        // Save this order

        setOrder(o)
        saveOrder()
    }

    const measurementSelected = () => {
        setSelectedMeasurement(order.conciergeMeasurements)
    }



    const form = () => {

        return (

            <div className='is-two-fifths  paddingHor20'>

                <br />

                <Columns>
                    <Columns.Column size={3} className="backgroundMoreGreyLighter">
                        <label className="label type55  marginTop10 has-text-right">Display Note</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className='field marginHorizontal8'>
                            {/* <label className="label type60 ">Email</label> */}
                            <textarea name='displayNote' defaultValue={order.conciergeDisplayNote ?? ""} type='text' className='input textarea has-text-weight-semibold type65' placeholder='Display Note to customer' onChange={e => { displayNoteChanged(e) }} />
                        </div>
                    </Columns.Column>
                </Columns>

                <Columns>
                    <Columns.Column size={3} className="backgroundMoreGreyLighter">
                        <label className="label type55  marginTop10 has-text-right">Refunded Amount</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className='field '>
                            {/* <label className="label type60 ">Email</label> */}
                            <input name='refundedAmount' defaultValue={order.refundedAmount ? `${order.refundedAmount}` : ""} type='number' className='input field has-text-weight-semibold type65' placeholder='Show refunded amount in order' onChange={e => { refundedAmountChanged(e) }} />
                        </div>
                    </Columns.Column>
                </Columns>

                <Columns>
                    <Columns.Column size={3} className="backgroundMoreGreyLighter">
                        <label className="label type55  marginTop10 has-text-right">Delivery Address</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className='field marginHorizontal8'>
                            {/* <label className="label type60 ">Email</label> */}
                            <textarea name='devliveryAddress' defaultValue={order.deliveryAddress ?? ""} type='text' className='input textarea has-text-weight-semibold type65' placeholder='Delivery Address of order' onChange={e => { deliveryAddressChanged(e) }} />
                        </div>
                    </Columns.Column>
                </Columns>



                <Columns>
                    <Columns.Column size={3} className="backgroundMoreGreyLighter">
                        <label className="label type55  marginTop10 has-text-right">Order Measurement</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className=' marginHorizontal8'>
                            <div className=" measurementInfoContainer">

                                {
                                     order.conciergeMeasurements ?  (
                                         <div key={order.conciergeMeasurements.name} className="bodyInfoMeasurement">
                                            {
                                                <Box className='is-radiusless' onClick={ measurementSelected } >
                                                    <div className='type55 has-text-weight-semibold meginBottom10'> {order.conciergeMeasurements.name} </div>
                                                    <Table className='is-hoverable is-size-7'>
                                                        <tbody>
                                                            {
                                                                order.conciergeMeasurements.measures.map((ms, index) => {
                                                                    return <tr key={index}><td className='type50'>{ms.name}</td><td className='has-text-right type50'>{ms.value}&nbsp;{ms.unit}</td></tr>
                                                                })
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Box>
                                            }
                                        </div>
                                    ) : 
                                    null
                                }
                            </div>

                        </div>
                    </Columns.Column>
                </Columns>





                <br />
                <br />


                <Button disabled={saving} loading={saving} className='is-fullwidth is-black' onClick={saveOrder} > Save </Button>

                <br />


            </div>
        )

    }




    const dropDownChoiceMade = (choice) => {
        if (choice === 'cancelOrder') {

            // if all products are cancelled, then we can cancel this order. Else not.

            if (props.products !== null) {
                if (props.products.length) {
                    let nonCancelled = props.products.filter((thisProd) => {
                        return (thisProd.isCancelled !== true)
                    })

                    if (nonCancelled !== null) {
                        if (nonCancelled.length > 0)
                            cogoToast.warn("Products are active in this order. Order can only be cancelled if all of its products are cancelled.")
                        return
                    }
                }
            } else {
                // order does not have products 

            }

            // alert("Cancelling order now")
        }

    }

    const closeModals = () => {
        setSelectedMeasurement(null)
    }


    const modal = () => {
        return (
            <Modal show={true} closeOnBlur={true} closeOnEsc={true} showClose={true} onClose={() => setClosed()} >
                <Modal.Content className='is-padingless has-background-light'>


                    {selectedMeasurement ? <MeasurementModal purpose='edit' measurement={selectedMeasurement}  type={MeasurementType.conciergeOrder} closed={closeModals} measurementUpadted={measurementsUpdated} /> : null }



                    <div className='is-radiusless is-padingless fadeIn has-background-transparent  marginTop40'>



                        <Heading size={6} className='is-size-5-mobile has-text-black has-text-semibold has-text-centered' > Concierge Order Fields </Heading>
                        <ColumnAux isMiddle={true} size={10} className=' is-centered '>
                            {form()}
                        </ColumnAux>

                    </div>
                </Modal.Content>
            </Modal>

        )
    }

    return modal()

}

export default ConciergeOrderModal