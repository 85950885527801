import React, { useState, useEffect } from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Heading, Tile, Button } from 'react-bulma-components/dist';
import { User } from '../../utilities/UserClasses'
import UserCard from '../../Departments/Users/userCard'
import UserModal from './userModal-original'
import { getCurrentLocation, getLocations, getPositions, saveUsers } from '../../utilities/Store';
import cogoToast from 'cogo-toast';
import LoadingAux from '../../containers/LoadingAux';
import ColumnAux from '../../containers/ColumnAux';

const AllUsers = (props) => {

    const [users, setUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState(null)
    const [addUser, setAddUser] = useState(null)
    const [fetching, setFetching] = useState(false)


    useEffect(() => {

        setFetching(true)
        User.getAll((succ, usrs, errMsg) => {
            setFetching(false)
            if (succ) {

                // remove deleted users

                let filteredUsers = usrs.filter((thisUsr) => {
                    return (thisUsr.isDeleted !== true)
                })

                let cl = getCurrentLocation()

                if (cl.isMain !== true) {
                    filteredUsers = filteredUsers.filter((thisUsr) => {
                        // console.log('CL =')
                        // console.log(cl)
                        // console.log('thisUsr')
                        // console.log(thisUsr)
                        return thisUsr.locationId === cl.objectId
                    })
                }

                setUsers(filteredUsers)
            } else {
                cogoToast.error('Could not fetch Users. An error occured.')
                // console.log(errMsg)
            }
        })

    }, [])

    const closeModal = () => {
        setSelectedUser(null)
        setAddUser(false)
    }


    const addUserPressed = () => {
        setAddUser(true)
    }

    const selectUserPressed = (usr) => {
        setSelectedUser(usr)
    }

    const usersView = () => {
        return (
            <LoadingAux isLoading={fetching} isMain={false} size='medium' loaderHeight={200}>
                <div>
                    <div>
                        <div>
                            <ColumnAux size={12} isMiddle={false}>
                                <Button className='is-small is-pulled-right is-rounded marginBottom10' onClick={addUserPressed}> Add </Button>
                            </ColumnAux>
                            <br />
                        </div>
                    </div>
                    {/* <Heading size={6} className="  marginBottom6">Customers </Heading> */}

                    <Tile kind="ancestor" className=' userGrid' >
                        {/* <Fade right cascade> */}
                        {
                            users.map((user, index) => {
                                return (
                                    <Tile key={index} kind='parent' className=''>
                                        <Tile className='' >
                                            <UserCard user={user} selectUser={selectUserPressed} />
                                        </Tile>
                                    </Tile>
                                )
                            })
                        }
                        {/* </Fade> */}
                    </Tile>
                </div>
            </LoadingAux>

        )
    }


    const userIsUpdated = (usr) => {
        console.log('User is Updated')
        let usrs = [...users]
        const index = usrs.findIndex((u) => {
            return u.objectId === usr.objectId
        })

        if (index != null) {
            if (usr.isDeleted) {
                usrs[index] = usr

                usrs = usrs.filter((thisUser) => {
                    return (thisUser.isDeleted !== true)
                })

                saveUsers(usrs)
                setUsers(usrs)
            } else {
                usrs[index] = usr
                // Save Users in Store
                saveUsers(usrs)
                setUsers(usrs)
            }
        }

        closeModal()
    }

    const userIsAdded = (usr) => {
        console.log('User is Added')
        let usrs = [...users]
        usrs.push(usr)
        setUsers(usrs)
        // Save Users in Store
        saveUsers(usrs)
        closeModal()
    }



    const newUserWithAnyPosition = () => {

        let pos = getPositions()[0]
        let nu = User.init()
        // nu.location = getLocations().filter( loc => {  return loc.isMain === true })[0]
        // if (getCurrentLocation().isFranchise === true){
        nu.location = getCurrentLocation()
        // }
        nu.locationId = nu.location.objectId ?? ""
        nu.position = pos
        return nu
    }


    return (
        <div>
            {addUser ? <UserModal isNewUser={true} userAdded={userIsAdded} user={newUserWithAnyPosition()} userUpdated={userIsUpdated} closed={closeModal} /> : null}
            {selectedUser ? <UserModal isNewUser={false} user={selectedUser} userUpdated={userIsUpdated} closed={closeModal} /> : usersView()}
        </div>
    )

}

export default AllUsers