import React from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Heading } from 'react-bulma-components/dist';
import PageHeader from '../../containers/PageHeader'



const Slides = (props) => {
    let pathOrder = () => {
        let path = props.location.pathname
        let links = path.split('/')

        // removing initial blank item after split
        links.shift()

        return links
    }

    return (
        <div>
            <PageHeader crumbItems={pathOrder()} />
            <Heading size={5}> Slides </Heading>
        </div>

    )

}

export default Slides