




  const devBaseName = '/'
  const productionBaseName = `${process.env.PUBLIC_URL}/`

export  function giveBaseName() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      // dev code
      console.log('Giving Base Name - ', devBaseName)
      return  devBaseName
    } else {
      // production code
      console.log('Giving Base Name - ', productionBaseName)
      return productionBaseName
    }
  }


function importAll(r) {
    return r.keys().map(r);
}

const allImages = importAll(require.context('../assets/', false, /\.(png|jpe?g|svg)$/));


const getImageNamed = (name) => {
    // console.log('ALL IMAGES = ', allImages)
    let images = allImages.filter(path => {
        return path.includes(name)
    })
    return images ? images[0] : null   // add a placeholder image here
}

export const imageNamed = (name) => {
    let img = getImageNamed(name)
    return img ?? getImageNamed('placeholder')
}

export const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)


export const uniqId = () => 'id' + (new Date()).getTime();


export const newOrderNo = () => {

    let date = new Date()

    var d = date.getDate();
    var m = date.getMonth() + 1; //Month from 0 to 11
    var y = ('' + date.getFullYear()).substr(2,2)
    let no =  '' + y +  m  + d + randomAlphabets(3)
    console.log('Year Value = ', y)
    console.log('Order No = ', no)
    return no
}


export const randomAlphabets = (length) => {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

 export const randomIntegerString = (length) => {
    var result           = '';
    var characters       = '1234567890';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }



export const PRODUCT_TYPE = {
    suit: 'SUIT',
    blazer: 'Blazer',
    jacket: 'Jacket',
    shirt: 'Shirt',
    pant: 'Pant',
    shoe: 'Shoe'
}

export  const EXTRA_STEP_TYPE = {
    essential : 'Essential',
    welcome : 'Welcome',
    units : 'Units',
}




export const UNIT_TYPES = {
    ft: 'Fts',
    inchs: 'Inchs',
    cm: 'Cms',
    kg: 'Kg',
    lb: 'Lb',
    year: 'Years'    
}

export const UnitGroupOptions = {
    FtInchKg : 'Feets,   Inches,   Kilogram',
    CmLb : 'Centimeters,   Pound',
}

export const UNIT_KIND = {
    lengthBig: 'Length Big',
    length: 'Length',
    weight: 'Weight',
    age: 'Age'
}

export const MEASURE_TYPE = {
    age: 'Age',
    height: 'Height',
    weight: 'Weight',
    neck: 'Neck',
    shoulder: 'Shoulder',
    sleeve: 'Sleeve',
    overarm: 'Overarm',
    hip: 'Hip',
    pant: 'Pant',
    chest: 'Chest',
    arms: 'Arms',
    biceps: 'Biceps',
    wrist: 'Wrist',
    waiste: 'Waiste',
    inseem: 'Inseem',
    suitLength: 'SuitLength',
    shirtLength: 'ShirtLength',
    null: null
}

