import React from 'react'
import { Box, Button, Modal, Heading, Card, Image, Columns, Table } from 'react-bulma-components/dist';
import Vendors from './Vendors'

const VendorsModal = (props) => {
    // Just Showing Vendors in a Modal 

    
    let vm =  (
            <Modal show={true} closeOnBlur={true} closeOnEsc={true} showClose={true} onClose={props.closed} >
            <Modal.Content className='is-padingless'>
                <Box className='is-radiusless is-padingless fadeIn has-background-light'>

                    {/* <ColumnAux isMiddle={true} size={10} className=' is-centered '> */}
                    <Vendors isToChoose={props.isToChoose} vendorChosen={props.vendorChosen} />
                    {/* </ColumnAux> */}

                </Box>
            </Modal.Content>
        </Modal>
        )

    



    return vm
    

}

export default VendorsModal