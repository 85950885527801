import React, { useState } from 'react'
import { Box, Button, Modal, Heading, Columns, Tag, Image, Dropdown } from 'react-bulma-components/dist';

import ColumnAux from '../../containers/ColumnAux'
import { LocationType, User } from '../../utilities/UserClasses'
import { picType } from '../../utilities/enums'

import { getLocations, getUsers, getPositions, getAptPositions, getCurrentLocation } from '../../utilities/Store';
import ImageUploader from '../../containers/ImageUploader'
import cogoToast from 'cogo-toast';

const UserModal = (props) => {


    const setClosed = () => {
        props.closed()
    }

    let [user, setUser] = useState(props.user)
    let [saving, setSaving] = useState(false)
    let [addUserPic, setAddUserPic] = useState(false)
    let [selectedLoc, setSelectedLoc] = useState(user.location ?? null)
    let [selectedPos, setSelectedPos] = useState(user.position.objectId ? user.position : null)

    // let [allPositions, setAllPositions] = useState(getPositions())


    // {(newuser === true) ? <userModal customer={customer} isNewuser={true} userAdded={newuserAdded} userUpdated={userWasUpdated} closed={setNoSelected} /> : null}



    const closed = () => {
        setAddUserPic(false)
    }




    const saveUser = () => {


        // let user = User.copyFrom(user)

        


        if (user.name == null || user.name === '') {
            // alert('No Name set')
            cogoToast.warn('No Name set')
            return
        }

        if (user.username == null || user.username === '') {
            // alert('No Username set')
            cogoToast.warn('No Username set')
            return
        }

        if (user.password == null || user.password === '') {
            // alert('No Password set')
            cogoToast.warn('No Password set')
            return
        }


        if (user.name.length <= 2) {
            // alert('Name must be atleast 3 characters long.')
            cogoToast.warn('Name must be atleast 3 characters long')
            return
        }


        if (user.username.length <= 2) {
            // alert('Username must be atleast 5 characters long.')
            cogoToast.warn('Username must be atleast 3 characters long')
            return
        }

        let allUsers = getUsers()

        if (allUsers && allUsers.length) {

            if (user.objectId != null) {
                allUsers = allUsers.filter(us => {
                    return us.objectId !== user.objectId
                })
            }

            let sameUserName = allUsers.filter(us => {
                let thisUserObjId = user.objectId ?? ""
                return (us.username === user.username && us.objectId !== user.thisUserObjId)
            })

            console.log(sameUserName)

            if (sameUserName && sameUserName.length) {
                let sameUser = sameUserName[0]
                if (sameUser != null) {
                    // alert('Username must be unique. This username exists already.')
                    cogoToast.warn('Username must be unique. This username exists already')
                    return
                }
            }

        }

        if (user.password.length <= 7) {
            // alert('Password must be atleast 8 characters long.')
            cogoToast.warn('Password must be atleast 8 characters long')
            return
        }


        if (props.isNewUser) {

            let thisUser = User.copyFrom(user)

            if (thisUser.position.objectId === null) {
                let userLocation = thisUserLocation()
                let aptPositions = filterAptPositions(user.location)


                console.log("G APT POSiTIONS")
                console.log(aptPositions)

                if (aptPositions.length) {
                    let firstPos = aptPositions[0]
                    thisUser.position = firstPos


                    console.log("U APT POSiTIONS")
                    console.log(thisUser.position)
                }
            }

            console.log("Saving User")
            console.log(thisUser)

            // save 
            setSaving(true)
            thisUser.add((succ, errMsg) => {
                setSaving(false)
                if (succ) {
                    props.userAdded(user)
                } else {
                    cogoToast.error('Could not save User. Check internet and try again.')
                    // alert(errMsg)
                }
            })

        } else {
            // update

            // console.log('COPINGF FROM POS IS')
            // console.log(user)


            let thisUser = User.copyFrom(user)

            if (thisUser.position.objectId === null) {
                let aptPositions = filterAptPositions(user.location)
                if (aptPositions.length) {
                    let firstPos = aptPositions[0]
                    thisUser.position = firstPos
                }
            }



            // console.log('OBJECT ID OF OBJECT = ', thisUser.objectId)
            // console.log('USER IS')
            // console.log(thisUser)

            setSaving(true)
            thisUser.update((succ, errMsg) => {
                setSaving(false)
                if (succ) {
                    props.userUpdated(user)
                } else {
                    cogoToast.error('Could not update User. Check internet and try again.')
                    // alert(errMsg)
                }
            })

        }


    }



    const locationIsChanged = (e) => {
        console.log('Location is changed to : ' + e.target.value)
        let allLocations = getLocations()
        let matchLoc = allLocations.filter((loc) => {
            return loc.objectId === e.target.value
        })

        if (matchLoc != null && matchLoc.length) {
            let thisLoc = matchLoc[0]
            console.log('Changing Location to ' + thisLoc.name)
            let o = { ...user }
            o.location = thisLoc
            o.locationId = thisLoc.objectId
            setUser(o)
        }
    }



    const positionIsChanged = (e) => {
        console.log('Position is changed to : ' + e.target.value)
        let allPos = getPositions()
        let thisPos = allPos.filter((pos) => {
            return pos.objectId === e.target.value
        })

        if (thisPos != null && thisPos.length) {
            let newPos = thisPos[0]
            console.log('Changing Position to ' + newPos.name)
            let o = { ...user }
            o.position = newPos
            setUser(o)
        }
    }

    const changePosition = (pos) => {
        console.log('Changing Position to ' + pos)
        let o = { ...user }
        o.position = pos
        setUser(o)
    }

    const nameIsChanged = (e) => {
        console.log('Name is changed to : ' + e.target.value)
        let o = { ...user }
        o.name = e.target.value
        setUser(o)
    }

    // const phoneIsChanged = (e) => {
    //     console.log('Phone is changed to : ' + e.target.value)
    // }

    const usernameIsChanged = (e) => {
        console.log('Username is changed to : ' + e.target.value)
        let o = { ...user }
        o.username = e.target.value
        setUser(o)
    }

    const passwordIsChanged = (e) => {
        console.log('Password is changed to : ' + e.target.value)
        let o = { ...user }
        o.password = e.target.value
        setUser(o)
    }

    const thisUserLocation = () => {
        let allLocations = getLocations()
        let userLocation = user.location ?? null

        let userLocationMatch = allLocations.filter((thisLoc) => {
            return thisLoc.objectId === user.locationId
        })

        if (userLocationMatch) {
            if (userLocationMatch.length) {
                userLocation = userLocationMatch[0]
            }
        }

        return userLocation
    }

    const filterAptPositions = (userLocation) => {
        let aptPositions = getAptPositions()
        let currentLoc = getCurrentLocation()

        // console.log("ALL APT POSITIONS")
        // console.log(aptPositions)

        // console.log("User Location is")
        // console.log(userLocation)


        if (userLocation.type === LocationType.franchise || userLocation.isFranchise === true) {
            // Send positions only which are added in this Franchise
            aptPositions = aptPositions.filter((thisPos) => {
                if (thisPos.forLocationId || thisPos.forLocation) {
                    if (thisPos.forLocation) {
                        return (thisPos.forLocation.objectId === userLocation.objectId)
                    }
                    return (thisPos.forLocationId === userLocation.objectId)
                }
            })

            console.log("Calculated Apt Positions are")
            console.log(aptPositions)

        } else {
            // Remove franchise locations
            let na = aptPositions.filter((thisPos) => {
                return (thisPos.forLocationId === null)
            })
            aptPositions = na
        }

        return aptPositions

    }


    const form = () => {

        let allLocations = getLocations()
        let cl = getCurrentLocation()
        if (cl.isFranchise === true) {
            allLocations = [cl]
        }

        let userLocation = thisUserLocation()
        console.log("USER LOCATION IS")
        console.log(userLocation)
        let aptPositions = filterAptPositions(userLocation)




        console.log('APOS =')
        console.log(aptPositions)

        return (

            <div className='is-two-fifths  paddingHor20'>
                <br />

                {
                    (user.isAdmin || user.isFranchiseAdmin) ? null :

                        <div>

                            <Columns className='is-mobile'>
                                <Columns.Column size={3} className="backgroundMoreGreyLighter">
                                    <label className="label type55  marginTop10 has-text-right">Location:</label>
                                </Columns.Column>
                                <Columns.Column>

                                    <div className="control ">
                                        <div className='select is-fullwidth'>
                                            <select name='position' className='select ' defaultValue={user.locationId ?? null} onChange={locationIsChanged}>

                                                {
                                                    allLocations.map((loc, index) => {
                                                        return <option key={index} value={loc.objectId} > {loc.type + " • " + loc.name} </option>
                                                    })
                                                }

                                            </select>
                                        </div>
                                    </div>

                                </Columns.Column>
                            </Columns>

                            <Columns className='is-mobile'>
                                <Columns.Column size={3} className="backgroundMoreGreyLighter">
                                    <label className="label type55  marginTop10 has-text-right">Position:</label>
                                </Columns.Column>
                                <Columns.Column>

                                    <div className="control ">
                                        <div className='select is-fullwidth'>
                                            <select name='position' className='select ' defaultValue={user.position ? user.position.objectId : ''} onChange={positionIsChanged}>

                                                {
                                                    aptPositions.map((pos, index) => {
                                                        return <option key={index} value={pos.objectId} > {pos.name} </option>
                                                    })
                                                }

                                            </select>
                                        </div>
                                    </div>

                                </Columns.Column>
                            </Columns>
                        </div>
                }


                <Columns className='is-mobile'>
                    <Columns.Column size={3} className="backgroundMoreGreyLighter">
                        <label className="label type55  marginTop10 has-text-right">Name:</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className="control is-fullwidth">
                            <div className='field '>
                                <input name='name' defaultValue={user.name ?? ''} placeholder='Name of user' type='input' className='input field has-text-weight-semibold type65' onChange={e => { nameIsChanged(e) }} />
                            </div>
                        </div>
                    </Columns.Column>
                </Columns>


                <Columns className='is-mobile'>
                    <Columns.Column size={3} className="backgroundMoreGreyLighter">
                        <label className="label type55  marginTop10 has-text-right">Username:</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className="control is-fullwidth">
                            <div className='field '>
                                <input name='username' defaultValue={user.username ?? ''} placeholder='Username - must be unique' type='input' className='input field has-text-weight-semibold type65' onChange={e => { usernameIsChanged(e) }} />
                            </div>
                        </div>
                    </Columns.Column>
                </Columns>


                <Columns className='is-mobile'>
                    <Columns.Column size={3} className="backgroundMoreGreyLighter">
                        <label className="label type55  marginTop10 has-text-right">Password:</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className="control is-fullwidth">
                            <div className='field '>
                                <input name='password' defaultValue={user.password ?? ''} placeholder='Password - 8 chars atleast' type='secure' className='input field has-text-weight-semibold type65' onChange={e => { passwordIsChanged(e) }} />
                            </div>
                        </div>
                    </Columns.Column>
                </Columns>



                <br />


                <Button disabled={saving} loading={saving} className='is-fullwidth is-black' onClick={saveUser} > Save </Button>

                <br />


            </div>
        )

    }


    const userImagePressed = () => {
        setAddUserPic(true)
    }

    const userImageAdded = (imageUrl) => {
        let us = { ...user }
        us.imageUrl = imageUrl
        setUser(us)
        closed()
    }



    const tryDeleteUser = () => {



        if (user.isAdmin) {
            cogoToast.warn("Admin cannot be deleted. You may only request to change admin via email.")
            return
        }

        if (user.isFranchiseAdmin) {
            cogoToast.warn("Franchise Admin cannot be deleted. You may only request to change/delete franchise admin via email.")
            return
        }


        let nu = User.copyFrom(user)
        nu.isDeleted = true

        let dpMatch = getPositions().filter((thisPos) => {
            return thisPos.isDeletedUserPosition === true
        })

        if (dpMatch) {
            if (dpMatch.length > 0) {
                nu.position = dpMatch[0]
            }
        }

        nu.username = user.username + "(Del)"

        setSaving(true)
        nu.update((succ, errMsg) => {
            setSaving(false)
            if (succ) {
                props.userUpdated(nu)
            } else {
                cogoToast.error('Could not delete User. Check internet and try again.')
                console.log(errMsg)
            }
        })

    }

    const dropDownChoiceMade = (val) => {
        if (val === 'delete') {
            tryDeleteUser()
        }
    }


    const modal = () => {

        let allLocations = getLocations()



        let userLocation = null

        let userLocationMatch = allLocations.filter((thisLoc) => {
            return thisLoc.objectId === user.locationId
        })

        if (userLocationMatch) {
            if (userLocationMatch.length) {
                userLocation = userLocationMatch[0]
            }
        }

        let imageUrl = null

        if (user.imageUrl != null && user.imageUrl !== '') {
            imageUrl = user.imageUrl
        }
        else {
            imageUrl = 'https://ui-avatars.com/api/?background=808080&color=f2f2f2&name=' + user.name
        }

        return (
            <Modal show={true} closeOnBlur={true} closeOnEsc={true} showClose={true} onClose={() => setClosed()} >
                <Modal.Content className='is-padingless has-background-light'>

                    <Dropdown className=" is-small is-arrowless  is-pulled-left has-background-light noDropdownIcon" color="light" label='•••' onChange={(val) => { dropDownChoiceMade(val) }} >
                        <Dropdown.Item value="delete">
                            Delete
                        </Dropdown.Item>
                    </Dropdown>
                    <br />

                    <Box className='is-radiusless is-padingless no-border is-shadowless has-background-transparent '>

                        <Heading size={6} className='is-size-5-mobile has-text-black has-text-semibold has-text-centered' > {props.isNewuser ? 'New User' : 'User Details'}  </Heading>

                        {user.isAdmin ? <div className='is-centered has-text-centered'> <Tag className=' is-centered has-text-centered has-text-link type45 marginBottom20' color="white" > ADMIN </Tag>  </div> : null}
                        {user.isFranchiseAdmin ? <div className='is-centered has-text-centered'> <Tag className=' is-centered has-text-centered has-text-info type45 marginBottom20' color="white" > FRANCHISE ADMIN • {userLocation.name ?? ""} </Tag>  </div> : null}


                        <div className=" marginBottom20  has-text-centered ">
                            <Image className=' userImage has-image-centered ' src={imageUrl} alt="User Profile Image" size={64} onClick={userImagePressed} />
                        </div>

                        <ColumnAux isMiddle={true} size={10} className=' is-centered '>

                            {form()}
                        </ColumnAux>

                    </Box>
                </Modal.Content>
            </Modal>

        )
    }

    return (
        <div>
            {addUserPic ? <ImageUploader user={user} product={null} type={picType.UserPic} closed={closed} imageAdded={userImageAdded} /> : modal()}

        </div>

    )

}

export default UserModal