import React, { useState, useEffect } from 'react'
import { Box, Button, Modal, Heading, Columns, Table } from 'react-bulma-components/dist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPrint } from '@fortawesome/free-solid-svg-icons';
import ColumnAux from '../../containers/ColumnAux'
import LoadingAux from '../../containers/LoadingAux'
import { Profession_options, ProductType_StyleOptions, SpendRange_options, ClientCategory_options, ProductType } from '../../utilities/enums'

import { Position } from '../../utilities/UserClasses'
import { StyleSelection, Product } from '../../utilities/Classes'

import { getLocations, getUsers, getCurrentUser } from '../../utilities/Store';
import cogoToast from 'cogo-toast';
import { ProductType_options_NonFormik } from '../../ClientInfo/clientInfo';

const ProductEditModal = (props) => {



    const setClosed = () => {
        props.closed()
    }


    let [prodType, setProdType] = useState(props.product.type)
    let [saving, setSaving] = useState(false)
    







    const saveProduct = () => {

        
        setSaving(true)
        let prodCopy = Product.copyFrom(props.product)
        prodCopy.type = prodType

        console.log("SAVING ProdType")
        console.log(prodType)

        prodCopy.update( (succ, msg) => {
            setSaving(false)
            if (succ){
                props.productEdited(prodCopy)
            }else{
                cogoToast.error('Could not update Product Type. Check internet and try again.')
                console.log(msg)
            }
        })
    }


    const typeChanged = (event) => {
        // alert(`Change in Selection name = ${selectionName}`)

        console.log('EVENT TARGET VALUE = ', event.target.value)

        setProdType(event.target.value)


    }






const createTables = () => {
    return (
        <div >
                <Columns className='is-mobile'>
                    <Columns.Column size={3}>
                        <label className="label type55  marginTop10 ">Product:</label>
                    </Columns.Column>
                    <Columns.Column>

                        <div className="control is-fullwidth"> 
                            <span className="select has-text-weight-semibold type65 is-fullwidth">
                                {ProductType_options_NonFormik('type', '  ', typeChanged, prodType)}
                            </span>
                        </div>

                    </Columns.Column>
                </Columns>
        </div>
    )
}

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }
  

const camelToSentenceCase = (text) => {
    var result = text.replace(/([A-Z])/g, " $1");
    var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult
}



const form = () => {
    return (
        <div className='is-two-fifths  paddingHor20'>
            <br />
            {createTables()}
            <br />
            <Button disabled={saving} loading={saving} className='is-fullwidth is-black' onClick={saveProduct} > Save </Button>
            <br />
        </div>
    )
}


const modal = () => {

    createTables()

    return (
        <Modal show={true} closeOnBlur={true} closeOnEsc={true} showClose={true} onClose={() => setClosed()} >
            <Modal.Content className='is-padingless'>
                <Box className='is-radiusless is-padingless fadeIn has-background-light'>
                    <Heading size={6} className='is-size-5-mobile has-text-black has-text-semibold has-text-centered' > Product Edit  </Heading>
                    <ColumnAux isMiddle={true} size={10} className=' is-centered '>

                        <div className='field is-centered'>
                            {/* <label className="label type60 ">Email</label> */}
                            {/* <input name={position.name} defaultValue={position.name} placeholder={'Name of Position'} type='input' className='input  has-text-weight-semibold type65' onChange={e => { positionNameChanged(e) }} /> */}
                        </div>

                        {form()}
                    </ColumnAux>

                </Box>
            </Modal.Content>
        </Modal>

    )
}

return modal()

}

export default ProductEditModal