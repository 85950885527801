import React from 'react'
import { screen } from '../../utilities/enums'
import Orders from '../../General/Orders'

const CompleteOrders = () => {

    return (
        <div>
            <Orders columnAuxSize={8} headerTitle='Orders that are recieved at factory but not yet marked as work-complete.' screen={screen.CompleteOrders_Factory} />
        </div>
    ) 

}

export default CompleteOrders