import React, { useState } from 'react'
import { Box, Button, Modal, Heading, Columns, Image } from 'react-bulma-components/dist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPrint } from '@fortawesome/free-solid-svg-icons';
import ColumnAux from '../../containers/ColumnAux'
import DatePicker from 'react-datepicker'
import { Order, Vendor } from '../../utilities/Classes'
import { getLocations, getUsers } from '../../utilities/Store';

const ChoiceModal = (props) => {



    const setClosed = () => {
        props.closed()
    }



    let choiceRow = (choice) => {

        return (

            <div key={choice.key}>
                {choice.title !== "" ?  <Heading size={6} className=''> {choice.title} </Heading> : null }
                {
                    choice.needsRemark ?

                        <div className='field '>
                            {/* <label className="label type60 ">Email</label> */}
                            <textarea name={choice.key} placeholder={choice.remarkPlaceholder} type='input' className='input textarea has-text-weight-semibold type65' onChange={e => { remarkChanged(e, choice.key) }} />
                        </div>
                        :
                        null
                }
                <Button className={choice.highlight ? 'is-fullwidth is-primary' : 'is-fullwidth is-black'} onClick={() => { choiceMade(choice.key) }} > {choice.buttonTitle} </Button>

            </div>

        )

    }


    const remarkChanged = (e, key) => {

        let value = e.target.value

        let chz = [...choices]
        const index = chz.findIndex((ch) => {
            return ch.key === key
        })

        if (index != null) {
            let thisCh = chz[index]
            thisCh.remark = value
            chz[index] = thisCh
            setChoices(chz)
        }
    }


    const choiceMade = (key) => {

        let chz = [...choices]
        const index = chz.findIndex((ch) => {
            return ch.key === key
        })

        if (index != null) {
            let thisCh = chz[index]
            props.choiceMade(thisCh)
        } else {
            console.log('Could not get index of choice')
        }
    }


    let [choices, setChoices] = useState(props.choices)




    const form = () => {


        return (

            <div className='is-two-fifths  paddingHor20'>

                <br />

                {choices.map((ch) => {
                    return <div key={ch.key} >
                        {choiceRow(ch)}
                        <hr />
                    </div>
                })}


            </div>
        )

    }


    const modal = () => {
        return (
            <Modal show={true} closeOnBlur={true} closeOnEsc={true} showClose={true} onClose={() => setClosed()} >
                <Modal.Content className='is-padingless'>
                    <ColumnAux isMiddle={true} size={10} className=' is-centered '>
                        <Box className='is-radiusless is-padingless fadeIn has-background-light'>
                            <Heading size={6} className='is-size-5-mobile has-text-grey  has-text-centered' >{props.title ? props.title : "Choose"}</Heading>

                            <ColumnAux isMiddle={true} size={10} className=' is-centered '>
                                {form()}
                            </ColumnAux>
                        </Box>                
                    </ColumnAux>

                </Modal.Content>
            </Modal>

        )
    }

    return modal()

}

export default ChoiceModal