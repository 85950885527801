import React, { useState } from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Heading, Button } from 'react-bulma-components/dist';

const VendorReports = () => {

    const [vendors, setVendors] = useState([])
    const [searching, setSearching] = useState(false)

    return <Heading size={6} > Stock Reports </Heading>

}

export default VendorReports