import React, { useState } from 'react'
import { Button, Columns, Heading } from 'react-bulma-components/dist'
import OrderCard from '../components/Cards/OrderCard'
import ProductModal from '../components/Cards/ProductModal'
import { Order } from '../utilities/Classes'
import { OrderFilter } from '../utilities/Filters'

import { canAddOrderAndProducts } from '../utilities/Classes'
import cogoToast from 'cogo-toast'

const ClientOrdersInfo = (props) => {


    // productBox(props.type, props.groupType, props.id, props.stage, props.saleType, props.styleNo , props.trial, props.delivery, props.stocks, props.pics, props.remarks )

    const searchForOrders = () => {
        // console.log('ORDER SEARCH NEEDED = ' + props.orderSearchNeeded)

        if (props.customer && (props.customer.objectId != null)) {
            if (props.orderSearchNeeded === true) {
                let of = OrderFilter.init()
                of.customer = props.customer
                of.active = true

                Order.getAll(of, (succ, orders, errorMsg) => {
                    if (succ) {
                        props.customerOrdersFetched(orders)
                    } else {
                        cogoToast.error('Could not fetch Orders. An error occured.')
                        // alert(errorMsg)
                    }
                })
            }
        }
    }




    const view = () => {

        searchForOrders()

        return (
            <div>
                {canAddOrderAndProducts(props.screen) ?  <Button className='is-small is-rounded is-pulled-right marginBottom10' onClick={() => { props.addOrder() }}> Add </Button>   : null}  
                <Heading size={6} className="  marginBottom6">Orders </Heading>

                {
                    props.orders.map((ord, index) => {
                        return <OrderCard screen={props.screen} key={index} order={ord} selectOrder={ props.selectOrder} />
                    })
                }
                
            </div>

        )
    }

    return (
        view()
    )

}


export default ClientOrdersInfo