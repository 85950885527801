import React, { useState, useEffect } from 'react'
import { Box, Button, Modal, Heading, Columns, Table } from 'react-bulma-components/dist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPrint } from '@fortawesome/free-solid-svg-icons';
import ColumnAux from '../../containers/ColumnAux'
import { Position } from '../../utilities/UserClasses'
import cogoToast from 'cogo-toast';
import { productType_allValues } from '../../ClientInfo/clientInfo';

const ProductSelectionModal = (props) => {
    const setClosed = () => {
        props.closed()
    }

    const setupProducts = () => {

        console.log('Attained Selected Products')
        console.log(props.selectedProducts)

        let sp = props.selectedProducts ?? []

        let allProds = productType_allValues()

        let allProdObjs = []

        allProds.map( (thisProd) => {
            let thisObj = {
                product: thisProd,
                selected: sp.includes(thisProd)
            }
            allProdObjs.push(thisObj)
        })

        // let prodObjArray = [
        //     {product: '2pc suit', selected: sp.includes('2pc suit')} ,
        //     {product: '3pc suit', selected: sp.includes('3pc suit')} ,
        //     {product: 'Jacket', selected: sp.includes('Jacket')} ,
        //     {product: 'Pant', selected: sp.includes('Pant')} ,
        //     {product: 'Waistcoat', selected: sp.includes('Waistcoat')} ,
        //     {product: 'Peacoat', selected: sp.includes('Peacoat')} ,
        //     {product: 'Overcoat', selected: sp.includes('Overcoat')} ,
        //     {product: 'Shirt', selected: sp.includes('Shirt')} ,
        //     {product: 'Shirt dress', selected: sp.includes('Shirt dress')} ,
        //     {product: 'Skirt', selected: sp.includes('Skirt')} ,
        //     {product: 'Leather Goods', selected: sp.includes('Leather Goods')} ,
        //     {product: 'Sweaters', selected: sp.includes('Sweaters')} ,
        //     {product: 'Shoes', selected: sp.includes('Shoes')} 
        // ]

        console.log('Prod Obj Array')
        console.log(JSON.stringify(allProdObjs))     
        return allProdObjs   
    }

    let [products, setProducts] = useState(setupProducts())
    let [saving, setSaving] = useState(false)


    // {(newposition === true) ? <positionModal customer={customer} isNewposition={true} positionAdded={newpositionAdded} positionUpdated={positionWasUpdated} closed={setNoSelected} /> : null}


    const saveSelections = () => {
        if (props.selectionSaved){

            // make array of products only
            let ps = []
            products.map( (p) => {
                if (p.selected){
                    ps.push(p.product)
                }
                return null
            })
            console.log('SENDING NEW SAVED SELECTION AS')
            console.log(ps)
            props.selectionSaved(ps)
        }
    }



    const createProductTable = () => {

        return (
            <div >
                < Table   >
                    <tbody>
                        {products.map((prod, index) => {

                            // if (index === 0) {
                            //     console.log('Sending ControlName=' + controlName + ' Key=' + key)
                            // }

                            return createRow(prod, index)
                        })}
                    </tbody>
                </Table >
                <br />
            </div>
        )
    }


    const createRow = (prod, index) => {

        console.log('PROD IS = ' + prod)

        return (
            <tr key={index} className=''>
                <td>
                    <div className=' padVertical10 type74'>
                        {prod.product}
                    </div>
                </td>
                <td  >
                    <div className=' padVertical10 is-pulled-right marginRight6'>
                        <input type="checkbox" className='largerCheckbox ' onChange={(e) => { productSelectionChanged(prod.product, e) }} defaultChecked={prod.selected} />
                    </div>
                </td>
            </tr>
        )
    }



    const productSelectionChanged = (prod, e) => {
        console.log('' + prod + ' value changed to = ' + e.target.checked)
        let value = e.target.checked
        let o = [ ...products ]

        const index = o.findIndex((pr) => {
            return pr.product === prod
        })
        
        if (index != null) {
            o[index].selected = value
        }

        setProducts(o)
        console.log('Product Selection to ')
        console.log(products)
    }



    const form = () => {

        return (

            <div className='is-two-fifths  paddingHor20'>

                <br />


                {createProductTable()}


                <br />


                <Button disabled={saving} loading={saving} className='is-fullwidth is-black' onClick={saveSelections} > Save </Button>

                <br />


            </div>
        )

    }


    const modal = () => {


        return (
            <Modal show={true} closeOnBlur={true} closeOnEsc={true} showClose={true} onClose={() => setClosed()} >
                <Modal.Content className='is-padingless'>
                    <Box className='is-radiusless is-padingless fadeIn has-background-light'>
                        <Heading size={6} className='is-size-5-mobile has-text-black has-text-semibold has-text-centered' > For Products </Heading>
                        <ColumnAux isMiddle={true} size={10} className=' is-centered '>
                            {form()}
                        </ColumnAux>

                    </Box>
                </Modal.Content>
            </Modal>

        )
    }

    return modal()

}

export default ProductSelectionModal