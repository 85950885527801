import React, { useState } from 'react'
import { Box, Button, Modal, Heading, Columns, Image } from 'react-bulma-components/dist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPrint } from '@fortawesome/free-solid-svg-icons';
import ColumnAux from '../../containers/ColumnAux'
import DatePicker from 'react-datepicker'
import { Order, Vendor } from '../../utilities/Classes'
import { getLocations, getUsers } from '../../utilities/Store';
import VendorImage from '../../assets/vendorImage.png'
import cogoToast from 'cogo-toast';


const VendorModal = (props) => {



    const setClosed = () => {
        props.closed()
    }

    const newVendor = () => {
        let nv = Vendor.init()
        return nv
    }

    let [vendor, setVendor] = useState(props.vendor ?? newVendor())
    let [saving, setSaving] = useState(false)


    // {(newOrder === true) ? <OrderModal customer={customer} isNewOrder={true} orderAdded={newOrderAdded} orderUpdated={orderWasUpdated} closed={setNoSelected} /> : null}


    const saveVendor = () => {

        if (vendor.name == null) {
            // alert('Please add Vendor name before saving. ')
            cogoToast.warn('Add Vendor Name')
            return
        } else if (vendor.name.length < 1) {
            // alert('Please add Vendor name before saving. ')
            cogoToast.warn('Add valid Vendor Name')
            return
        }



        setSaving(true)

        let nv = Vendor.init()
        nv.name = vendor.name
        nv.description = vendor.description
        nv.address = vendor.address
        nv.contact = vendor.contact
        nv.forProductTypes = vendor.forProductTypes

        if (props.isNewVendor === true) {

            if ( vendor.logoPicUrl != null ) { nv.logoPicUrl = vendor.logoPicUrl }

            

            nv.add((succ, err) => {
                if (succ) {
                    // console.log('New Vendor creared')
                    cogoToast.error('Vendor Created')
                    props.vendorAdded(nv)
                    setVendor(nv)
                } else {
                    cogoToast.error('Could not create Vendor. Check internet and try again.')
                    // alert(err)
                }

                setClosed()
            })


        } else {

            nv.objectId = vendor.objectId
            nv.update((succ, err) => {
                if (succ) {
                    // console.log('Vendor updated')
                    cogoToast.error('Vendor Updated')
                    // console.log(nv)
                    props.vendorUpdated(nv)
                } else {
                    cogoToast.error('Could not update Vendor. Check internet and try again.')
                    // alert(err)
                }
                setClosed()
            })

        }


    }


    const nameChanged = (e) => {
        let v = { ...vendor }
        v.name = e.target.value
        setVendor(v)
    }

    const addressChanged = (e) => {
        let v = { ...vendor }
        v.address = e.target.value
        setVendor(v)
    }

    const contactChanged = (e) => {
        let v = { ...vendor }
        v.contact = e.target.value
        setVendor(v)
    }

    const descriptionChanged = (e) => {
        let v = { ...vendor }
        v.description = e.target.value
        setVendor(v)
    }




    const form = () => {


        // let logoPicUrl = "https://logopond.com/logos/542261601e8c26d6b12cce3cb159b55a.png"


        return (

            <div className='is-two-fifths  paddingHor20'>

                <br />

                <Image className=' is-centered vendorImage has-image-centered' src={VendorImage} alt="Vendor Profile Pic" size={96} />

                <br />

                <Columns className="is-mobile">
                    <Columns.Column size={3} className="backgroundMoreGreyLighter">
                        <label className="label type55  marginTop10 has-text-right">Name</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className='field marginHorizontal8'>
                            {/* <label className="label type60 ">Email</label> */}
                            <input name='name' defaultValue={vendor.name} type='input' className='input field has-text-weight-semibold type65' placeholder='Name' onChange={e => { nameChanged(e) }} />
                        </div>
                    </Columns.Column>
                </Columns>

                <Columns className="is-mobile">
                    <Columns.Column size={3} className="backgroundMoreGreyLighter">
                        <label className="label type55  marginTop10 has-text-right">Description</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className='field marginHorizontal8'>
                            {/* <label className="label type60 ">Email</label> */}
                            <textarea name='description' defaultValue={vendor.description} type='input' className='input textarea has-text-weight-semibold type65' placeholder='Description' onChange={e => { descriptionChanged(e) }} />
                        </div>
                    </Columns.Column>
                </Columns>

                <Columns className="is-mobile">
                    <Columns.Column size={3} className="backgroundMoreGreyLighter">
                        <label className="label type55  marginTop10 has-text-right">Contact</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className='field marginHorizontal8'>
                            {/* <label className="label type60 ">Email</label> */}
                            <input name='contact' defaultValue={vendor.contact} type='input' className='input field has-text-weight-semibold type65' placeholder='Contact' onChange={e => { contactChanged(e) }} />
                        </div>
                    </Columns.Column>
                </Columns>

                <Columns className="is-mobile">
                    <Columns.Column size={3} className="backgroundMoreGreyLighter">
                        <label className="label type55  marginTop10 has-text-right">Address</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className='field marginHorizontal8'>
                            {/* <label className="label type60 ">Email</label> */}
                            <input name='address' defaultValue={vendor.address} type='input' className='input field has-text-weight-semibold type65' placeholder='Address' onChange={e => { addressChanged(e) }} />
                        </div>
                    </Columns.Column>
                </Columns>



                <br />
                <br />


                <Button disabled={saving} loading={saving} className='is-fullwidth is-black' onClick={saveVendor} > Save </Button>

                <br />


            </div>
        )

    }


    const modal = () => {
        return (
            <Modal show={true} closeOnBlur={true} closeOnEsc={true} showClose={true} onClose={() => setClosed()} >
                <Modal.Content className='is-padingless'>
                    <Box className='is-radiusless is-padingless fadeIn has-background-light'>
                        <Heading size={6} className='is-size-5-mobile has-text-black has-text-semibold has-text-centered' > {props.isNewVendor ? 'New Vendor' : 'Vendor Details'}  </Heading>
                        <ColumnAux isMiddle={true} size={10} className=' is-centered '>
                            {form()}
                        </ColumnAux>

                    </Box>
                </Modal.Content>
            </Modal>

        )
    }

    return modal()

}

export default VendorModal