import React, { useState, useEffect } from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Tile, Box, Table, Button, Tag, Image, Modal, Heading, Card, Label, InputFile } from 'react-bulma-components/dist';
import { MeasurementType } from '../utilities/enums'
import ColumnAux from './ColumnAux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPrint, faUpload } from '@fortawesome/free-solid-svg-icons';
import { MeasurementTemplate } from '../utilities/Classes'
import cogoToast from 'cogo-toast';

import MeasurementModal from './MeasurementModal'


const MeasurementTemplates = (props) => {



    const [templates, setTemplates] = useState([])

    const [selectedTemplateForModal, setSelectedTemplateForModal] = useState(null)

    const templateTappedToUse = (tmp) => {
        console.log('Template tapped to use')

        if (props.isToSelectedBodyMeasure) {

            setSelectedTemplateForModal(tmp)

            return
        }

        props.selectedTemplate(tmp)
    }


    useEffect(() => {
        MeasurementTemplate.getAll(props.type, (succ, tmps, errMsg) => {
            if (succ) {
                setTemplates(tmps)
            } else {
                cogoToast.error('Could not fetch Measurement Templates. An error occured.')
                // alert(errMsg)
            }
        })

    }, [props])





    const tmpBox = (mt) => {
        return (
            // <Box className='hoverShine noBreakWords generalCardBG imagesItem has-background-black' onClick={() => { templateTappedToUse(tmp) }} >
            // </Box>

            <Card className='is-radiusless  noBreakWords generalCardBG measureTemplateItem has-background-white' onClick={() => { templateTappedToUse(mt) }} >
                <div className='type55 has-text-weight-semibold marginTop10 marginLeft10'> {mt.name} </div>
                <br />
                <Table className=' is-size-7'>
                    <tbody>
                        {
                            mt.measures.map((ms, index) => {
                                return <tr key={index}><td className='type40'>{ms.name}</td><td className='has-text-right type40'>{ms.value}&nbsp;{ms.unit}</td></tr>
                            })
                        }
                    </tbody>
                </Table>
            </Card>

        )
    }



    const tmpTile = (tmp, index) => {
        return (
            <Tile key={index} size={4} kind='parent' className=''>
                <Tile className='fullHeightAll' >
                    {tmpBox(tmp)}
                </Tile>
            </Tile>
        )
    }

    const allTemplateTiles = () => {
        return (
            <div>
                <Tile kind="ancestor" className='grid measurementsTileContainer' >
                    {
                        templates.map((tmp, index) => {
                            return tmpTile(tmp, index)
                        })
                    }
                </Tile>
            </div>
        )
    }


    const closeSelectMeasureModal = () => {
        setSelectedTemplateForModal(null)
    }

    const measureIsSelected = (selectedMeasure) => {
        setSelectedTemplateForModal(null)
        props.bodyMeasureSelected(selectedMeasure)

    }

    const modal = () => {
        return (
            <Modal show={true} closeOnBlur={true} closeOnEsc={true} showClose={true} onClose={() => props.closed()} >
                <Modal.Content className='is-padingless'>

                    {(selectedTemplateForModal !== null) ? <MeasurementModal purpose='edit' measurement={selectedTemplateForModal} type={MeasurementType.body} isToSelectMeausre={true} closed={closeSelectMeasureModal} measureIsSelected={measureIsSelected} /> : null}


                    <Box className='is-radiusless is-padingless fadeIn has-background-light'>

                        {
                            props.isToSelectedBodyMeasure ?

                            <Heading size={6} className='is-size-5-mobile has-text-black has-text-semibold has-text-centered' >  Select a template to choose BodyMeasure from </Heading>

                            :

                                <div>
                                    <Heading size={6} className='is-size-5-mobile has-text-black has-text-semibold has-text-centered' >  Measurement Templates </Heading>
                                    <Heading className='type50 marginTop20  has-text-grey has-text-centered' > TAP TO USE </Heading>
                                </div>

                        }



                        <ColumnAux isMiddle={true} size={10} className=' is-centered '>
                            {allTemplateTiles()}
                        </ColumnAux>
                    </Box>
                </Modal.Content>
            </Modal>

        )
    }









    return (
        modal()
    )

}

export default MeasurementTemplates