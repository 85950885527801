import React from 'react'
import Customers from '../../General/Customers'
import { screen } from '../../utilities/enums'

const SearchClients = () => {

    return (
        <div>
            <Customers  isSearchable={true} screen={screen.Search_Clients} />
        </div>
    )
}

export default SearchClients
