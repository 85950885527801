import React, { useState, useEffect } from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Heading, Button } from 'react-bulma-components/dist';
import { User, Position } from '../../utilities/UserClasses'
import PositionCard from './positionCard'
import ColumnAux from '../../containers/ColumnAux'
import PositionModal from './positionModal'
import { getCurrentUser, getPositions, savePositions, getCurrentLocation, getAptPositions } from '../../utilities/Store'
import cogoToast from 'cogo-toast';
import LoadingAux from '../../containers/LoadingAux';

const Positions = () => {

    const [positions, setPositions] = useState([])
    const [selectedPosition, setSelectedPosition] = useState(null)
    const [addPosition, setAddPosition] = useState(false)
    const [fetching, setFetching] = useState(false)


    const closeModal = () => {
        setSelectedPosition(null)
        setAddPosition(false)
    }

    useEffect(() => {

        setFetching(true)
        Position.getAll((succ, posits, errMsg) => {
            setFetching(false)

            if (succ) {

                // We do not show default generated Positions by us.
                let aptPositions = getAptPositions()
                setPositions(aptPositions)
            } else {
                cogoToast.error('Could not fetch Positions. An error occured.')
                // console.log(errMsg)
            }
        })

    }, [])


    const addPositionPressed = () => {
        setAddPosition(true)
    }

    const selectPositionPressed = (pos) => {
        setSelectedPosition(pos)
    }

    const positionsView = () => {
        return (
            <LoadingAux isLoading={fetching} loaderHeight={300} size='medium'>
                <div>
                    <div>
                        {/* {(props.isSearchable === true) ? searchForm() : null} */}
                        <div>
                            <Button className='is-small is-pulled-right is-rounded marginBottom10' onClick={addPositionPressed}> Add </Button>
                            <br />
                        </div>
                    </div>
                    {/* <Heading size={6} className="  marginBottom6">Customers </Heading> */}

                    <ColumnAux size={7} isMiddle={true}>
                        {/* <Fade right cascade> */}
                        {
                            positions.map((position, index) => {
                                return (
                                    <PositionCard key={index} position={position} selectPosition={selectPositionPressed} />
                                )
                            })
                        }
                        {/* </Fade> */}
                    </ColumnAux>
                </div>
            </LoadingAux>

        )
    }



    const positionIsUpdated = (pos) => {
        console.log('Position is Updated')
        let ps = [...positions]
        const index = ps.findIndex((p) => {
            return p.objectId === pos.objectId
        })

        if (index != null) {
            ps[index] = pos
            setPositions(ps)
        }

        // Save Positions in Store
        savePositions(ps)

        closeModal()
    }

    const positionIsAdded = (pos) => {
        console.log('Position is Added')
        let ps = [...positions]
        ps.push(pos)
        setPositions(ps)

        // Save Positions in Store
        savePositions(ps)


        closeModal()
    }

    const positionIsDeleted = (pos) => {
        console.log('Position is deleted')
        let ps = [...positions]
        const index = ps.findIndex((p) => {
            return p.objectId === pos.objectId
        })

        if (index != null) {
            ps.splice(index, 1);
            setPositions(ps)
        }
        closeModal()
    }


    return (
        <div>
            {addPosition ? <PositionModal isNewposition={true} positionAdded={positionIsAdded} positionDeleted={positionIsDeleted} position={Position.initBasicPositionWithNoAccess('')} closed={closeModal} /> : null}
            {selectedPosition ? <PositionModal isNewposition={false} positionUpdated={positionIsUpdated} positionDeleted={positionIsDeleted} position={selectedPosition} closed={closeModal} /> : positionsView()}

        </div>
    )

}

export default Positions