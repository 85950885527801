import React from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Heading, Button } from 'react-bulma-components/dist';
import PageHeader from '../../containers/PageHeader'
import Vendors from '../../General/Vendors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faEdit } from '@fortawesome/free-solid-svg-icons';
import { screen, screenMenuLink } from '../../utilities/enums'
import ConfirmOrders from './confirm-orders'
import CompleteOrders from './complete-orders'
import SendOrders from './send-orders'
import { Vendor } from '../../utilities/Classes';


const AllVendors = (props) => {

    let pathOrder = () => {
        let path = props.location.pathname
        let links = path.split('/')

        // removing initial blank item after split
        links.shift()

        console.log('PATHS ARE - ')
        console.log(links)

        return links
    }

    let lastPath = () => {
        let path = pathOrder()
        let thisTitle = path[path.length - 1]
        return thisTitle
    }

    let thisPage = () => {
        switch (lastPath()) {
            case 'confirmOrders': return <ConfirmOrders />;
            case 'completeOrders': return <CompleteOrders />;
            case 'sendOrders': return <SendOrders />;

            default:
                return <Vendors  screen={screen.Vendors} isToChoose={false} vendorChosen={vendorChosen} isSearchable={false} />;
        }
    }

    const vendorChosen = (vendor) => {
        props.vendorChosen(vendor)
    }



    return (
        <div>
            <PageHeader crumbItems={pathOrder()} />
            { props.isToChoose ? <Button className="  no-decoration is-pulled-left is-small has-text-weight-bold" onClick={props.backPressed}><FontAwesomeIcon className='' icon={faChevronLeft} /></Button> : null } 

            {/* <Heading size={5}> Vendors </Heading> */}

            {/* <Vendors screen={screen.Vendors} isToChoose={false} vendorChosen={vendorChosen} isSearchable={false} /> */}

            {thisPage()}

        </div>

    )

}

export default AllVendors