import React, { useState, useRef } from 'react'
import Orders from '../../General/Orders'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Heading, Button, Image, Columns } from 'react-bulma-components/dist';
import { screen, SearchKey } from '../../utilities/enums'
import { OrderFilter } from '../../utilities/Filters'
import { Order } from '../../utilities/Classes'
import OrderCard from '../../components/Cards/OrderCard'
import ProductsOfOrder from '../../containers/ProductsOfOrder'
import ColumnAux from '../../containers/ColumnAux'
import DatePicker from 'react-datepicker'

const PendingInfo = (props) => {


    return (
        <div>
            {/* {selectedOrder ? <ProductsOfOrder customer={null} order={selectedOrder} fetchProducts={true} screen={screen.Search_Store} orderUpdated={orderIsUpdated} backPressed={closeModals} /> : searchForm()} */}
             <Orders columnAuxSize={8} isSearchable={false} screen={screen.PendingInfo}  />

        </div>
    )
}

export default PendingInfo