import React from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Heading } from 'react-bulma-components/dist';
import PageHeader from '../../containers/PageHeader'
import Positions from './positions'
import AllUsers from './allUsers'


const Users = (props) => {


    let pathOrder = () => {
        let path = props.location.pathname
        let links = path.split('/')

        // removing initial blank item after split
        links.shift()

        console.log('PATHS ARE - ')
        console.log(links)

        return links
    }

    let lastPath = () => {
        let path = pathOrder()
        let thisTitle = path[path.length - 1]
        return thisTitle
    }

    let thisPage = () => {
        switch (lastPath()) {
            case 'positions': return <Positions />;
            case 'users': return <AllUsers />;

            default:
                return <Heading size={5}> Select a screen </Heading>;
        }
    }


    return (
        <div>
            <PageHeader crumbItems={pathOrder()} />
            { thisPage() }
        </div>

    )

}

export default Users