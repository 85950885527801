import React, { useState } from 'react'
import { Box, Columns, Button, Image } from 'react-bulma-components/dist';
import { invoiceStatus, profilePages } from '../../utilities/enums'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { dateToDateString } from '../../utilities/helper'
import { Customer } from '../../utilities/Classes'
import { getCurrentLocation } from '../../utilities/Store';
import mapPinMicro from '../../assets/mapPinMicro.svg'



const blankText = <span className='blankText'> . </span>


const CustomerCard = (props) => {



    // const custs = () => {

    //     let cust1 = Customer.init()
    //     cust1.name = 'Dwyane Johnson'
    //     cust1.country = '+1'
    //     cust1.phone1 = '903909090'
    //     cust1.email1 = 'rocky@gmail.com'

    //     let cust2 = Customer.init()
    //     cust2.name = 'Idrys Elba'
    //     cust2.country = '+91'
    //     cust2.phone1 = '3576353657'
    //     cust2.email1 = 'elba@gmail.com'

    //     let cust3 = Customer.init()
    //     cust3.country = '+91'
    //     cust3.name = 'Jason Stathom'
    //     cust3.phone1 = '46754654757'
    //     cust3.email1 = 'json@gmail.com'

    //     let allCusts = [cust1, cust2, cust3]

    //     return allCusts.map( (c, index) => {
    //         return customerBox(c, index)
    //     })

    // }


    const customerBox = (customer) => {


        console.log('Got Customer')
        console.log(customer)

        let isMainLoc = true
        let locString = ""
        let oa = customer.registeredAt

        console.log(" BJKSBJS REGISTERED AT ")
        console.log(oa)
        console.log(" BJKSBJS CL IS ")
        console.log(getCurrentLocation())


        if (oa) {
            if (oa.objectId !== getCurrentLocation().objectId) {
                locString = oa.name
                isMainLoc = false
            }
        }

        console.log(" ISMAINLOC ")
        console.log(isMainLoc)



        // let imageUrlOfUser = 'https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200'

        let profilePicUrl = null

        if (customer.profilePicUrl != null && customer.profilePicUrl !== '') {
            profilePicUrl = customer.profilePicUrl
        }
        else {
            profilePicUrl = 'https://ui-avatars.com/api/?color=909090&name=' + customer.name
        }

        return (
            <Box className='hoverShine customerCard has-text-centered noBreakWords generalCardBG' onClick={() => { props.selectCustomer(customer) }} style={{cursor: 'pointer'}}>

                <div className=" marginBottom20  has-text-centered ">
                    <Image className=' customerImage has-image-centered' src={profilePicUrl} alt="User Profile Image" size={48} />
                </div>

                <p className="fieldHeight20 marginBottom5 has-text-weight-semibold type65 is-uppercase" >{customer.name}</p>
                <p className="fieldHeight20  has-text-grey has-text-weight-semibold type55" >{customer.country}{customer.phone1}</p>
                <p className="fieldHeight20 marginBottom10 has-text-grey has-text-weight-semibold type55" >{customer.email1}</p>

                {/* <Button className='  is-small is-grey is-fullwidth is-outlined' onClick={() => { props.selectCustomer(customer) }}> View </Button> */}

                {
                    (isMainLoc === false) ?
                        <p className="type45 has-text-grey marginTop10"><img src={mapPinMicro} width="6" />&nbsp;{locString}</p> :

                        null
                }
            </Box>
        )
    }





    return (
        customerBox(props.customer)
    )
}

export default CustomerCard