import React, { useState } from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Columns, Modal, Container, Heading, Breadcrumb, Tabs, Image, Button, Control, Box, Tile } from 'react-bulma-components/dist';
import ColumnAux from './ColumnAux';
import { Product } from '../utilities/Classes';




const ProductPrint = (props) => {

    let [product, setProduct] = useState(props.product)

    let logoRow = () => {

        return (
            // <Columns>
            //     <Columns.Column size={4} >
            //         <div className='is-pulled-left'>
            //             <img src={PersonaLogo} alt="Tailorwise: Streamlined Bespoke Tailoring" width="136" />
            //         </div>

            //     </Columns.Column>
            //     <Columns.Column >

            //         <Heading size={6} className='marginTop10 type70 is-pulled-right'> Product {product.productId ?? ''} </Heading> */}

            //          </Columns.Column>
            // </Columns>
            <Heading size={6} className='marginTop10 type70'> Render Module: Awaiting assets </Heading>

        )
    }



    const printPressed = () => {

        // const input = document.querySelector('#capture');






    }


    const setClosed = () => {
        props.closed()
    }

    let modal = () => {
        return (
            <Modal id='' className='' show={true} closeOnBlur={true} closeOnEsc={true} showClose={true} onClose={() => setClosed()} >
                <Modal.Content className='is-padingless'>
                    <Button className='is-pulled-right is-black is-small rounded' onClick={() => setClosed()} > Close </Button>
                    <Box className='is-radiusless is-padingless fadeIn has-background-light'>

                        <Container>
                            <div id='capture' className=''>
                                <ColumnAux isMiddle={true} size={10} className=' is-centered '>
                                    {logoRow()}
                                </ColumnAux>
                            </div>

                        </Container>



                    </Box>

                    <Button className='is-small ' onClick={printPressed} > Download </Button>
                </Modal.Content>
            </Modal>
        )
    }

    return (
        <div>
            {modal()}
        </div>
    )


}

export default ProductPrint