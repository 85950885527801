import React, { useState } from 'react'
import { Box, Button, Modal, Heading, Columns } from 'react-bulma-components/dist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPrint } from '@fortawesome/free-solid-svg-icons';
import ColumnAux from '../../containers/ColumnAux'
import { Formik, Form, Field, } from 'formik'
import { Remark } from '../../utilities/Classes'
import { getUsers } from '../../utilities/Store';
import cogoToast from 'cogo-toast';

const RemarkModal = (props) => {

    const setClosed = () => {
        props.closed()
    }

    let orderInfoValues = {
        remark: '',
    }



    const saveRemark = (values, callback) => {

        let rm = Remark.init()
        rm.product = props.product
        rm.order = props.product.order
        rm.byUser = getUsers()[0]
        rm.byName = rm.byUser.name
        rm.remark = values.remark

        rm.add( (succ, errMsg) => {
            if (succ) {
                // console.log('Saved Remark ')
                // console.log(rm)
                cogoToast.success('Remark Saved')
                callback({ success: true, remark: rm , msg: ''})

            }else{
                cogoToast.error('Could not save Remark. Check internet and try again.')
                // console.log('Remark Save Failed - ' + errMsg )
                callback({ success: false, remark: null , msg: errMsg})
            }
        })

    }




    const form = () => {
        return (

            <div className='is-two-fifths  paddingHor20'>
                {/* <div className='is-two-fifths paymentBoxBGColor paddingHor20'> */}

                <br />
                {/* <Heading size={7}> SHIPPING ADDRESS </Heading> */}

                <Formik
                    initialValues={orderInfoValues}

                    validate={(values) => {
                        const errors = {};
                        return errors;
                    }}

                    onSubmit={(data, { setSubmitting, resetForm }) => {
                        setSubmitting(true)
                        console.log('Searching Remark with Data : ', data)
                        saveRemark(data, (response) => {
                            setSubmitting(false)
                            resetForm(true)
    
                            if (response.success === true) {
                                props.remarkAdded(response.remark)
                            } else {
                                props.errorOccured(response.msg)
                            }
                        })


                    }}

                    validator={() => ({})}
                >
                    {({ values, isSubmitting, errors, touched, isValidating }) => (

                        <Form >
                            {/* <div className='field is-horizontal  '> */}
                            {/* <label className="label type55 marginHorizontal8 marginTop20 ">Type</label> */}


                            <Columns>
                                <Columns.Column>
                                    <div className='field marginHorizontal8'>
                                        {/* <label className="label type60 ">Email</label> */}
                                        <Field as='textarea' name='remark'  className='input textarea has-text-weight-semibold type65' placeholder='Add Remark' />
                                    </div>
                                </Columns.Column>
                            </Columns>


                            <br />
                            <br />


                            <Button disabled={isSubmitting || isValidating} loading={isSubmitting}  className='is-fullwidth is-black' type='submit' > Save </Button>

                            <br />

                        </Form>

                    )}

                </Formik>
            </div>
        )

    }


    const modal = () => {
        return (
            <Modal show={true} closeOnBlur={true} closeOnEsc={true} showClose={true} onClose={() => setClosed()} >
                <Modal.Content className='is-padingless'>
                    <Box className='is-radiusless is-padingless fadeIn has-background-light'>
                        <Heading size={6} className='is-size-5-mobile has-text-black has-text-semibold has-text-centered' > Add Remark </Heading>
                        <ColumnAux isMiddle={true} size={10} className=' is-centered '>
                            {form()}
                        </ColumnAux>

                    </Box>
                </Modal.Content>
            </Modal>

        )
    }

    return modal()

}

export default RemarkModal