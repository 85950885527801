import React, { useState } from 'react'
import 'react-bulma-components/dist/react-bulma-components.min.css'
import { Tile, Box, Columns, Button, Tag, Image, Modal, Heading, Card, Label, InputFile, Dropdown } from 'react-bulma-components/dist';
import { pages, departments, screen, departmentForScreen, clientScreenTab } from '../utilities/enums'
import ColumnAux from './ColumnAux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPrint, faUpload } from '@fortawesome/free-solid-svg-icons';
import LoadingAux from './LoadingAux';
import { CustomerPic, ProductPic } from '../utilities/Classes';
import cogoToast from 'cogo-toast';

import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';



const ImageViewer = (props) => {

    const backPressed = () => {
        props.closed()
    }


    const [isDeleting, setIsDeleting] = useState(false)


    const deletePressed = (val) => {
        if (val !== "delete") {
            return
        }

        if (props.type === "customerPic") {

            let pp = CustomerPic.copyFrom(props.imgObj)

            if (pp.objectId !== null && pp.objectId !== "") {
                setIsDeleting(true)
                pp.delete((succ, errMsg) => {
                    setIsDeleting(false)
                    if (succ) {
                        props.imageDeleted(props.imgObj)
                        backPressed()
                    } else {
                        console.log(errMsg)
                        cogoToast.warn("Could not delete image right now. Check internet & try again.")
                    }
                })
            }

        } else {
            let pp = ProductPic.copyFrom(props.imgObj)

            if (pp.objectId !== null && pp.objectId !== "") {
                setIsDeleting(true)
                pp.delete((succ, errMsg) => {
                    setIsDeleting(false)
                    if (succ) {
                        props.imageDeleted(props.imgObj)
                        backPressed()
                    } else {
                        console.log(errMsg)
                        cogoToast.warn("Could not delete image right now. Check internet & try again.")
                    }
                })
            }
        }

    }

    const modal = () => {
        return (
            <Modal show={true} closeOnBlur={true} closeOnEsc={true} showClose={true} onClose={() => props.closed()} >
                <Modal.Content className='is-padingless has-background-light'>

                    {isDeleting ?
                        null :
                        <Dropdown className=" is-arrowless is-grey noDropdownIcon" color="light" label='•••' onChange={(val) => { deletePressed(val) }} >
                            <Dropdown.Item value="delete">
                                Delete
                    </Dropdown.Item>
                        </Dropdown>

                    }


                    <Box className='is-radiusless is-padingless no-border is-shadowless has-background-transparent '>

                        <LoadingAux isLoading={isDeleting} loaderHeight={300} >


                            {/* <Heading size={6} className='is-size-5-mobile has-text-black has-text-semibold has-text-centered' > Image </Heading> */}
                            <ColumnAux isMiddle={true} size={10} className=' is-centered '>
                                {props.imgObj.url ?


                                    <TransformWrapper
                                        defaultScale={1}
                                    // defaultPositionX={200}
                                    // defaultPositionY={100}
                                    >
                                        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                                            <React.Fragment>
                                                <div className="is-pulled-left">
                                                    <Button className=" has-text-grey is-text " onClick={resetTransform}>Reset</Button>

                                                </div>
                                                <div className="tools is-pulled-right marginRight24  marginBottom10">

                                                    <Button rounded className=" " onClick={zoomOut}>-</Button>
                                                    <Button rounded className=" marginLeft10" onClick={zoomIn}>+</Button>

                                                </div>
                                                <br />
                                                <Box className="marginTop20 has-text-ceneterd is-centered">
                                                    <TransformComponent className="has-text-ceneterd is-centered ">
                                                        <Image className="is-centered has-text-ceneterd imageViewer" src={props.imgObj.url} />
                                                    </TransformComponent>
                                                </Box>

                                            </React.Fragment>
                                        )}
                                    </TransformWrapper>


                                    : null}
                            </ColumnAux>
                            <br />
                        </LoadingAux>


                    </Box>
                </Modal.Content>
            </Modal>

        )
    }


    return (
        modal()
    )

}

export default ImageViewer