import React, { useState } from 'react'
import { Box, Button, Modal, Heading, Columns } from 'react-bulma-components/dist';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPrint } from '@fortawesome/free-solid-svg-icons';
import { getCurrentUser, getTwConfig, saveTwConfig } from '../../utilities/Store';
import { TWConfig } from '../../utilities/UserClasses';
import cogoToast from 'cogo-toast';



const UserSettings = () => {

    let  [config, setConfig] = useState( getTwConfig() ?? { logoutAfterHours: 6, userCanChangeOwnPass:false, objectId:'fa27rMObVy' } )
    let [saving, setSaving] = useState(false)

    // const defaultValueChanged = (e) => {
    //     console.log('CHNAGING DEFAULT VALUE TO = ' + e.target.value)
    //     let m = { ...measure }
    //     m.defaultValue = e.target.value

    //     console.log('CHNAGING DEFAULT VALUE')

    //     setMeasure(m)
    // }

    // const valueChanged = (e) => {
    //     let m = { ...measure }
    //     m.value = e.target.value
    //     setMeasure(m)
    // }


    const saveConfig = () => {

        let cn = new TWConfig(config.userCanChangeOwnPass, config.logoutAfterHours, config.objectId)
        setSaving(true)
        cn.update((succ, msg) => {
            setSaving(false)
            if (succ){
                cogoToast.success('User Setting Saved')
                saveTwConfig(cn)
            }else{
                cogoToast.error('User Setting could not be saved')
                console.log(msg)
            }
        })
        
    }

    const allowPassChangeChanged = (e) => {
        let m = { ...config }
        m.userCanChangeOwnPass = (e.target.value === 'Yes' ? true : false )
        setConfig(m)
    }

    const logoutAfterHoursChanged = (e) => {
        let m = { ...config }
        m.logoutAfterHours = Number(e.target.value)
        setConfig(m)
    }


    const form = () => {

        return (

            <div className='is-two-fifths  paddingHor20'>

                <br />

                <Columns>
                    <Columns.Column size={3}>
                        <label className="label type55  marginTop10 ">Auto Logout After</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className="control marginHorizontal8">
                            <div className='select type65 is-fullwidth'>
                                <select name='unit' className='select ' defaultValue={config.logoutAfterHours ??  6} onChange={e => { logoutAfterHoursChanged(e) }}>
                                    <option value={2} > 2 hours </option>
                                    <option value={6} > 6 hours </option>
                                    <option value={12} > 12 hours </option>
                                    <option value={24} > 1 Day </option>
                                    <option value={48} > 2 Day </option>
                                    <option value={168} > 1 Week </option>
                                </select>
                            </div>
                        </div>
                    </Columns.Column>
                </Columns>

                <br />
                {/* <Columns>
                    <Columns.Column size={3}>
                        <label className="label type55  marginTop10 ">User Can Edit Own Password</label>
                    </Columns.Column>
                    <Columns.Column>
                        <div className="control marginHorizontal8">
                            <div className='select type65 is-fullwidth'>
                                <select name='passChangeable' className='select ' defaultValue={config.userCanChangeOwnPass ??  false} onChange={e => { allowPassChangeChanged(e) }}>
                                    <option value={true} > Yes </option>
                                    <option value={false} > No </option>
                                </select>
                            </div>
                        </div>

                    </Columns.Column>
                </Columns> */}

                <br />
                <br />


                <Button disabled={saving} loading={saving} className='is-fullwidth is-black' onClick={saveConfig} > Save </Button>

                <br />


            </div>
        )

    }


    return (
        <div>
            <br />
            <Heading size={6} className='has-text-centered'> User Settings </Heading>
            {form()}
        </div>
    )
}

export default UserSettings